<!-- Banner @s -->
<div class="header-banner bg-theme-grad">
  <div class="nk-banner">
    <div class="banner banner-fs banner-pd-lg">
      <div class="banner-wrap">
        <div class="container">
          <div
            class="row gutter-vr-30px align-items-center justify-content-lg-between justify-content-center"
          >
            <ng-content select="[banner]"></ng-content>

            <div class="col-lg-5 col-sm-9 order-lg-last">
              <div
                class="banner-gfx-re-s3 my-0 animated"
                data-animate="fadeInUp"
                data-delay="1"
              >
                <img src="./../../images/roadmapearth.png" alt="header" />
              </div>
            </div>
            <!-- .col -->
            <div class="col-lg-7 col-xl-6 col-sm-10 text-center text-lg-start">
              <div class="banner-caption cpn tc-light">
                <div class="cpn-head">
                  <h1
                    class="title title-md animated"
                    data-animate="fadeInUp"
                    data-delay="1.1"
                  >
                    Connecting a Decentralized World
                    <br class="d-none d-md-block" />
                  </h1>
                </div>
                <div class="cpn-text cpn-text-light">
                  <p
                    class="lead animated"
                    data-animate="fadeInUp"
                    data-delay="1.2"
                  >
                    Nexus is a next-generation blockchain technology that is
                    re-building the internet from the ground up.
                  </p>
                </div>
                <div class="cpn-action mt-0">
                  <div
                    class="cpn-btns pt-4 animated"
                    data-animate="fadeInUp"
                    data-delay="1.3"
                  >
                    <ul class="btn-grp">
                      <li class="nk-block-video tc-light">
                        <a
                          href="{{ sanitizedUrl }}"
                          class="btn btn-primary btn-lg video-popup"
                        >
                          <em class="link-icon fs-5 far fa-play-circle"></em>
                          <span>Learn more</span>
                        </a>
                      </li>

                      <li>
                        <a
                          href="#"
                          class="btn btn-outline btn-primary on-bg-theme-alt btn-lg"
                          (click)="navigateToWiki()"
                          >Read Docs</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="cpn-social">
                  <ul class="social">
                    <li>
                      <a
                        href="https://www.facebook.com/NexusBlockchain/"
                        alt="nexus facebook"
                        ><em class="social-icon fab fa-facebook-f"></em
                      ></a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/NexusOfficial"
                        alt="nexus twitter"
                        ><em class="social-icon fab fa-twitter"></em
                      ></a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/nexusblockchain/"
                        alt="nexus instagram"
                        ><em class="social-icon fab fa-instagram"></em
                      ></a>
                    </li>

                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCDi2stU5yj71_iPgxMZF79w"
                        alt="nexus youtube"
                        ><em class="social-icon fab fa-youtube"></em
                      ></a>
                    </li>

                    <li>
                      <a href="https://t.me/NexusOfficial" alt="nexus telegram"
                        ><em class="social-icon fab fa-telegram"></em
                      ></a>
                    </li>

                    <!-- <li>
                      <a
                        href="https://discord.gg/YZZt8t6Ur5"
                        alt="nexus discord"
                        ><em class="social-icon fab fa-discord"></em
                      ></a>
                    </li> -->

                    <li>
                      <a
                        href="https://www.reddit.com/r/nexus_community/"
                        alt="nexus reddit"
                        ><em class="social-icon fab fa-reddit"></em
                      ></a>
                    </li>

                    <li>
                      <a
                        href="https://github.com/Nexusoft/LLL-TAO"
                        alt="nexus github"
                        ><em class="social-icon fab fa-github"></em
                      ></a>
                    </li>
                    <li>
                      <a
                        href="https://medium.com/@NexusOfficial"
                        alt="nexus medium"
                        ><em class="social-icon fab fa-medium-m"></em
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->
        </div>
      </div>
    </div>
  </div>
  <!-- .nk-banner -->
  <div class="nk-ovm mask-f mask-contain-bottom shape-a2"></div>
  <!-- Place Particle Js -->
  <div id="particles-bg" class="particles-container particles-bg"></div>
</div>
<!-- .header-banner @e -->
