<footer class="nk-footer bg-theme-grad">
  <section class="section no-pdy section-contact bg-transparent">
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block block-contact">
        <div class="row justify-content-center g-0">
          <!-- .col -->
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
    <div class="nk-ovm ovm-top ovm-h-50 bg-light"></div>
    <!-- .nk-ovm -->
  </section>
  <!-- // -->
  <section class="section section-footer tc-light bg-transparent">
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block block-footer">
        <div class="row">
          <div class="col-lg-2 col-sm-4 mb-4 mb-sm-0">
            <div class="wgs wgs-menu">
              <h6 class="wgs-title">Nexus</h6>
              <div class="wgs-body">
                <ul class="wgs-links">
                  <li><a href="#">About Us</a></li>
                  <li><a href="./team">Meet Our Team</a></li>
                  <li><a href="./privacypolicy">Privacy Policy</a></li>
                  <li><a href="./terms">Terms of Use</a></li>
                </ul>
              </div>
            </div>
          </div>
          <!-- .col -->
          <div class="col-lg-2 col-sm-4 mb-4 mb-sm-0">
            <div class="wgs wgs-menu">
              <h6 class="wgs-title">Technology</h6>
              <div class="wgs-body">
                <ul class="wgs-links">
                  <li><a href="./whitepapers">Whitepapers</a></li>
                  <li><a href="./security">Security Audits</a></li>
                  <li><a href="./inquire">Inquire</a></li>
                </ul>
              </div>
            </div>
          </div>
          <!-- .col -->
          <div class="col-lg-2 col-sm-4 mb-4 mb-sm-0">
            <div class="wgs wgs-menu">
              <h6 class="wgs-title">Community</h6>
              <div class="wgs-body">
                <ul class="wgs-links">
                  <li>
                    <a href="https://nexusofficial.redbubble.com/"
                      >Merchandise</a
                    >
                  </li>
                  <li><a href="./branding">Branding & Style Guide</a></li>
                  <li><a href="./links">Social Media</a></li>
                  <li>
                    <a href="./internationallinks"
                      >International Social Media</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- .col -->
          <div class="col-lg-2 col-sm-4 mb-4 mb-sm-0">
            <div class="wgs wgs-menu">
              <h6 class="wgs-title">Quick Links</h6>
              <div class="wgs-body">
                <ul class="wgs-links">
                  <li><a href="./faq">FAQ</a></li>
                  <li>
                    <a href="https://explorer.nexus.io/">Block Explorer</a>
                  </li>
                  <li>
                    <a href="https://github.com/Nexusoft">Github Respository</a>
                  </li>
                  <li>
                    <a routerLink="resourcehub">Resource hub</a>
                  </li>
                  <li>
                    <a href="https://docs.nexus.io/user-guide">User Guides</a>
                  </li>

                  <li>
                    <a href="https://docs.nexus.io/developer-guide"
                      >Developer Guides</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- .col -->
          <div class="col-lg-4 mb-4 mb-lg-0 order-lg-first">
            <div class="wgs wgs-text">
              <div class="wgs-body">
                <a href="./" class="logo-link">
                  <img
                    class="logo-dark"
                    src="./../../images/nexus-logo-blue.png"
                    alt="logo"
                  />
                  <img
                    class="logo-light"
                    src="./../../images/nexus-logo-white.png"
                    alt="logo"
                  />
                </a>
                <p>
                  Copyright &copy; 2023 Nexus. <br />
                  All rights reserved.
                </p>
              </div>
            </div>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>
  <div class="nk-ovm shape-b"></div>
</footer>

<app-scroll-top></app-scroll-top>
