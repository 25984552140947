<link rel="icon" type="image/x-icon" href="./../assets/favicon.png" />

<body class="nk-body body-wider mode-onepage bg-light">
  <div class="nk-wrap">
    <header
      class="nk-header page-header is-transparent is-sticky is-shrink"
      id="header"
    >
      <nexus-header *ngIf="showHeader"></nexus-header>
      <nexus-banner *ngIf="showBanner"></nexus-banner>
    </header>
  </div>
</body>

<router-outlet></router-outlet>

<nexus-footer *ngIf="showFooter"></nexus-footer>
