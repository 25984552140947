<header
  class="nk-header page-header is-transparent is-sticky is-shrink"
  id="header"
>
  <div class="header-banner bg-theme-grad">
    <div class="nk-banner">
      <div class="banner banner-page banner-page-lg banner-gap-b4">
        <div class="banner-wrap">
          <div class="container">
            <div
              class="row align-items-center justify-content-center justify-content-lg-start"
            >
              <div class="col-lg-6 col-sm-10 text-center text-lg-start">
                <div class="banner-caption cpn tc-light">
                  <div class="cpn-head">
                    <h1 class="title">How To Buy Nexus</h1>
                  </div>
                  <div class="cpn-text">
                    <p>
                      If you would like to buy NXS on an exchange, BTC must
                      first be purchased with a fiat currency ($, €, £) or
                      another cryptocurrency.
                      <br />Once you have BTC or another cryptocurrency, you can
                      then buy NXS on
                      <a
                        href="https://bittrex.com/Market/Index?MarketName=BTC-NXS"
                        >Bittrex</a
                      >
                      or
                      <a href="https://tradeogre.com/exchange/BTC-NXS"
                        >TradeOgre.</a
                      >
                      <br />
                      Join us on
                      <a
                        href="https://www.coinstore.com/#/assetInfo/NXS?lang=en_US"
                        >CoinStore</a
                      >
                      for our USDT trading pair.
                    </p>
                  </div>

                  <div class="exchanges">
                    <div id="bittrex-img">
                      <a
                        href="https://bittrex.com/Market/Index?MarketName=BTC-NXS"
                      >
                        <picture id="imgDark">
                          <img
                            loading="lazy"
                            src="images/exchangeColor/bittrex.png"
                            alt="bittrex"
                          />
                        </picture>
                      </a>
                    </div>

                    <div id="coinstore-img">
                      <a
                        href="https://www.coinstore.com/#/assetInfo/NXS?lang=en_US"
                      >
                        <picture id="imgDark">
                          <img
                            loading="lazy"
                            src="images/exchangeColor/nav-logo.620c299f.png"
                            alt="CoinStore"
                          />
                        </picture>
                        <!-- <picture id="imgLight">
                            <img
                              loading="lazy"
                              src="images/exchangeColor/nav-logo.620c299f3.png"
                              alt="CoinStore"
                            />
                          </picture> -->
                      </a>
                    </div>
                    <div id="tradeogre-img">
                      <a href="https://tradeogre.com/exchange/BTC-NXS">
                        <picture id="imgDark">
                          <img
                            loading="lazy"
                            src="images/exchangeColor/trade-ogre-blue.png"
                            alt="tradeogre"
                          />
                        </picture>
                      </a>
                    </div>
                  </div>

                  <div
                    id="crypto-widget-CoinList"
                    data-design="modern"
                    data-coin-ids="131"
                  ></div>
                </div>
              </div>
              <!-- .col -->
            </div>
            <!-- .row -->
          </div>
        </div>
      </div>
    </div>
    <!-- .nk-banner -->
    <div class="banner-screen banner-screen-right">
      <div class="container">
        <div class="row justify-content-end">
          <div class="col-lg-6 col-xl-5">
            <div class="banner-screen-gfx ps-lg-4 ps-xl-0">
              <img class="buy-nxs-image" src="images/buynxs.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="particles-bg" class="particles-container particles-bg"></div>
    <div class="nk-ovm mask-a-md shape-a-sm"></div>
  </div>
  <!-- .header-banner @e -->
</header>
<main class="nk-pages">
  <div class="section bg-light tradingview">
    <div class="ui-mask-left ui-mask-s9"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="nk-block-text text-center">
              <div class="nexus-chart">
                <h1 class="title title-s2 animated fadeInUp">Nexus Chart</h1>
                <div class="tradingview-widget-container">
                  <div id="tradingview_7b6fc"></div>
                </div>
              </div>

              <div class="crypto-carrousel">
                <coingecko-coin-price-marquee-widget
                  coin-ids="bitcoin,ethereum,litecoin,ripple,nexus,solana,cardano"
                  currency="usd"
                  background-color="#ffffff"
                  locale="en"
                  font-color="#2f6598"
                >
                </coingecko-coin-price-marquee-widget>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- .nk-block @e -->
    </div>
  </div>

  <!-- Footer -->
  <nexus-footer></nexus-footer>
  <!-- // -->
</main>
