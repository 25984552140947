<!-- Header @s -->
<header
  class="nk-header page-header is-sticky is-shrink is-transparent is-light"
  id="header"
>
  <div class="header-main">
    <div class="header-container container">
      <div class="header-wrap">
        <!-- Logo @s -->
        <div class="header-logo logo">
          <a href="./" class="logo-link">
            <img src="./../../images/nexus-logo-blue.png" alt="logo" />
            <!-- <img
              class="logo-light"
              src="./../../images/nexus-logo-white.png"
              alt="logo"
            /> -->
          </a>
        </div>
        <!-- Menu Toogle @s -->
        <div class="header-nav-toggle">
          <a href="#" class="navbar-toggle" data-menu-toggle="header-menu">
            <div class="toggle-line">
              <span></span>
            </div>
          </a>
        </div>
        <!-- Menu @s -->
        <div class="header-navbar header-navbar-s1">
          <nav class="header-menu" id="header-menu">
            <ul class="menu" [@fadeInDown]="animationState">
              <li class="menu-item has-sub">
                <a class="menu-link nav-link menu-toggle" href="#"
                  >Technology</a
                >
                <ul class="menu-sub menu-drop">
                  <li class="menu-item">
                    <a
                      class="menu-link nav-link"
                      href="#"
                      (click)="scrollToTag($event, 'softwarestack')"
                      >Software Stack</a
                    >
                  </li>
                  <li class="menu-item">
                    <a
                      class="menu-link nav-link"
                      href="#"
                      (click)="scrollToTag($event, 'sigchains')"
                      >Signature Chains</a
                    >
                  </li>
                  <li class="menu-item">
                    <a
                      class="menu-link nav-link"
                      href="#"
                      (click)="scrollToTag($event, 'quantumresistance')"
                      >Quantum Resistance</a
                    >
                  </li>
                  <li class="menu-item">
                    <a
                      class="menu-link nav-link"
                      href="#"
                      (click)="scrollToTag($event, '3DC')"
                    >
                      3 Dimensional Chain</a
                    >
                  </li>
                </ul>
              </li>
              <li class="menu-item">
                <a
                  class="menu-link nav-link"
                  href="https://docs.nexus.io/user-guide"
                  >User Guides</a
                >
              </li>
              <li class="menu-item">
                <a
                  class="menu-link nav-link"
                  href="https://docs.nexus.io/developer-guide"
                  >Developer Guides</a
                >
              </li>
            </ul>
          </nav>
        </div>
        <!-- .header-navbar @e -->
      </div>
    </div>
  </div>
</header>
<!-- .header-main @e -->

<!-- Banner @s -->
<div class="header-banner bg-light ov-h header-banner-jasmine">
  <div class="background-shape"></div>
  <div class="nk-banner">
    <div class="banner banner-fs banner-single">
      <!-- .block @s -->
      <div class="nk-block nk-block-header my-auto">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-9 text-center">
              <div class="banner-caption">
                <div class="cpn-head">
                  <h1 class="title title-xl-2">Resource Hub</h1>
                </div>
                <div class="cpn-text cpn-text-s2">
                  <p class="lead-s2">
                    Efficient & Scalable Code, Enhanced Security,
                    <br class="d-none d-sm-block" />
                    Revolutionary Hardware
                  </p>
                </div>
                <div class="cpn-action">
                  <ul class="cpn-links">
                    <li>
                      <a class="link link-primary" href="#"
                        ><em class="link-icon icon-circle ti ti-files"></em>
                        <span>White Paper</span></a
                      >
                    </li>
                    <li>
                      <a
                        class="link link-primary video-play"
                        href="https://www.youtube.com/watch?v=8VZfrwLfwRo"
                        ><em
                          class="link-icon icon-circle ti ti-control-play"
                        ></em>
                        <span>What is Nexus?</span></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->
        </div>
      </div>
      <!-- .block @e -->
      <!-- .block @s -->
      <div class="nk-block nk-block-status">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <div class="row justify-content-center">
                <div class="col-md-8">
                  <div class="token-status token-status-s4">
                    <div class="token-box token-box-s2 bg-transparent">
                      <h4 class="title title-xs-alt tc-default">
                        CONNECTING A DECENTRALIZED WORLD
                      </h4>
                      <div
                        class="countdown-s3 countdown-s4 countdown"
                        data-date="2023/04/27"
                      ></div>
                    </div>
                    <div class="token-action token-action-s1">
                      <a class="btn btn-rg btn-grad btn-grad-alt" href="#"
                        >Back to Nexus Landing Page</a
                      >
                    </div>

                    <div class="circle-animation">
                      <div class="circle-animation-l1 ca">
                        <span
                          class="circle-animation-l1-d1 ca-dot ca-color-1"
                        ></span>
                        <span
                          class="circle-animation-l1-d2 ca-dot ca-color-2"
                        ></span>
                        <span
                          class="circle-animation-l1-d3 ca-dot ca-color-3"
                        ></span>
                        <span
                          class="circle-animation-l1-d4 ca-dot ca-color-1"
                        ></span>
                        <span
                          class="circle-animation-l1-d5 ca-dot ca-color-2"
                        ></span>
                        <span
                          class="circle-animation-l1-d6 ca-dot ca-color-3"
                        ></span>
                      </div>
                      <div class="circle-animation-l2 ca">
                        <span
                          class="circle-animation-l2-d1 ca-dot ca-color-1"
                        ></span>
                        <span
                          class="circle-animation-l2-d2 ca-dot ca-color-3"
                        ></span>
                        <span
                          class="circle-animation-l2-d3 ca-dot ca-color-2"
                        ></span>
                        <span
                          class="circle-animation-l2-d4 ca-dot ca-color-1"
                        ></span>
                        <span
                          class="circle-animation-l2-d5 ca-dot ca-color-2"
                        ></span>
                      </div>
                      <div class="circle-animation-l3 ca">
                        <span
                          class="circle-animation-l3-d1 ca-dot ca-color-1"
                        ></span>
                        <span
                          class="circle-animation-l3-d2 ca-dot ca-color-3"
                        ></span>
                        <span
                          class="circle-animation-l3-d3 ca-dot ca-color-2"
                        ></span>
                        <span
                          class="circle-animation-l3-d4 ca-dot ca-color-1"
                        ></span>
                        <span
                          class="circle-animation-l3-d5 ca-dot ca-color-2"
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </div>
  <!-- .nk-banner -->
  <!-- Place Particle Js -->
  <div
    id="particles-bg"
    class="particles-container particles-bg"
    data-pt-base="#00c0fa"
    data-pt-base-op=".3"
    data-pt-line="#2b56f5"
    data-pt-line-op=".5"
    data-pt-shape="#00c0fa"
    data-pt-shape-op=".2"
  ></div>
</div>
<!-- .header-banner @e -->

<main class="nk-pages">
  <!-- <h1
    class="sub-heading-s2 mgb-m title-semibold title title-s2 text-center technology"
  >
    Technology
  </h1> -->

  <section class="section bg-light-grad" id="softwarestack">
    <div class="ui-mask-left ui-mask-s8"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-features-s2">
        <div class="row align-items-center gutter-vr-30px">
          <div class="col-md-6">
            <div class="gfx mx-auto mx-lg-0">
              <img src="images/stack.png" alt="stack" />
            </div>
          </div>
          <!-- .col -->
          <div class="col-md-6">
            <!-- Section Head @s -->
            <div class="nk-block-text text-center text-md-start">
              <h2 class="title title-s2 title-s2-alt" title="Decentralized">
                Seven-layered software stack
              </h2>
              <p>
                The Nexus architecture is designed as a seven-layered software
                stack that includes a 64-bit register-based process virtual
                machine. Each layer is designated to carry out a specialized
                process independently of one another, providing additional
                functionality to the existing Internet stack, the OSI model.
              </p>
            </div>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>

  <section class="section bg-light pd-s layers">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <h2 class="title title-s3 text-center">7 layers</h2>

          <div id="acc-3" class="accordion">
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#acc-3-1"
              >
                Interface
                <span class="accordion-icon"></span>
              </h5>
              <div id="acc-3-1" class="collapse" data-bs-parent="#acc-3">
                <div class="accordion-content">
                  <p>
                    The interface is the user space, i.e the buttons used to
                    interact with the Dapp. This layer can be accompanied by
                    various technologies from AR/VR to mobile interfaces. Our
                    official Interface Layer, provides a platform for developers
                    to build modules and applications that embed directly into
                    the Nexus Wallet.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#acc-3-2"
              >
                Logical
                <span class="accordion-icon"></span>
              </h5>
              <div id="acc-3-2" class="collapse" data-bs-parent="#acc-3">
                <div class="accordion-content">
                  <p>
                    This is the first layer of the developer application space
                    which forms the logic of most applications. This layer
                    interacts directly with the API below it, and provides
                    functionality that does not depend on direct access to the
                    blockchain.
                  </p>
                  <p>
                    An example of this could be simple requests such as: ‘Send a
                    message to this user if another user completed this event.’
                    We postulate that in the future this layer will also be
                    driven by AI systems.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#acc-3-3"
              >
                API
                <span class="accordion-icon"></span>
              </h5>
              <div id="acc-3-3" class="collapse" data-bs-parent="#acc-3">
                <div class="accordion-content">
                  <p>
                    This layer provides an interface that allows developers to
                    gain direct access to blockchain functionality. It is based
                    on a simple verb and noun semantics, and accepts a wide
                    variety of encoding. It is the gateway into the blockchain,
                    that can be used without direct access to the lower levels
                    of the software stack, making development on Nexus as easy
                    as developing a web application.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#acc-3-4"
              >
                Operations
                <span class="accordion-icon"></span>
              </h5>
              <div id="acc-3-4" class="collapse" data-bs-parent="#acc-3">
                <div class="accordion-content">
                  <p>
                    Operations are instructions or actions that give registers
                    context, and define more complex contract logic. A contract
                    is an object containing: a register pre-state (the register
                    that is being operated on that was passed upwards from the
                    Register Layer), a primitive operation (only one primitive
                    operation per contract), and a set of conditions (any amount
                    of conditional operations).
                  </p>

                  <div class="tc-info">Primitive Operations:</div>
                  <p>
                    The current iteration of the Operations Layer contains 16
                    primitive operations, and 54 conditional operations and
                    types. The primitive operations can be best described as the
                    actions taking place on the register such as: WRITE, DEBIT,
                    TRANSFER, APPEND, etc. These actions themselves cause the
                    register to change its state in some way or form, including
                    its movement from one signature chain to another.
                  </p>

                  <div class="tc-info">Conditional Contracts:</div>
                  <p>
                    Conditional Contracts are an agreement between participating
                    parties, outlining a set of requirements that must be met
                    for a transaction to complete. They are the building blocks
                    that allow users to engage with one another, such as
                    contract expiration, or the exchanging of items. More
                    advanced forms of non-custodial escrow or arbitration are
                    also possible. Conditional statements have no limit to their
                    complexity, being capable of handling groups of groups of
                    conditions that together evaluate to either true or false.
                    In the case the conditions return true, this allows the
                    recipient of the transaction to claim their funds or object
                    (depending on if this was a TRANSFER or DEBIT). In the case
                    that the recipient is unable to satisfy the conditions,
                    after a period of time set by the sender, the transaction
                    will be redeemable.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#acc-3-5"
              >
                Register
                <span class="accordion-icon"></span>
              </h5>
              <div id="acc-3-5" class="collapse" data-bs-parent="#acc-3">
                <div class="accordion-content">
                  <p>
                    A register is the fastest storage medium inside your
                    computer’s CPU (Central Processing Unit), that the processor
                    uses to cache data in between calculations. This has become
                    a more desirable architecture for computer design as an
                    improvement to a much older architecture called a ‘stack’.
                  </p>
                  <p>
                    Nexus Registers are a data storage system that maintain an
                    immutable record and history, including current and previous
                    states, therefore they can be used to record the state of
                    applications.
                  </p>
                  <p>
                    The ownership of a register can be transferred between
                    Sigchains, and thus perform as Assets, Tokens or simple
                    objects, such as a crypto kitty. A register can also be
                    owned by a token, creating partial ownership of the asset it
                    represents. The Register Layer consists of three main
                    components: Object Registers, State Registers, and a 64-bit
                    Register Based Virtual Machine for condition execution.
                  </p>
                  <p>
                    Every register on the Nexus blockchain has two main
                    properties: An address (referred to as a register address)
                    which is used to determine the location of the data in the
                    register database, and an owner, which is the genesis hash
                    of the Signature Chain that currently owns the register.
                  </p>
                  <div class="tc-info">Object Registers:</div>
                  <p>
                    Object Registers are programmable type-safe objects recorded
                    with a predetermined structure which describes an asset,
                    token, or programmer’s object. They can be used for a wide
                    variety of purposes including company shares, digital
                    assets, certificates, data files, and accounts. Data fields
                    inside the object carry specifiers that define whether the
                    field can be mutable, making the object itself able to
                    enforce certain fields to have read-only or write access.
                  </p>
                  <div class="tc-secondary">
                    Image ID: 108629084398374<br />
                    License Type: Enhanced<br />
                    Image Title: Arizona Sunset<br /><br />
                  </div>
                  <p>
                    The above example displays a meta-data format for a
                    photographic asset that is stored in a programmable object
                    register. This format can be augmented with mutable and
                    immutable type specifiers, meaning that a field such as
                    ‘Image Title’ in the example above, could be mutable and
                    able to be modified, while the other fields would remain
                    immutable.
                  </p>
                  <div class="tc-info">State Register:</div>
                  <p>
                    A state register is a simple register that can store data in
                    any sequence without an enforced format by the Ledger. These
                    would be used by a Dapp to record an immutable state. State
                    Registers have two forms: RAW and APPEND. A RAW register is
                    bound by its initial size, and can be written to at any
                    time, while an APPEND register can only have data appended
                    to its state, keeping the original contents immutable.
                  </p>
                  <div class="tc-info">Virtual Machine:</div>
                  <p>
                    Conditional Statements are processed on top of the Virtual
                    Machine which uses 64-bit registers to maintain the state of
                    temporary condition variables as they are processed. The VM
                    increases the efficiency of conditional statements, as it
                    doesn’t carry the overhead of ‘pushing and popping’ from the
                    stack as do other VMs. This part of the Register Layer is
                    responsible for the incredible speed of conditional
                    execution, requiring only 50 nanoseconds on average per
                    instruction.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#acc-3-6"
              >
                Ledger
                <span class="accordion-icon"></span>
              </h5>
              <div id="acc-3-6" class="collapse" data-bs-parent="#acc-3">
                <div class="accordion-content">
                  <p>
                    The Ledger is responsible for ensuring all data is formed
                    under consensus and is immutable by nature. It manages
                    ownership between parties using cryptographic primitives
                    such as hashing, digital signatures, and consensus
                    protocols. It is composed of signature chains for user-level
                    states, and the proposed architecture of the
                    Three-Dimensional Chain (3DC) for the global state, the
                    latter of which is under development through the TAO
                    framework.
                  </p>
                  <div class="tc-info">Signature Chains:</div>
                  <p>
                    A Signature Chain is a decentralized blockchain account that
                    allows you to login from any computer with a username,
                    password, and pin, without the need for wallet.dat files or
                    constantly rescanning the database. They are comparable to a
                    personal blockchain that allows decentralized access through
                    the login system, removing the need to store private keys.
                    Sigchains deterministically create a mathematical 'lock'
                    that only your login credentials can unlock.
                  </p>
                  <p>
                    Fundamentally, a Signature Chain decouples the private key
                    from the account, therefore one is not bound by possession
                    or security of this single private key. The private key
                    becomes obsolete when the next transaction is generated,
                    producing higher levels of security compared to the
                    continual reuse of a private key, as is the case with other
                    blockchain technologies. Other benefits come from the
                    efficiency gained by reducing the requirement of storing a
                    large amount of signatures on disk, and the ability to use a
                    variety of key types such as FALCON for increased Quantum
                    Resistance.
                  </p>
                  <div class="tc-info">Consensus:</div>
                  <p>
                    Two Proof of Work (PoW) channels (Prime and Hashing) and one
                    Proof of Stake (PoS) channel are used to secure the network.
                    Consensus is balanced between all three channels as it is
                    based on collective chain weight, providing a higher
                    resistance to 51% attacks compared to single algorithm
                    blockchains. As the TAO framework is developed, these three
                    forms of consensus will become the three layers of the 3DC.
                  </p>
                  <div class="tc-info">Reputation:</div>
                  <p>
                    Nexus implements a reputation mechanism called ‘Trust’ that
                    records the consistent time that a node contributes to the
                    validation process. Trust decreases three times faster than
                    it accrues and translates into a variable stake reward of
                    between 0.5% to 3% per annum. Trust also benefits the
                    Network Layer, where nodes can determine the reputation or
                    reliability of the nodes they are talking to, increasing
                    security against Sybil Attacks
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#acc-3-7"
              >
                Network
                <span class="accordion-icon"></span>
              </h5>
              <div id="acc-3-7" class="collapse" data-bs-parent="#acc-3">
                <div class="accordion-content">
                  <p>
                    The Network is responsible for the end-to-end communication
                    between nodes, handling the relaying and receiving of ledger
                    level data. Nexus augments this layer by using ‘overlays’
                    which provide IPv6 functionality to the network, which
                    allows the user to control their IP address through
                    cryptography.
                  </p>

                  <div class="tc-info">Overlays:</div>
                  <p>
                    Our overlay of choice is LISP (Location Indicator Separation
                    Protocol). This creates better connectivity between peers as
                    the overlay functions as a layer of ‘elevated trust’ to the
                    Internet when combined with the ledger (blockchain), which
                    increases the safety, reliability, and security of the
                    online experience.
                  </p>
                  <div class="tc-info">IPv6:</div>
                  <p>
                    The LISP overlay combines IPv6 Crypto EIDs with Signature
                    Chains, enabling a fully encrypted peer-to-peer
                    communication system and identification of the cryptographic
                    keys through the ledger.
                  </p>
                  <div class="tc-info">Multicast:</div>
                  <p>
                    Through the Amine and Obsidian upgrades, we will deploy
                    multicast links for message propagation which will give us
                    higher degrees of scalability (constant time) in comparison
                    to existing blockchain models (exponential time). The
                    significance of constant time routing is that messages relay
                    from edge to edge of the network much faster, even with a
                    large number of connected nodes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- .container -->
  </section>

  <section class="section bg-light" id="sigchains">
    <div class="background-shape bs-reverse"></div>
    <div class="container">
      <div class="nk-block nk-block-features-s2">
        <div class="row align-items-center gutter-vr-30px">
          <!-- .col -->
          <div class="col-lg-12">
            <!-- Section Head @s -->
            <div class="nk-block-text text-center text-md-start">
              <h2 class="title title-s2 title-s2-alt" title="Decentralized">
                Signature Chains
              </h2>

              <img
                class="sigchains-img"
                src="images/sigchains.jpg"
                alt="stack"
              />

              <p>
                A major benefit of using cryptocurrencies is the security gained
                through the use of public key cryptography. Though this is a
                vast improvement to centralized login systems, this benefit
                comes with the drawback of key management. In order to manage
                keys, most cryptocurrency wallets use a database storage file
                called a ‘wallet.dat’ that keeps a record of all the keys that
                have been / will be used to access coins. We believe that the
                adoption of cryptocurrency and blockchain technology will always
                be limited if relying on such a model, due to the inconvenience
                of having to make regular wallet backups, loss or theft of hard
                drives, and the risk of sending funds to unspendable addresses.
                These systems are susceptible to human error, boding for the
                need for complex hardware designed specifically to store private
                keys securely. Though these devices are a step towards user
                friendliness, they are still at risk of being lost or stolen,
                and therefore are not a reliable replacement for authorization
                systems.
              </p>
              <p>
                Therefore, we have automated the management of keys via a
                decentralized login system we call Signature Chains. This allows
                you to log in to your Nexus Wallet simply through a Username,
                Password and PIN. Signature Chains still use public key
                cryptography, but rather than maintaining the keys on disk or
                the cloud, they are stored in ‘mathematical hyperspace’ meaning
                that your credentials become the 'key' to a 'virtual space' that
                you own with a Signature Chain. This also removes the burden of
                key management when using DApps, which often require third party
                plugins such as MetaMask. Signature Chains decouple the private
                key from the user account, therefore one is unbound by the
                possession or security of a single private key. The private key
                becomes obsolete when the next transaction is generated,
                producing higher levels of security compared to the continual
                reuse of a private key, as is the case with other blockchain
                technologies. Signature Chains (Sigchains) are comparable to a
                'personal Blockchain', and provide the foundation for DApps that
                manage all types of digital property. They integrate into
                existing application frameworks that already rely on
                authorization systems through username and password
                combinations, and thereby provide DApp developers the ability to
                integrate blockchain functionalities as an elevated layer into
                existing applications with minimal architectural modification.
                They can also be integrated with various security measures such
                as biometrics and hardware password managers. Additional
                benefits are the efficiency gained by reducing the requirement
                of storing a large amount of signatures on disk, and the ability
                to use a variety of key types such as FALCON for increased
                Quantum Resistance.
              </p>
            </div>

            <div id="sigchain-acc" class="accordion">
              <div class="accordion-item accordion-item-s3">
                <h5
                  class="accordion-title accordion-title-sm collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#sigchain-acc-1"
                >
                  Speed & Scalability
                  <span class="accordion-icon"></span>
                </h5>
                <div
                  id="sigchain-acc-1"
                  class="collapse"
                  data-bs-parent="#sigchain-acc"
                >
                  <div class="accordion-content">
                    <p>
                      Sigchains use an account based model that replaces the
                      clunky UTxO (Unspent Transaction Output) architecture that
                      Bitcoin introduced. Sigchains contain all user data on a
                      unique chain, and provide higher levels of scalability, as
                      only one signature is required to be verified per
                      transaction.
                    </p>
                    <p>
                      Along with Sigchains, Nexus transactions are decoupled
                      from the block, which means that only a single hash or
                      ‘proof’ per transaction is required in the block level
                      data, rather than the entire transaction itself. This
                      results in the accommodation of approximately 31,728
                      transactions per 2 MB block. Together, these innovations
                      produce lightweight blocks and efficient transaction
                      processing, without the requirement of off chain (Layer 2)
                      scaling solutions.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item accordion-item-s3">
                <h5
                  class="accordion-title accordion-title-sm collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#sigchain-acc-2"
                >
                  UTxO
                  <span class="accordion-icon"></span>
                </h5>
                <div
                  id="sigchain-acc-2"
                  class="collapse"
                  data-bs-parent="#sigchain-acc"
                >
                  <div class="accordion-content">
                    <p>
                      UTxO is an architecture envisioned by Satoshi Nakamoto in
                      the Bitcoin whitepaper under Section 9, ‘Splitting and
                      Combining Value’ [<a
                        href="https://bitcoin.org/bitcoin.pdf"
                        >Bitcoin Whitepaper</a
                      >]. In this architecture, outputs contain a given value,
                      such as 0.5 BTC, which then become inputs to another. The
                      following diagram illustrates this model:
                    </p>
                    <p>
                      These outputs are then ‘split and combined’ into more
                      outputs in the next transaction as shown in the example in
                      the diagram above: 50 BTC is split into two outputs of 0.5
                      BTC and 49.5 BTC, respectively. Though this architecture
                      provides the benefit of privacy, it does not scale very
                      well due to the need for many exhaustive cryptographic
                      operations to move even a small amount of coins. This is
                      because the minimum transaction amount per input is
                      0.00000001, which could result in the requirement for
                      thousands of inputs, each of which would require a
                      signature verification, increasing the size of the
                      transaction. A notable example of this is:
                    </p>
                    <p>
                      <a
                        href="https://www.blockchain.com/btc/tx/bb41a757f405890fb0f5856228e23b715702d714d59bf2b1feb70d8b2b4e3e08"
                        >The largest Bitcoin transaction ever made</a
                      >
                    </p>
                    <p>
                      As you can see from the above link, this transaction
                      consumed an entire Bitcoin block and was only moving
                      0.05569 BTC. If this were a common occurrence, Bitcoin
                      would only be able to process 1 transaction every ten
                      minutes. This is not the only example of the limitations
                      of the UTxO model, which creates inefficiency and serves
                      as a unique attack vector to any chain that employs it.
                    </p>
                    <p>
                      <a
                        href="https://www.reddit.com/r/litecoin/comments/9ncqse/what_should_we_do_about_the_50_of_litecoins_utxo/"
                        >50% of Litecoin’s UTXO is unspendable</a
                      >
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item accordion-item-s3">
                <h5
                  class="accordion-title accordion-title-sm collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#sigchain-acc-3"
                >
                  Pruning
                  <span class="accordion-icon"></span>
                </h5>
                <div
                  id="sigchain-acc-3"
                  class="collapse"
                  data-bs-parent="#sigchain-acc"
                >
                  <div class="accordion-content">
                    <p>
                      A Signature Chain is named as such due to it being a chain
                      of signatures and public keys, all linked together through
                      the next hash and previous transaction hash. Since this is
                      a consistent chain of events that cannot be altered, it
                      provides additional scaling benefits by requiring only two
                      signatures to verify an entire Sigchain: the first and
                      last transaction. This means that all the signatures in
                      between can be discarded along with the public keys,
                      saving a large amount of storage space without sacrificing
                      security. This is because a Sigchain is an immutable chain
                      of transactions A valid signature at the head transaction
                      of the chain proves that the entire chain is valid, as it
                      locks the entire Sigchain from modification.
                    </p>

                    <p>
                      Usually, this signature is the largest part of the
                      transaction, therefore removing the need to save it on
                      disk improves the scalability of the Nexus Blockchain. In
                      Bitcoin, the average input size is 186 bytes, with the
                      signature consuming 146 of these bytes, resulting in 77%
                      of the Bitcion blockchain being comprised of signatures
                      stored on disk. As you can see, signature chaining has a
                      huge impact on combating blockchain bloat.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item accordion-item-s3">
                <h5
                  class="accordion-title accordion-title-sm collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#sigchain-acc-4"
                >
                  Secure Digital Identity
                  <span class="accordion-icon"></span>
                </h5>
                <div
                  id="sigchain-acc-4"
                  class="collapse"
                  data-bs-parent="#sigchain-acc"
                >
                  <div class="accordion-content">
                    <p>
                      An End Point Identifier (EID) is similar to an Internet
                      Protocol (IP) address, and is a form of identity on the
                      network layer. Through the use of LISP, the EID becomes
                      the identifier, while the RLOC (Routing Locator) becomes
                      the location. This decoupling enables a device to freely
                      roam between networks as only the RLOC changes, not the
                      EID. This is contrary to how the Internet currently
                      functions, where both the identifier and location are
                      bound together in a single IP address.
                    </p>
                    <p>
                      An EID is a critical security feature, as it can be linked
                      to a Sigchain which makes the network identifier
                      cryptographically associated with the Ledger. EIDs,
                      Sigchains and the use of reputation combined create an
                      elevated layer of trust to the Internet, as one can verify
                      with certainty that the other party is who they claim to
                      be without the need for third party services like
                      Certificate Authorities (CA). A reduction in fraud,
                      hacking, fake accounts, and identity theft for both
                      consumers and service providers is envisioned as a result
                      of this technology.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item accordion-item-s3">
                <h5
                  class="accordion-title accordion-title-sm collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#sigchain-acc-5"
                >
                  Quantum Resistance
                  <span class="accordion-icon"></span>
                </h5>
                <div
                  id="sigchain-acc-5"
                  class="collapse"
                  data-bs-parent="#sigchain-acc"
                >
                  <div class="accordion-content">
                    <p>
                      Sigchains increase resistance to attack by both classical
                      and theoretical quantum computers. This is achieved by
                      decoupling the identity of the account from the
                      cryptography associated with it, similar to how LISP
                      decouples the identifier and the location. This enables
                      the architectural advantage of changing the key pair that
                      is used to access the Sigchain with every transaction, and
                      hiding the public key until it is used. When an individual
                      creates a transaction on the network, they claim ownership
                      by revealing the public key of the NextHash (the hash of
                      the public key) by producing a valid signature from the
                      one time use private key. This significantly reduces the
                      time window for an attack to take place, naturally
                      increasing the required computing power to successfully
                      hijack a signature chain with MITM (Man in the Middle)
                      attacks. Please see Quantum Resistance for more
                      information.
                    </p>
                    <p>
                      Since the account identity is decoupled from the
                      cryptography, a Sigchain supports multiple signature
                      schemes such as FALCON (Fast Fourier Lattice-Based Compact
                      Signatures Over NTRU) or Brainpool. Due to FALCON being
                      still under study and assessment by NIST (National
                      Institute of Standards and Technology), it is generally
                      recommended that it is only used in production in what is
                      termed a ‘hybrid signature scheme’, meaning you don’t rely
                      solely on the security of FALCON for the security of the
                      system. In our implementation, a Sigchain acts like a
                      hybrid signature scheme, making use of FALCON safe for our
                      production environment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
      </div>
    </div>
  </section>

  <section class="section bg-light-grad" id="quantumresistance">
    <div class="ui-mask-right ui-mask-s11"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-features-s2">
        <div class="row align-items-center gutter-vr-30px">
          <div class="col-md-5">
            <div (appear)="onAppearQR()" class="gfx mx-auto mx-lg-0">
              <img
                *ngIf="qrAnimationState"
                [@bounceInUpOnEnter]
                src="images/quantum.png"
                alt="qr"
              />
            </div>
          </div>
          <!-- .col -->
          <div class="col-md-7">
            <!-- Section Head @s -->
            <div class="nk-block-text text-center text-md-start">
              <h2 class="title title-s2 title-s2-alt" title="Decentralized">
                Quantum Resistance
              </h2>
              <p>
                With the rise in the power of classical computers and the
                emergence of quantum computers, public keys are becoming
                increasingly vulnerable. Most cryptocurrency addresses are
                created by hashing or obscuring the public key. Though, when a
                user makes a transaction the public key is revealed on the
                blockchain. In the realm of classical computing there is little
                risk with this method. However, a quantum computer running
                Shor’s algorithm could break most public key cryptography in
                little to no time, resulting in funds being stolen. Though most
                conjectures range from five to ten years before security could
                begin to break, Nexus has prepared by integrating a number of
                cryptographic innovations that support increased levels of
                quantum resistance.
              </p>
              <p>
                We have developed an architecture called Signature Chains that
                enhance the security of existing DSA (Digital Signature
                Algorithm), by hashing the public key until it is used while
                changing the key pair with every transaction. We have also
                integrated the following cryptographic functions: FALCON (a
                second round contender for the NIST Post-Quantum cryptography
                competition), Argon2 (winner of the password hashing
                competition, and a superior alternative to S-Crypt or B-Crypt),
                and Keccak (winner of the SHA3 competition).
              </p>
            </div>
          </div>
          <!-- .col -->

          <div id="qr-acc" class="accordion">
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#qr-acc-1"
              >
                Classic VS Quantum Computer
                <span class="accordion-icon"></span>
              </h5>
              <div id="qr-acc-1" class="collapse" data-bs-parent="#qr-acc">
                <div class="accordion-content">
                  <p>
                    Classical computing uses an array of transistors. These
                    transistors form the heart of your computer (the CPU). Each
                    transistor is capable of being either on or off, and these
                    states are used to represent the numerical values 1 and 0.
                    Binary digits’ (bits) number of states depends on the number
                    of transistors available, according to the formula (2^n) +
                    1, with n being the number of transistors. Classical
                    computers can only be in one of these states at any one
                    time, so the speed of your computer is limited to how fast
                    it can change state.
                  </p>
                  <p>
                    Quantum computers on the other hand, use what are termed
                    quantum bits or ‘qubits’ which are represented by the
                    quantum spin of electrons or photons. These particles are
                    placed into a state called superposition, allowing the qubit
                    to assume a value of 1 and 0 simultaneously, generally
                    resulting in an exponential increase in computational power
                    over their classical counterparts.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#qr-acc-2"
              >
                Signature Chains
                <span class="accordion-icon"></span>
              </h5>
              <div id="qr-acc-2" class="collapse" data-bs-parent="#qr-acc">
                <div class="accordion-content">
                  <p>
                    Nexus is accessible through technology we designed called
                    Signature Chains, a decentralized blockchain account that
                    allows you to login from any computer with a username,
                    password, and pin. They are comparable to a personal
                    blockchain that allows decentralized access through a login
                    system, removing the need to store a private key. Sigchains
                    deterministically create a mathematical ‘lock’ that only
                    your login credentials can unlock.
                  </p>
                  <p>
                    Fundamentally, a Sigchain decouples the private key from the
                    user account, therefore one is unbound by the possession or
                    security of a single private key. When one creates a
                    transaction on the network, they claim ownership by
                    revealing the public key of the NextHash (the hash of your
                    public key) and produce a signature from the one time use
                    private key. The private key becomes obsolete when the next
                    transaction is generated, producing higher levels of
                    security compared to the continual reuse of a private key,
                    as is the case with other blockchain technologies. The
                    future use of biometric username generation will strengthen
                    your credentials and Signature Chain access.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#qr-acc-3"
              >
                Key Management
                <span class="accordion-icon"></span>
              </h5>
              <div id="qr-acc-3" class="collapse" data-bs-parent="#qr-acc">
                <div class="accordion-content">
                  <p>
                    Signature Chains decouple keys from the user account,
                    meaning that at any time, you are able to change the type of
                    key that your account uses. This gives users the option to
                    use Post-Quantum cryptography such as FALCON, or the option
                    to use more time-tested Brainpool curves. If any flaws were
                    to be found in either of these cryptographic schemes, your
                    account would be safe using a signature chain. These
                    safeguards are important in order to protect systems over
                    time, as ongoing crypto-analysis are always finding
                    vulnerabilities and attack vectors that will begin to break
                    once secure cryptographic standards (eg. SHA1).
                  </p>
                  <p>
                    <a
                      target="_blank"
                      href="https://bitcoinwhoswho.com/blog/2017/09/13/are-your-bitcoins-safe-large-bitcoin-collider-finds-another-private-key/"
                      >Large Bitcoin Collider Finds Another Bitcoin Private
                      Key</a
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#qr-acc-4"
              >
                Falcon
                <span class="accordion-icon"></span>
              </h5>
              <div id="qr-acc-4" class="collapse" data-bs-parent="#qr-acc">
                <div class="accordion-content">
                  <p>
                    Complemented with this is the use of FALCON (Fast-Fourier
                    Lattice-Based Compact-Signatures Over NTRU) as an optional
                    setting, that uses Lattice Based cryptography to ensure the
                    security of accounts in the post-quantum age. The
                    computational requirements are at least 1/40th of Elliptic
                    Curve Digital Signature Algorithm (ECDSA), which means you
                    can verify signatures much faster than ECDSA. However, the
                    downside is that it requires about 1.5kb for both the public
                    key and signature. Though Falcon is based on aged and proven
                    mathematics (NTRU lattices), it has not undergone as much
                    crypto-analysis as Elliptic Curve Cryptography (ECC) or
                    Rivest Shamir Adleman (RSA).
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#qr-acc-5"
              >
                Argon2
                <span class="accordion-icon"></span>
              </h5>
              <div id="qr-acc-5" class="collapse" data-bs-parent="#qr-acc">
                <div class="accordion-content">
                  <p>
                    Is an open source password hashing function we have
                    integrated for key and username generation. Argon2 is a
                    memory-hard password hashing algorithm with variable
                    complexity which means it can control how many seconds it
                    takes to generate a key or username. This drastically
                    increases the time and resources it takes an offline hacker
                    to brute-force a Signature Chain. Because the time to
                    generate an Argon2 hash is bound by memory latency, a
                    specialized ‘password cracking’ device has no advantage over
                    a general purpose CPU.
                  </p>
                  <p>
                    Our default Argon2 settings requires at least 0.3 – 0.5
                    seconds to generate a new key, meaning one is only able to
                    try two to three passwords per second. Combining this with a
                    minimum requirement of at least 8 alphanumeric [a-Z, 0-9]
                    characters per password, even if the username and PIN were
                    known by the attacker, the time required to crack the
                    password would be in the order of 5 million years.
                  </p>
                </div>
              </div>
            </div>

            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#qr-acc-6"
              >
                Keccak
                <span class="accordion-icon"></span>
              </h5>
              <div id="qr-acc-6" class="collapse" data-bs-parent="#qr-acc">
                <div class="accordion-content">
                  <p>
                    Due to the recommendation from NIST (National Institute for
                    Standards in Technology), the bit requirement for symmetric
                    encryption schemes and hash functions must be at least twice
                    the size for equivalent quantum resistance (eg. 512 vs 256).
                    This recommendation inspires our standard hash: 256-bits for
                    registers, 512-bit for transactions, and 1024-bit for blocks
                    for equivalent 128-bit, 256-bit, and 512-bit quantum
                    resistance respectively.
                  </p>
                </div>
              </div>
            </div>

            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#qr-acc-7"
              >
                Cryptographic Redundancy
                <span class="accordion-icon"></span>
              </h5>
              <div id="qr-acc-7" class="collapse" data-bs-parent="#qr-acc">
                <div class="accordion-content">
                  <p>
                    We do not rely on the security of only one cryptographic
                    function for the security of the entire system, and treat
                    every public key as disposable once used. This means our
                    security uses many different layers of redundancy to provide
                    protection, in the event that one of them becomes
                    vulnerable. Relying on a single private key for security is
                    a ticking time bomb, though this approach is largely used by
                    most blockchain applications.
                  </p>
                  <p>
                    <a
                      target="_blank"
                      href="https://medium.com/@neha/cryptographic-vulnerabilities-in-iota-9a6a9ddc4367"
                      >Cryptographic Flaws found in IOTA</a
                    >
                  </p>
                </div>
              </div>
            </div>

            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#qr-acc-8"
              >
                Inherited Security Flaws
                <span class="accordion-icon"></span>
              </h5>
              <div id="qr-acc-8" class="collapse" data-bs-parent="#qr-acc">
                <div class="accordion-content">
                  <p>
                    The copy/paste mentality of source code used to create many
                    cryptocurrency projects today has led to the pervasion of
                    many security flaws. Below is one such example that created
                    a pandemonium for hundreds of projects that inherited a flaw
                    from Zcoin.
                  </p>
                  <p>
                    <a
                      target="_blank"
                      href="https://micky.com.au/expert-warning-fatal-flaw-embedded-in-all-privacy-coins/"
                      >Fatal Flaws may be embedded in all Privacy Coins</a
                    >
                  </p>
                </div>
              </div>
            </div>

            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#qr-acc-9"
              >
                Security Considerations
                <span class="accordion-icon"></span>
              </h5>
              <div id="qr-acc-9" class="collapse" data-bs-parent="#qr-acc">
                <div class="accordion-content">
                  <p>
                    Nexus offers post quantum cryptography as an optional
                    feature to users, as at the moment ECC is still considered
                    safe. Our optional safeguards provide adequate levels of
                    resistance against attacks using a quantum computer. The
                    following will describe a MITM (Main-In-The-Middle) attack,
                    which is the only known attack able to penetrate a signature
                    chain if the user has not opted in to use FALCON (post
                    quantum cryptography).
                  </p>
                  <p>
                    A MITM attack can be described as someone eavesdropping on
                    node communication, and at the same time, relaying
                    information to other nodes, masquerading as the sender. In
                    order for this type of attack to take place, an attacker
                    would need to control the sigchain of every single node that
                    the user is connected to (i.e to compromise the private keys
                    of all connected nodes). This is because Nexus records a
                    hash in a sigchain’s crypto object register, that is a hash
                    of the certificate being used for SSL/TLS (Secure Socket
                    Layer / Transport Layer Security). This means that the
                    attacker has further difficulty pretending to be a remote
                    node, because the blockchain acts as a certificate authority
                    (CA).
                  </p>
                  <p>
                    On top of verifying certificates, nodes require additional
                    authentication before the network will accept their
                    transactions. The authentication message is signed by
                    another key in the crypto object register, which can be
                    facilitated by FALCON or Brainpool. In order to successfully
                    authenticate a connection as a MITM attacker, the attacker
                    would need to gain access to all the remote node’s
                    authentication keys.
                  </p>
                  <p>
                    <em
                      >Note: * It is important to note that a replay attack
                      would not work in this case, due to the authentication
                      message containing a random session Nonce and timestamp
                      that even if intercepted cannot be used to fake
                      authentication.</em
                    >
                  </p>
                  <p>
                    Now, we will detail the steps of an attempted attack,
                    assuming the user has not configured their account to use
                    FALCON, and that the attacker is using a 3,800 qubit quantum
                    computer.
                  </p>
                  <ul class="list list-dot">
                    <li>
                      The MITM attacker needs to first: brute force a user's
                      certificate key or wait until the user's node sends it out
                      in a key exchange. They cannot create their own
                      self-signed certificate as in a normal MITM attack, since
                      the blockchain acts as a certificate authority (CA).
                    </li>
                    <li>
                      The attacker now needs to brute force the user's
                      authentication hash or again wait for an authentication
                      message, and attempt to break the authentication key.
                      Breaking this key would enable the attacker to forge
                      authentication messages, and thus pretend to be a node
                      that the user is connected to and trusts.
                    </li>
                    <li>
                      The attacker would then need to break the remote node's
                      authentication key (assuming they can forge signatures on
                      every open connection which is 16+ nodes, and that the
                      remote node is not already using FALCON).
                    </li>
                    <li>
                      The attacker would then need to wait for the user to
                      create a transaction, i.e for them to reveal their public
                      key, and in less than 200ms, break this public key in
                      order to forge transactions.
                    </li>
                  </ul>
                  <p>
                    This is a requirement, because to successfully hijack a
                    user’s account, the attacker would need to capture the
                    transaction before it was broadcast, brute force the
                    sender’s public key to obtain its private key, and then
                    broadcast a forged transaction to take over this user’s
                    account, all in less than 200ms.
                  </p>
                  <p>
                    <b><span>Why is the attack window only 200ms?</span></b>
                  </p>
                  <p>
                    The network considers the most valid transaction the one
                    that nodes receive first. With the internet round trip time
                    taking roughly 200ms, this is your upper bound of packet
                    propagation time. Therefore, in order to create a
                    conflicting transaction that could hijack a user’s signature
                    chain, the attacker would need their ‘attack’ transaction to
                    propagate over the network before the user’s transaction.
                    The window is only 200ms as the users transaction begins to
                    propagate at the time that the users public key is revealed,
                    making it highly improbable that any computer, including a
                    quantum computer, could ever break a user’s public key.
                  </p>
                  <p>
                    We believe that even when ECC is being used instead of
                    FALCON, the likelihood of a successful attack even by a
                    quantum computer is very unlikely. However, users that wish
                    to move away from ECC and switch to FALCON can do so with a
                    click of a button, and with little risk to their previous
                    transactions. This is because the keys that have already
                    been used in our Signature Chain architecture, no longer
                    have any use, and therefore can not be attacked.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>

  <section class="section bg-theme-accent-alt" id="3DC">
    <!-- <div class="ui-mask-right ui-mask-s11"></div> -->
    <div class="background-shape"></div>
    <div class="container">
      <div class="nk-block">
        <div class="row align-items-center gutter-vr-30px">
          <div class="col-lg-8">
            <div class="nk-block-text">
              <h2 class="title title-s2 title-s2-alt" title="Decentralized">
                3 Dimensional Chain
              </h2>
              <p>
                The Three-Dimensional Chain (3DC) is designed to solve the
                'Blockchain Trilemma'. It will transform the ledger into a
                multi-layered processing system, in order to scale securely and
                with a high degree of decentralization. The Blockchain Trilemma
                is an opinion that only two of the three qualities, Security,
                Decentralization and Scalability, are achievable concurrently.
              </p>
              <p>
                The 3DC chains together cryptographic primitives into a three
                dimensional immutable object (a 3D block), and has three core
                dimensions: reputation channels (X), immutability or
                authenticity (Y), and time (Z). This architecture is being
                deployed through the TAO framework.
              </p>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="nk-block-img">
              <img class="cubes" src="images/3DC.png" alt="3DC" />
            </div>
          </div>

          <div id="threedc-acc" class="accordion">
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#threedc-acc-1"
              >
                Scalability
                <span class="accordion-icon"></span>
              </h5>
              <div
                id="threedc-acc-1"
                class="collapse"
                data-bs-parent="#threedc-acc"
              >
                <div class="accordion-content">
                  <p>
                    The architecture of legacy blockchains is comparable to
                    driving a car on a single lane highway – as the volume of
                    cars increases, traffic occurs. Nexus views ‘scalability’ as
                    a requirement, not a feature. Therefore, we design protocols
                    that scale as more nodes join the network, processing
                    unhindered even with the increase of resource requirements.
                  </p>
                  <p>
                    Using ‘Signature Chains’, ‘Aggregation’ and ‘Computational
                    Sharding’, the 3DC creates parallel lanes of transaction
                    processing to produce the L1 layer, the base layer of the
                    3DC. Data is then stored between many nodes using what we
                    term ‘Data Sharding’, that eliminates the need for
                    synchronizing and storing the entire blockchain.
                    <a href="./lisp">LISP</a> (Location Identifier Separation
                    Protocol) and the ‘LLL’ (Lower Level Library) together form
                    the common interface for this, that results in an increase
                    of distributed data storage as more nodes join the network
                    providing longer term scaling potential.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#threedc-acc-2"
              >
                Signature Chains
                <span class="accordion-icon"></span>
              </h5>
              <div
                id="threedc-acc-2"
                class="collapse"
                data-bs-parent="#threedc-acc"
              >
                <div class="accordion-content">
                  <p>
                    Nexus transactions no longer use the UTXO (Unspent Tx
                    Outputs) architecture, where you have outputs from one
                    transaction being inputs to another, resulting in a large
                    amount of expensive signature verifications for even small
                    transactions. Though UTXO was an important cornerstone of
                    the Bitcoin architecture, it has proven to be outdated and
                    vulnerable to many different types of attacks and scaling
                    limitations.
                  </p>
                  <p>
                    Why is this important to avoid?
                    <a
                      target="_blank"
                      href="https://www.reddit.com/r/litecoin/comments/9ncqse/what_should_we_do_about_the_50_of_litecoins_utxo/"
                      >50% of Litecoin’s UTXO is unspendable.</a
                    >
                  </p>
                  <p>
                    As a move away from legacy blockchain architecture, Nexus
                    has designed and implemented an architecture named Signature
                    Chains, that act as personal user-level blockchains
                    containing all of your data in one unique chain. This
                    architecture provides higher scaling characteristics, as
                    only one signature needs to be verified per transaction.
                    Conversely, a single UTXO transaction could contain 1000’s
                    of inputs (and therefore require 1000’s of signature
                    verifications), in order to transact even a small amount of
                    coins (less than 0.00001). Additionally, Signature Chains
                    don’t require wallet.dat files, as they are accessible by
                    login credentials (username, password and PIN). This
                    verifies authenticity and identity of individuals utilizing
                    reputation on the network, without sacrificing privacy.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#threedc-acc-3"
              >
                Aggregation
                <span class="accordion-icon"></span>
              </h5>
              <div
                id="threedc-acc-3"
                class="collapse"
                data-bs-parent="#threedc-acc"
              >
                <div class="accordion-content">
                  <p>
                    Transactions in legacy blockchains are not only referenced
                    in a block, they are also transported with it. Though this
                    does contain some positive characteristics for processing,
                    it limits scale as transactions require transport twice,
                    once when created, and again when the block itself is
                    broadcast. In order to combat this inefficiency, the Tritium
                    protocol stores the transaction object separately from the
                    block object, and references the txid inside the block. This
                    is the first form of ‘Aggregation’, that means that a single
                    reference can represent the entire transaction, thus
                    reducing the data that is transported in blocks. This
                    results in enhanced levels of scaling, and improved security
                    by lowering the probability of successful Finney attacks on
                    the network.
                  </p>
                  <p>
                    What’s a Finney Attack?
                    <a
                      target="_blank"
                      href="https://bitcointalk.org/index.php?topic=3441.msg48384#msg48384"
                      >Hal Finney Discovered it in 2011.</a
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#threedc-acc-4"
              >
                Computational Sharding
                <span class="accordion-icon"></span>
              </h5>
              <div
                id="threedc-acc-4"
                class="collapse"
                data-bs-parent="#threedc-acc"
              >
                <div class="accordion-content">
                  <p>
                    Computational Sharding is necessary for the division of work
                    between specific types of nodes, to create ‘lanes’ that
                    process data in parallel comparable to multiple lanes of a
                    highway. Though computational sharding is powerful, it can
                    be less secure if implemented incorrectly. The reason is
                    that a ‘shard’ will be easier to dominate than an entire
                    network, as it is smaller. The way to resolve this is
                    through the use of a multi-layered ledger (explained in
                    Security) inherent in the 3DC. Layers of consensus allow the
                    shards below to be smaller in size than those above,
                    safeguarding conflicts from attacks.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#threedc-acc-5"
              >
                Data Sharding
                <span class="accordion-icon"></span>
              </h5>
              <div
                id="threedc-acc-5"
                class="collapse"
                data-bs-parent="#threedc-acc"
              >
                <div class="accordion-content">
                  <p>
                    Data Sharding is the division of data to be stored between
                    many nodes. This can be thought of as having many warehouses
                    to store packages (data) after they have been processed and
                    transported. Due to every object being ‘verifiable’ by its
                    index hash, the 3DC can provide Data Sharding with limited
                    trust in remote nodes.
                  </p>
                  <p>
                    The difficulty is, how the state of so many objects and
                    shards is managed? The use of
                    <a href="./lisp">LISP</a> solves this problem. The method by
                    which the 3DC performs Data Sharding, a ‘network’ is created
                    that exists everywhere, where instead of ‘IP’ addresses, you
                    have ‘hashes’. This could be compared to typing in a txid in
                    your web browser, and receiving that transaction. Using LISP
                    in this manner, we would enable the browser (or LLP in
                    network terms) to open a connection to a hash, which points
                    to the group of nodes that holds a particular piece of data.
                  </p>
                  <p>
                    The end result of this is, a user can login to their node
                    that has never communicated with the network before,
                    generate a ‘genesis-id’ using their credentials and open a
                    connection to the hash, utilizing the existing internet to
                    route to the node that contains this particular piece of
                    data. The beauty of this is that the network itself doesn’t
                    need to add superfluous data synchronization across nodes to
                    know where data is held. Nodes use the overlay to route
                    remote node requests, resulting in IP addresses as hashes of
                    data that exists in the wonderful world of Nexus.
                  </p>
                  <p>
                    Data sharding is an essential facet of the 3DC in order to
                    achieve long-term scalability. Amine will provide the
                    opportunity for nodes to run in ‘shard mode’, lowering their
                    disk and memory usage even when the network is experiencing
                    high load. Data sharding in Obsidian will extend to critical
                    network functions, resulting in nodes being required to
                    store only a portion of the entire chain.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#threedc-acc-6"
              >
                Multicast
                <span class="accordion-icon"></span>
              </h5>
              <div
                id="threedc-acc-6"
                class="collapse"
                data-bs-parent="#threedc-acc"
              >
                <div class="accordion-content">
                  <p>
                    Additional to the cryptographic structure challenges
                    described in Data Sharding, the Internet, must be capable of
                    routing efficiently. We utilize what is termed ‘IP
                    Multicast’ that enables a single broadcast of a message to
                    be initiated by a node, rather than every node needing to
                    replicate the message as it is verified. This can be likened
                    to a public speaker broadcasting a message to an audience
                    (multicast), rather than having a one-on-one conversation
                    (unicast), where the message is then gossiped from one
                    person to the next. You can imagine how this would not only
                    improve the scalability, but also the integrity of the
                    message (as gossip often fails to reflect the original
                    conversation). Packets and transactions will route in
                    constant time no matter how many nodes are part of the
                    system.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#threedc-acc-7"
              >
                Lower Level Library (LLL)
                <span class="accordion-icon"></span>
              </h5>
              <div
                id="threedc-acc-7"
                class="collapse"
                data-bs-parent="#threedc-acc"
              >
                <div class="accordion-content">
                  <p>
                    The Lower Level Library (LLL) is the foundation of the TAO
                    framework, enabling support for other web technologies with
                    high reliability, performance, and extensibility. The LLL is
                    a high-performance ‘Template Library’ designed to power
                    emerging-web technologies. Most web technology today is
                    clunky, centralized, and over-engineered as a result of
                    decades of development. The LLL is simple, powerful, and
                    lightweight; made with modularity in mind.
                  </p>
                  <p>
                    It contains three main components: Cryptography (LLC),
                    Database (LLD), and Protocol (LLP). The Cryptography
                    component contains
                    <a href="./quantum-resistance">Quantum Resistant</a>
                    cryptography, the Database component out performs Google’s
                    LevelDB by orders of magnitude, and the Protocol component
                    handles well over 450k requests per second.
                  </p>
                  <p>
                    The Lower Level Database (LLD) is Nexus’ fast and modular
                    storage engine, which to the best of our knowledge, is
                    capable of outperforming most existing embedded database
                    engines. Our average results are around 0.33 seconds for
                    100k writes and reads to disk (one then the other). This
                    rivals other storage engines such as Google’s LevelDB.
                  </p>
                  <p>
                    The Lower Level Protocol (LLP) is a fundamental component of
                    the Network Layer, a light and fast protocol that allows a
                    developer to customize their packet design and message
                    interpretation. It gains scalability through simplicity, and
                    is capable of managing a large number of concurrent
                    connections.
                  </p>
                  <p>
                    The Lower Level Cryptography (LLC) is a light and efficient
                    library that contains many useful cryptographic functions
                    such as post-quantum cryptography, AES and Argon2. The
                    library provides an easily accessible set of high
                    performance cryptographic functions to ensure maximum
                    scaling potential. An example would be our benchmarks of
                    FALCON (used in the TAO) that verified 150k signatures on a
                    consumer grade apple laptop, where ECDSA (Elliptic Curve
                    Digital Signature Algorithm; used in Bitcoin, Ethereum,
                    etc.) performed only 4k signatures.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#threedc-acc-8"
              >
                Security
                <span class="accordion-icon"></span>
              </h5>
              <div
                id="threedc-acc-8"
                class="collapse"
                data-bs-parent="#threedc-acc"
              >
                <div class="accordion-content">
                  <p>
                    Nexus employs multiple consensus systems that ‘check and
                    balance’ one another. Diversity strengthens the gene pool of
                    the human species, likewise it is an equally important
                    property for the security of a decentralized system. The 3DC
                    is designed as multiple layers of transaction processing or
                    ‘consensus’, and each of the layers aggregate data from the
                    layer below. The nodes performing work on L2, resolve any
                    conflicts in L1 shards, using ‘stake’ and ‘trust’ as the
                    ‘weight’ to determine consensus. In the event that there is
                    a conflict, it is resolved through the validity of data,
                    which is defined as (trust + weight). The L3 layer will
                    consolidate hashes from L2 to create the final 3D block.
                  </p>
                  <p>
                    Nexus considers the use of cryptography very seriously, as a
                    flaw in these functions could compromise the security of the
                    entire network. We only employ well tested and thoroughly
                    peer reviewed cryptography to support increased levels of
                    Quantum Resistance.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#threedc-acc-9"
              >
                Trust and Weight
                <span class="accordion-icon"></span>
              </h5>
              <div
                id="threedc-acc-9"
                class="collapse"
                data-bs-parent="#threedc-acc"
              >
                <div class="accordion-content">
                  <p>
                    Trust is defined as the total time a specific user
                    (Signature Chain) has been contributing to the network. This
                    time is measured by the consistency and availability of a
                    node to validate transaction data.
                  </p>
                  <p>
                    Weight is defined as the real time resource contribution
                    that a given node has provided for a one time transaction
                    process. This can be measured in computing cycles through
                    Proof-of-Work (PoW) or other resources such as ‘stake’ that
                    incurs a cost to provide.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#threedc-acc-10"
              >
                pBFT + Reputation Channels (L1)
                <span class="accordion-icon"></span>
              </h5>
              <div
                id="threedc-acc-10"
                class="collapse"
                data-bs-parent="#threedc-acc"
              >
                <div class="accordion-content">
                  <p>
                    As transactions are received by the network, nodes start
                    verifying them immediately. The transaction speed of L1
                    channels will vary based on the risk that a merchant wishes
                    to assume, ranging from sub-second speeds to five seconds.
                    For higher value transactions, it will be recommended that
                    they receive additional weight from validation on the next
                    consensus layer: L2, reducing transaction speed to 15
                    seconds plus.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#threedc-acc-11"
              >
                pBFT + PoS Trust Network (L2)
                <span class="accordion-icon"></span>
              </h5>
              <div
                id="threedc-acc-11"
                class="collapse"
                data-bs-parent="#threedc-acc"
              >
                <div class="accordion-content">
                  <p>
                    As an extension to the existing Proof-of-Stake system, L2
                    will form the second layer of consensus above L1. The L2
                    layer ensures safety and liveness, cross-shard
                    communication, and resolves conflicts from the L1 layer. It
                    represents the horizontal chaining of L1 channels, and is a
                    major step towards a truly decentralized and scalable
                    ledger.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#threedc-acc-12"
              >
                Checking and Balancing
                <span class="accordion-icon"></span>
              </h5>
              <div
                id="threedc-acc-12"
                class="collapse"
                data-bs-parent="#threedc-acc"
              >
                <div class="accordion-content">
                  <p>
                    In order to have the highest degree of security, decisions
                    cannot be concentrated in one form, as this creates the
                    ability for ‘coercion’. If there is only one form of cost
                    that provides security, the system can be easily dominated
                    due to limited ‘checking and balancing’. Bitcoin is a prime
                    example of a victim that is suffering from resource
                    domination or ‘centralization’.
                  </p>
                  <p>
                    Four organizations control more than 51% of Bitcoin’s
                    hashrate, meaning, that the entire security of Bitcoin is
                    reliant on them and the decisions that they make. This
                    situation is an example of centralization resulting from
                    resource domination, which has lead to proposed solutions
                    such as UASF (User Activated Soft Fork) and multiple Bitcoin
                    forks such as Bitcoin Cash, Bitcoin SV, Bitcoin Gold, etc.
                  </p>
                  <img src="images/3dcgraph.png" alt="graph" />
                  <p>
                    Though promising, UASF was unable to reach a level where it
                    could be effective, as the required percentage of miner’s
                    consent was too high.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#threedc-acc-13"
              >
                Decentralization
                <span class="accordion-icon"></span>
              </h5>
              <div
                id="threedc-acc-13"
                class="collapse"
                data-bs-parent="#threedc-acc"
              >
                <div class="accordion-content">
                  <p>
                    Many protocols have moved away from PoW due its large energy
                    requirements. The extremely competitive nature also leads to
                    an increasing amount of resources in order to search for a
                    block, as the traditional model of PoW only rewards the
                    winning miner of each block, incentivizing miners to pool
                    resources.
                  </p>
                  <p>
                    Delegated Proof of Stake (DPoS), a consensus mechanism used
                    by EOS, TRON, LISK and others primarily to achieve scaling
                    enhancements, relies on a limited number of select block
                    producers, yields a low degree of decentralization. There
                    are several solutions that have been proposed for the
                    scaling of a blockchain: Bitcoin’s Segregated Witness
                    combined with Lightning Network, and Ethereum’s Plasma for
                    example. Though promising, both essentially depend on
                    off-chain solutions to provide scaling (a more centralized
                    approach). They create payment channels or ‘side chains’,
                    that rely on a small group of verifiers to recommit updated
                    balances. Younger protocols have proposed multilayered
                    systems, though we are unaware of any designs that place as
                    much emphasis on decentralization as the 3DC.
                  </p>
                  <p>
                    The 3DC aims to achieve decentralization through many
                    methods that include; L1 reputation channels, decentralized
                    pools on the L2 and L3 layers, reputation incentive
                    structures, and peer discovery.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#threedc-acc-14"
              >
                L1 Reputation Channels
                <span class="accordion-icon"></span>
              </h5>
              <div
                id="threedc-acc-14"
                class="collapse"
                data-bs-parent="#threedc-acc"
              >
                <div class="accordion-content">
                  <p>
                    L1 Reputation channels are designed to require a low amount
                    of resources in comparison to the L2 and L3 layers. This is
                    to enable the use of smaller mobile devices which in turn
                    will provide higher levels of decentralization. This is
                    possible as the L2 consensus layer above adds weight to
                    ensure the security of the channels below. Reputation is the
                    final ingredient that the 3DC employs to maintain security
                    while achieving high levels of decentralization. It is
                    aggregated through all three layers of the 3DC, to quantify
                    the ‘validity’ of the 3D block.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#threedc-acc-15"
              >
                Decentralized Staking Pool (L2)
                <span class="accordion-icon"></span>
              </h5>
              <div
                id="threedc-acc-15"
                class="collapse"
                data-bs-parent="#threedc-acc"
              >
                <div class="accordion-content">
                  <p>
                    The L2 layer is the core of the 3DC that manages data
                    aggregation and contract processing. This layer also
                    receives shares from the miners on the L3 layer above, in
                    order to accumulate their work and reward the miners
                    collectively. The more shares that are included from the L3
                    layer, the greater the accumulated weight and trust will be
                    for the given 3D block. Therefore, the 3DC incentivises L2
                    validators to include as many shares as possible to ensure
                    that their 3D block is accepted as the most valid in the 3D
                    chain.
                  </p>
                  <p>
                    The L2 layer is driven by a ‘Proof-of-Stake’ weighting, that
                    identifies all nodes in the consensus process as
                    contributors, and therefore produces a higher degree of
                    decentralization compared to existing Proof-of-Stake (PoS)
                    protocols. The 3DC will require a lower minimum balance in
                    order to stake with than the current PoS protocol.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#threedc-acc-16"
              >
                Decentralized Mining Pool (L3)
                <span class="accordion-icon"></span>
              </h5>
              <div
                id="threedc-acc-16"
                class="collapse"
                data-bs-parent="#threedc-acc"
              >
                <div class="accordion-content">
                  <p>
                    This layer will use PoW based mining shares computed from
                    the work performed by the nodes of L2. Consensus will be
                    determined by the largest value of shares + Trust, in order
                    to reach a final agreement on the most valid 3D block.
                  </p>
                  <p>
                    Instead of miners having the authority to determine the next
                    block by finding the winning hash, mining will become a
                    group-wide activity forming the L3 layer of the 3DC. Miners
                    who submit hashes to the network perform work that locks the
                    L2 cross links. This provides the infrastructure for a more
                    decentralized consensus process, while also inheriting the
                    positive properties that mining offers.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#threedc-acc-17"
              >
                Peer Discovery
                <span class="accordion-icon"></span>
              </h5>
              <div
                id="threedc-acc-17"
                class="collapse"
                data-bs-parent="#threedc-acc"
              >
                <div class="accordion-content">
                  <p>
                    Blockchains typically rely on the ability of nodes to
                    connect directly (peer-to-peer) to maintain a decentralized
                    and evenly distributed topology. Nodes must be discoverable
                    by their peers, by being able to accept connection requests.
                    This is seldom achieved, and has resulted in Bitcoin only
                    having a meager 10% of nodes that are discoverable.
                  </p>
                  <p>
                    Alternatively, Nexus uses the LISP Overlay in order to
                    traverse ‘NATs’ (Network Address Translators) to maintain a
                    higher degree of node availability. LISP uses static
                    Endpoint Identifiers (EIDs) that can even be reached when
                    roaming between different networks (WiFi, cell towers,
                    etc.). This gives nodes significantly increased mobility,
                    allowing them to be located anywhere on the internet, behind
                    NATs in residential environments, in cloud providers, and
                    behind mobile carriers while still being discoverable.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item accordion-item-s3">
              <h5
                class="accordion-title accordion-title-sm collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#threedc-acc-18"
              >
                Reputation Incentive Structures
                <span class="accordion-icon"></span>
              </h5>
              <div
                id="threedc-acc-18"
                class="collapse"
                data-bs-parent="#threedc-acc"
              >
                <div class="accordion-content">
                  <p>
                    Reputation is an important requirement for the functioning
                    of decentralized systems, in order to create a healthy
                    global network. We will implement reputation on all three
                    layers of the 3DC, as a secondary component to weight,
                    improving the overall pBFT. Of equal importance, reputation
                    can improve the decentralization through incentive
                    structures facilitated through variable rewards to nodes
                    that have earned a higher reputation. Longer term
                    contributors to a system can be awarded a higher reputation,
                    and therefore a higher return for their contribution, giving
                    rise to a long standing view of Nexus that:
                  </p>
                  <p><i>Not everyone has money, but everyone has time</i></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
