<body class="nk-body body-wider bg-light">
  <div class="nk-wrap">
    <nexus-page-header
      [title]="'DApps'"
      headerText="Experience is simply the name we give our mistakes."
      [isSmall]="true"
    >
    </nexus-page-header>
  </div>

  <section class="section pdt-1 ov-v">
    <div class="ui-mask-left ui-mask-s8"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-text-wrap">
        <div
          class="row align-items-center gutter-vr-30px justify-content-between"
        >
          <div class="col-lg-5 col-mb-8 m-auto order-first">
            <div
              class="nk-block-img"
              data-animate="fadeInUp"
              data-delay=".1"
              title="integrated-api-image"
              style="visibility: visible; animation-delay: 0.1s"
            >
              <img class="modules" src="images/dapps.png" alt="app" />
            </div>
          </div>

          <div class="col-lg-7">
            <div class="nk-block-text">
              <h3
                class="title title-s2 title-s2-alt animated"
                data-animate="fadeInUp"
                data-delay=".1"
                style="visibility: visible; animation-delay: 0.1s"
              >
                DApps
              </h3>

              <h6
                class="title title-xs title-s1 tc-primary animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".2"
                style="visibility: visible; animation-delay: 0.2s"
              >
                <a target="_blank" href="./ResourceHub/invoice-guide"
                  >Nexus Invoices:</a
                >
              </h6>

              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".2"
                style="visibility: visible; animation-delay: 0.2s"
              >
                A wallet module to simplify payroll. Transaction history is
                transparent on Nexus, which makes our platform very suited to
                any non-profit, company or organization wishing to increase
                transparency, accountability, and auditability.
              </p>

              <h6
                class="title title-xs title-s1 tc-primary animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".3"
                style="visibility: visible; animation-delay: 0.3s"
              >
                <a target="_blank" href="https://bef.earth"
                  >Biodiversity &amp; Ecosystem Futures (BEF):</a
                >
              </h6>

              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".3"
                style="visibility: visible; animation-delay: 0.3s"
              >
                An asset registry that enables the tracking & trading of
                environmental assets (e.g. carbon credits) to incentivise
                environmental regeneration.
              </p>

              <h6
                class="title title-xs title-s1 tc-primary animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".4"
                style="visibility: visible; animation-delay: 0.4s"
              >
                <a target="_blank" href="https://freeandequal.org/"
                  >Free &amp; Equal:</a
                >
              </h6>

              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".4"
                style="visibility: visible; animation-delay: 0.4s"
              >
                A not-for-profit DApp that records votes. Their mission is to
                open the electoral process, promote transparency and empower
                voters with information about every candidate.
              </p>

              <h6
                class="title title-xs title-s1 tc-primary animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".4"
                style="visibility: visible; animation-delay: 0.4s"
              >
                <a
                  target="_blank"
                  href="https://horder.bubbleapps.io/version-test/"
                  >Digitality.rocks:</a
                >
              </h6>

              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".4"
                style="visibility: visible; animation-delay: 0.4s"
              >
                A prototype NFT marketplace with the goal to create a bridge
                between reality and digitality.
              </p>
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>

  <!-- Footer -->
  <nexus-footer></nexus-footer>
</body>
