<body class="nk-body body-wider bg-light">
  <div class="nk-wrap">
    <nexus-page-header
      [title]="'Economics'"
      headerText="Economics is too important to leave to the economists."
      [isSmall]="true"
    >
    </nexus-page-header>
  </div>

  <section class="section pdt-1 ov-v">
    <div class="ui-mask-left ui-mask-s8"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-text-wrap">
        <div
          class="row align-items-center gutter-vr-30px justify-content-between"
        >
          <div class="col-lg-7 col-mb-8 m-auto order-first">
            <div
              class="nk-block-img"
              data-animate="fadeInUp"
              data-delay=".1"
              title="integrated-api-image"
              style="visibility: visible; animation-delay: 0.1s"
            >
              <img class="modules" src="images/economics.png" alt="app" />
            </div>
          </div>

          <div class="col-lg-5">
            <div class="nk-block-text">
              <h3
                class="title title-s2 title-s2-alt animated"
                data-animate="fadeInUp"
                data-delay=".2"
                style="visibility: visible; animation-delay: 0.2s"
              >
                Economics
              </h3>
              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".3"
                style="visibility: visible; animation-delay: 0.3s"
              >
                Cryptocurrency is attracting the interest of people as they
                learn about the consequences of increasing government deficits
                and a rapidly expanding money supply. When excessive money or
                liquidity is released into an economy, it eventually drives up
                the price of goods and services, resulting in a reduction in
                purchasing power or ‘inflation’, and usually ends in the
                collapse of the currency, known as ‘hyperinflation’.
              </p>

              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".4"
                style="visibility: visible; animation-delay: 0.4s"
              >
                The economic model of Nexus is designed to produce an annual
                growth rate in the total supply of a NXS that is similar to that
                of gold. Gold is an asset that has often been favored in times
                of economic crisis as a hedge against inflation due to its
                inherent scarcity. Unlike fiat currencies, the issuance of NXS
                is not dependent on a central bank or government, its supply is
                regulated by mathematics, and it has zero debt or interest
                associated with its distribution.
              </p>
            </div>

            <div class="economics-button">
              <a
                class="btn btn-primary btn-lg"
                href="https://docs.nexus.io/economics-1"
                ><em class="link-icon fs-5"></em><span>Learn more</span></a
              >
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>

  <!-- Footer -->
  <nexus-footer></nexus-footer>
</body>
