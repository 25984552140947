<body class="nk-body body-wider bg-light">
  <div class="nk-wrap">
    <nexus-page-header
      [title]="'No-Code'"
      headerText="Even a bad code is better than no code at all."
      [isSmall]="true"
    >
    </nexus-page-header>
  </div>

  <section class="section pdt-1 ov-v">
    <div class="ui-mask-left ui-mask-s8"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-text-wrap">
        <div
          class="row align-items-center gutter-vr-30px justify-content-between"
        >
          <div class="col-lg-5">
            <div class="nk-block-text">
              <h3
                class="title title-s2 title-s2-alt animated"
                data-animate="fadeInUp"
                data-delay=".2"
                title="Decentralized"
                style="visibility: visible; animation-delay: 0.2s"
              >
                No-Code (Bubble)
              </h3>
              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".3"
                title="Decentralized"
                style="visibility: visible; animation-delay: 0.3s"
              >
                Non-coders can build low cost DApps with the
                <a
                  target="_blank"
                  href="https://bubble.io/plugin/nexus-blockchain-1595919363741x219916062308433920"
                  >Nexus Bubble plugin</a
                >
                that connects to the
                <a
                  target="_blank"
                  href="https://github.com/Nexusoft/LLL-TAO/tree/master/docs/API"
                  >Nexus API</a
                >. Bubble is similar to WIX, though is geared towards building
                API driven web-apps rather than static pages. If you are
                familiar with JSON-APIs, you can work directly with the Bubble
                API connector.
              </p>
              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".4"
                title="Decentralized"
                style="visibility: visible; animation-delay: 0.4s"
              >
                We provide access to a private-mode testnet, if you would like
                to test or develop the functionality of Nexus without paying
                fees. Note, our testnet is not permanently maintained, and you
                are advised to move your DApp to a private network or the public
                chain when possible.
              </p>
            </div>
          </div>
          <div class="col-lg-7 col-mb-8 m-auto order-first order-lg-last">
            <div
              class="nk-block-img"
              data-animate="fadeInUp"
              data-delay=".1"
              title="Decentralized"
              style="visibility: visible; animation-delay: 0.1s"
            >
              <img class="bubble" src="images/bubble.png" alt="app" />
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>

  <!-- Footer -->
  <nexus-footer></nexus-footer>
</body>
