<!-- Header @s -->
<div class="header-main">
  <div class="header-container container">
    <div class="header-wrap">
      <!-- Logo @s -->
      <div
        class="header-logo logo animated"
        data-animate="fadeInDown"
        data-delay=".6"
      >
        <a href="./" class="logo-link">
          <img
            class="logo-dark"
            src="./../../images/nexus-logo-blue.png"
            alt="logo"
          />
          <img
            class="logo-light"
            src="./../../images/nexus-logo-white.png"
            alt="logo"
          />
        </a>
      </div>
      <!-- Menu Toogle @s -->
      <div class="header-nav-toggle">
        <a href="#" class="navbar-toggle" data-menu-toggle="header-menu">
          <div class="toggle-line">
            <span></span>
          </div>
        </a>
      </div>
      <!-- Menu @s -->
      <div
        class="header-navbar animated"
        data-animate="fadeInDown"
        data-delay=".75"
      >
        <nav class="header-menu" id="header-menu">
          <ul class="menu">
            <li class="menu-item has-sub">
              <a class="menu-link nav-link menu-toggle" href="#">Technology</a>
              <ul class="menu-sub menu-drop">
                <li class="menu-item">
                  <a class="menu-link nav-link" routerLink="roadmap">Roadmap</a>
                </li>
                <li class="menu-item">
                  <a
                    class="menu-link nav-link"
                    routerLink="decentralizedinternet"
                    >Decentralized Internet</a
                  >
                </li>
                <li class="menu-item">
                  <a class="menu-link nav-link" routerLink="blockchain"
                    >Blockchain</a
                  >
                </li>
                <li class="menu-item">
                  <a class="menu-link nav-link" routerLink="operatingsystem"
                    >Operating System</a
                  >
                </li>
              </ul>
            </li>
            <li class="menu-item has-sub">
              <a class="menu-link nav-link menu-toggle" href="#">Develop</a>
              <div class="menu-sub menu-drop menu-mega menu-mega-2clmn">
                <div class="menu-mega-innr">
                  <ul class="menu-mega-list">
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="tokenization"
                        >Tokenization</a
                      >
                    </li>
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="bubble"
                        >No-Code (Bubble)</a
                      >
                    </li>
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="integratedapi"
                        >Integrated API</a
                      >
                    </li>
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="contracts"
                        >Contracts</a
                      >
                    </li>
                  </ul>
                  <ul class="menu-mega-list">
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="hybrid-networks"
                        >Hybrid Networks</a
                      >
                    </li>
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="modules"
                        >Modules</a
                      >
                    </li>
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="usecases"
                        >Use Cases</a
                      >
                    </li>
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="inquire"
                        >Inquire</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="menu-item has-sub">
              <a class="menu-link nav-link menu-toggle" href="#">Use</a>
              <ul class="menu-sub menu-drop menu-mega menu-mega-2clmn">
                <div class="menu-mega-innr">
                  <ul class="menu-mega-list">
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="buynexus"
                        >Buy Nexus</a
                      >
                    </li>
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="wallet"
                        >Wallet</a
                      >
                    </li>
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="transact"
                        >Transact</a
                      >
                    </li>
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="stake-mine"
                        >Stake & Mine</a
                      >
                    </li>
                  </ul>
                  <ul class="menu-mega-list">
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="assets"
                        >Assets (NFTs)</a
                      >
                    </li>
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="namespaces"
                        >Namespaces</a
                      >
                    </li>
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="dapps">DApps</a>
                    </li>
                  </ul>
                </div>
              </ul>
            </li>

            <li class="menu-item has-sub">
              <a class="menu-link nav-link menu-toggle" href="#">Resources</a>
              <div class="menu-sub menu-drop menu-mega menu-mega-2clmn">
                <div class="menu-mega-innr">
                  <ul class="menu-mega-list">
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="resourcehub"
                        >Resource hub</a
                      >
                    </li>
                    <li class="menu-item">
                      <a
                        class="menu-link nav-link"
                        href="https://explorer.nexus.io/"
                        >Nexus Explorer</a
                      >
                    </li>

                    <li class="menu-item">
                      <a
                        class="menu-link nav-link"
                        href="https://github.com/Nexusoft"
                        >Github Repository</a
                      >
                    </li>
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="whitepapers"
                        >Whitepapers</a
                      >
                    </li>
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="blog">Blog</a>
                    </li>
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="videos"
                        >Videos</a
                      >
                    </li>
                  </ul>
                  <ul class="menu-mega-list">
                    <li class="menu-item">
                      <a
                        class="menu-link nav-link"
                        href="https://docs.nexus.io/user-guide"
                        >User Guides</a
                      >
                    </li>

                    <li class="menu-item">
                      <a
                        class="menu-link nav-link"
                        href="https://docs.nexus.io/developer-guide"
                        >Developer Guides</a
                      >
                    </li>

                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="economics"
                        >Economics</a
                      >
                    </li>

                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="security"
                        >Security Audit</a
                      >
                    </li>
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="branding"
                        >Branding & Styling Guide</a
                      >
                    </li>
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="faq">FAQ</a>
                    </li>
                    <li class="menu-item">
                      <a class="menu-link nav-link" routerLink="glossary"
                        >Glossary</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="menu-item">
              <a class="menu-link nav-link" routerLink="team">Team</a>
            </li>
          </ul>
        </nav>
      </div>
      <!-- .header-navbar @e -->
    </div>
  </div>
</div>
<!-- .header-main @e -->
