<header
  class="nk-header page-header is-transparent is-sticky is-shrink"
  id="header"
>
  <!-- Banner @s -->
  <div class="header-banner bg-theme-grad">
    <div class="nk-banner">
      <div class="banner banner-page">
        <div class="banner-wrap">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-6 col-lg-9">
                <div class="banner-caption cpn tc-light text-center">
                  <div class="cpn-head">
                    <div
                      class="section-head section-head-sm text-center wide-auto-sm"
                    >
                      <h2
                        class="title title-s4 animated fadeInUp"
                        data-animate="fadeInUp"
                        data-delay=".1"
                        title="Roadmap"
                        style="visibility: visible; animation-delay: 0.1s"
                      >
                        Roadmap
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container banner-caption cpn tc-light">
            <div class="row justify-content-center">
              <div class="col-sm-12 col-md-6 roadmap-header">
                <p>
                  Our roadmap shows the progress made in each of the core stages
                  of the TAO Framework which include the deployment of the
                  <a href="./blockchain#3dc">Three-Dimensional Chain (3DC)</a>.
                  Each letter represents one of the three consensus upgrades
                  that correspond to the addition of a lock level on the 3DC.
                </p>
              </div>

              <div class="col-md-3">
                <img
                  class="logo-light taocoin d-none d-md-block"
                  src="images/taocoin.png"
                  alt="logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- .nk-banner -->
    <div class="nk-ovm shape-a-sm"></div>
    <div id="particles-bg" class="particles-container particles-bg"></div>
  </div>
  <!-- .header-banner @e -->
</header>

<div class="section section-roadmap" id="roadmap">
  <div class="ui-mask-left ui-mask-s11"></div>
  <div class="container">
    <!-- Block @s -->
    <div class="section-head text-center wide-auto">
      <h6 class="title title-xs title-light">The roadmap to success</h6>
      <h2 class="title title-lg title-dark">What are our major goals?</h2>
    </div>
    <div class="nk-block nk-block-roadmap">
      <div class="row justify-content-center">
        <div class="col-xl-10">
          <div class="roadmap-wrap mb-0">
            <div class="roadmap-line"></div>
            <div class="roadmap">
              <div class="roadmap-year">2018</div>
            </div>
            <div class="roadmap roadmap-logo roadmap-right">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <div class="roadmap-title text-center">Tritium</div>
                </div>

                <img class="logo-dark" src="images/Tritium.png" alt="tritium" />
              </div>
            </div>
            <div class="roadmap roadmap-left roadmap-finished">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Released 09/16/2018</span>
                  <span class="roadmap-title">Trust System</span>
                </div>
                <p>
                  This milestone marks the introduction of the new trust key
                  decay rate, with trust decreasing 3 times faster than it
                  accrues. Replacing the hard 24-hour time limit in September
                  2018, the new decay rate gives staking wallets greater ability
                  to retain trust and more accurately reflects the effort
                  invested in securing the blockchain.
                </p>
              </div>
            </div>
            <div class="roadmap">
              <div class="roadmap-year">2019</div>
            </div>
            <div class="roadmap roadmap-right roadmap-finished">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Released 01/31/2019</span>
                  <span class="roadmap-title">LISP</span>
                </div>
                <p>
                  The Locator/ID Separation Protocol is an important network
                  protocol that allows one to control their IP addressing,
                  without relying on ISPs for allocation.
                </p>
              </div>
            </div>
            <div class="roadmap roadmap-left roadmap-finished">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Released 06/26/2019</span>
                  <span class="roadmap-title">Legacy Mode</span>
                </div>
                <p>
                  Full update to Nexus Core software supporting Legacy
                  blockchain operation. This mode features fast sync times,
                  instant loading, and a small memory and disk footprint.
                </p>
              </div>
            </div>
            <div class="roadmap roadmap-right roadmap-finished">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Released 06/26/2019</span>
                  <span class="roadmap-title">Interface / Wallet</span>
                </div>
                <p>
                  The Nexus interface supports full wallet function. Modular
                  functionality allows custom themes and addon modules such as
                  Binance trading, multi-coin storage, a block explorer, Tritium
                  features, and anything module developers might dream up.
                </p>
              </div>
            </div>
            <div class="roadmap roadmap-left roadmap-finished">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Released 11/05/2019</span>
                  <span class="roadmap-title">API / SDK</span>
                </div>
                <p>
                  Our API gives developers access to a wide set of features
                  available through a simple HTTP interface. This provides easy
                  access to smart contracts that can be embedded directly into
                  web applications and existing login systems.
                </p>
              </div>
            </div>
            <div class="roadmap roadmap-right roadmap-finished">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Released Q4-2019</span>
                  <span class="roadmap-title">Other achievements</span>
                </div>
                <ul class="list list-dot roadmap-step-list">
                  <li>Assets (NFTS)</li>
                  <li>Conditional Contracts</li>
                  <li>Signature Chains</li>
                  <li>Lower Level Library</li>
                </ul>
              </div>
            </div>

            <div class="roadmap roadmap-logo roadmap-left">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <div class="roadmap-title text-center">Tritium ++</div>
                </div>

                <img class="logo-dark" src="images/Tritium.png" alt="tritium" />
              </div>
            </div>

            <div class="roadmap">
              <div class="roadmap-year">2023</div>
            </div>

            <div class="roadmap roadmap-right">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Expected with release 5.1</span>
                  <span class="roadmap-title">Mobile Wallet</span>
                </div>
                <p>
                  Our official mobile wallet will be developed using most of the
                  software that runs the desktop wallet, with changes to the
                  interface to be more mobile friendly, and daemon packages that
                  incrementally will be improved over time to enable more
                  contract functionality and staking. The Mobile Wallet will
                  still have the modular design of the Nexus Wallet, so that
                  developers can extend it with their DApps by simply deploying
                  new modules to the Wallet.
                </p>
              </div>
            </div>
            <div class="roadmap roadmap-left">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Expected with release 5.1</span>
                  <span class="roadmap-title">Pooled Staking</span>
                </div>
                <p>
                  As more people continue to join the staking process, the
                  network becomes more secure through the increase in the Trust
                  channel difficulty. Though this provides benefits to the
                  network as a whole, it constantly drives up the required
                  amount of coins to maintain a Trust key. Pooled Staking solves
                  this by giving smaller balances the ability to collaborate
                  with peers, ultimately resulting in increased staking access
                  to the regular user, and higher network security. This
                  approach does not require a central service, since it will
                  work as a sub network inside Nexus, that itself comes to
                  consensus on the Trust keys to be included. This is a pure,
                  decentralized, staking protocol that will benefit the entire
                  network as a whole.
                </p>
              </div>
            </div>
            <div class="roadmap roadmap-right">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Expected with release 5.1</span>
                  <span class="roadmap-title">The P2P Market API</span>
                </div>
                <p>
                  The Decentralized Exchange will sit mainly in the Logical /
                  Interface layers, with the exchange itself being facilitated
                  by a conditional contract on the Operations layer. The DEX
                  will be able to be used to trade tokens and assets listed on
                  Nexus, promoting the development of decentralized marketplaces
                  where any type of asset can be traded in a truly free, peer to
                  peer manner without a need for a custodian service. There is
                  no authority that designates the process of listing, and there
                  are no other parties involved in the exchange other than the
                  buyer and the seller.
                </p>
              </div>
            </div>
            <div class="roadmap roadmap-left">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Expected with release 5.1</span>
                  <span class="roadmap-title">Augmented Contracts</span>
                </div>
                <p>
                  Augmented Contracts are the second type of contracts that will
                  be available in the Tritium Protocol. These types of contracts
                  extend the Conditional VM (Virtual Machine that processes
                  Conditional Statements) to provide additional benefits
                  including, but not limited to, methods, functions, operation
                  overloading, and encapsulation. Augmented contracts add a
                  layer of complexity and processing, so will carry a higher fee
                  to execute. This will require more on-chain processing, but
                  overall makes our Contract Engine much more powerful.
                </p>
              </div>
            </div>
            <div class="roadmap roadmap-right">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Expected with release 5.1</span>
                  <span class="roadmap-title">Hybrid Mode</span>
                </div>
                <p>
                  An important feature, hybrid mode is capable of forming an
                  individual network out of the box, making it a highly useful
                  tool for businesses that wish to utilize our blockchain
                  technology, while retaining high levels of privacy.
                </p>
              </div>
            </div>
            <div class="roadmap roadmap-left">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Expected with release 5.1</span>
                  <span class="roadmap-title">Object Modeling</span>
                </div>
                <p>
                  Object Modeling is a new feature that will extend the API
                  layer allowing developers to model their Object Registers in
                  languages such as XML (EXtensible Markup Language). This will
                  also contain modeling languages that will make it possible for
                  advanced developers to work with lower level object
                  operations. These could include using specific data types and
                  specifiers, developing more complex object behavior through
                  Augmented Contracts, or creating extra conditions that
                  regulate the object’s overall behavior.
                </p>
              </div>
            </div>
            <div class="roadmap roadmap-right">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Expected with release 5.1</span>
                  <span class="roadmap-title">Protected Assets</span>
                </div>
                <p>
                  Protected Assets are a type of asset with the addition that a
                  ‘Master’ owner retains the right to revoke access to the
                  asset. This essentially allows the asset creator to have
                  permanent access to an asset, which is necessary for
                  securities, collateralized loans (DeFi), non-transferable
                  assets (Tickets, Educational/Professional Awards and
                  Certificates, Certificates of Insurance, Licenses etc.). They
                  are also useful for protecting the asset from being
                  transferred to unqualified parties, or in other words, parties
                  that do not fulfill the requirements set out by asset creator.
                </p>
              </div>
            </div>
            <div class="roadmap roadmap-left">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Expected with release 5.1</span>
                  <span class="roadmap-title">LLD Global File System</span>
                </div>
                <p>
                  The LLD global file system will support secondary files that
                  record assets to be stored and retrieved through network
                  operations, providing a seamless interface for managing assets
                  and data.
                </p>
              </div>
            </div>
            <div class="roadmap roadmap-right">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Expected with release 5.1</span>
                  <span class="roadmap-title"
                    >Contract Domain Specific Languages</span
                  >
                </div>
                <p>
                  Contract-specific programming languages will be provided that
                  will include internal safety mechanisms such as catching
                  overflows to allow more complex contract development, while
                  remaining less prone to error.
                </p>
              </div>
            </div>

            <div class="roadmap">
              <div class="roadmap-year">TBA</div>
            </div>

            <div class="roadmap roadmap-logo roadmap-left amine">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <div class="roadmap-title text-center">Amine</div>
                </div>

                <img class="logo-dark" src="images/Amine.png" alt="amine" />
              </div>
            </div>

            <div class="roadmap roadmap-right">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Expected with release 6.0</span>
                  <span class="roadmap-title"
                    >pBFT + Reputation Channels (L1)</span
                  >
                </div>
                <p>
                  This new architectural component will process transactions in
                  parallel, using reputation as an additional weight to provide
                  higher security. The transaction speed of L1 channels will
                  vary based on the risk that a merchant wishes to assume,
                  ranging from sub-second speeds to 5 seconds. For higher value
                  transactions, it will be recommended that they receive
                  additional weight from validation on the next consensus layer:
                  L2, reducing transaction speed to 15 seconds plus.
                </p>
              </div>
            </div>
            <div class="roadmap roadmap-left">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Expected with release 6.0</span>
                  <span class="roadmap-title"
                    >pBFT + PoS Trust Network (L2)</span
                  >
                </div>
                <p>
                  As an extension to the existing Proof of Stake system, L2 will
                  form the second layer of consensus above the L1. The L2 layer
                  ensures safety and liveness, cross-shard communication, and
                  resolves conflicts from the L1 layer. It represents the
                  horizontal chaining of the L1 channels, and is a major step
                  towards a truly decentralized and scalable ledger.
                </p>
              </div>
            </div>

            <div class="roadmap roadmap-right">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Expected with release 6.0</span>
                  <span class="roadmap-title">Network Data Sharding</span>
                </div>
                <p>
                  Data sharding is an essential facet of our ledger design in
                  order to achieve long-term scalability. Amine will provide the
                  opportunity for nodes to run in “shard” mode, which will lower
                  their disk and memory usage, even when the network is under
                  high load.
                </p>
              </div>
            </div>
            <div class="roadmap roadmap-left">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Expected with release 6.0</span>
                  <span class="roadmap-title"
                    >LISP Multicast Links for (L1) and (L2)</span
                  >
                </div>
                <p>
                  Using LISP, the L1 and L2 layers will have their own Multicast
                  links, therefore packets and transactions will route in
                  constant time no matter how many nodes are part of the system.
                </p>
              </div>
            </div>

            <div class="roadmap roadmap-right">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Expected with release 6.0</span>
                  <span class="roadmap-title">Application Store</span>
                </div>
                <p>
                  Here applications and modules will be able to be shared and
                  sold in a decentralized marketplace. Modules will provide
                  developers the building blocks to create applications.
                </p>
              </div>
            </div>

            <div class="roadmap">
              <div class="roadmap-year">TBA</div>
            </div>

            <div class="roadmap roadmap-logo roadmap-left obsidian">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <div class="roadmap-title text-center">Obsidian</div>
                </div>

                <img
                  class="logo-dark"
                  src="images/Obsidian.png"
                  alt="obsidian"
                />
              </div>
            </div>

            <div class="roadmap roadmap-right">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Expected with release 7.0</span>
                  <span class="roadmap-title"
                    >Extended Network Data Sharding</span
                  >
                </div>
                <p>
                  Data sharding in Obsidian will extend to critical network
                  functions, resulting in nodes being required to store only a
                  portion of the overall chain. Note, this is data sharding, not
                  computational sharding, which means once data has been
                  processed, it can be partitioned and stored between nodes. The
                  result will be an increase of data storage as more nodes join
                  the network.
                </p>
              </div>
            </div>
            <div class="roadmap roadmap-left">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Expected with release 7.0</span>
                  <span class="roadmap-title"
                    >Decentralized Mining & Merkle Share Pool (L3)
                  </span>
                </div>
                <p>
                  This component will use Proof of Work based mining shares
                  computed from the work performed by the nodes of L2. Consensus
                  will be determined by the largest value of shares + trust, in
                  order to reach the final agreement on the most valid 3D block.
                </p>
              </div>
            </div>

            <div class="roadmap roadmap-right">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Expected with release 7.0</span>
                  <span class="roadmap-title"
                    >DAO: L1 Voting Group (Implement)</span
                  >
                </div>
                <p>
                  The L1 voting group will provide voting rights to validators
                  who are new to the network, and therefore do not have enough
                  resources to participate in the L2 consensus. Voting weight is
                  based on reputation.
                </p>
              </div>
            </div>
            <div class="roadmap roadmap-left">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Expected with release 7.0</span>
                  <span class="roadmap-title"
                    >DAO: L2 Voting Group (Extend)</span
                  >
                </div>
                <p>
                  The L2 voting group will extend Tritium’s “Ambassador DAO” and
                  provide voting rights to validators who have reached a
                  reputation threshold. Voting weight is based on reputation
                  multiplied by stake.
                </p>
              </div>
            </div>

            <div class="roadmap roadmap-right">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Expected with release 7.0</span>
                  <span class="roadmap-title">DAO: L3 Voting Group</span>
                </div>
                <p>
                  The L3 voting group will provide voting rights to miners.
                  Voting weight is based on mining power (average weight of
                  mined shares over time) multiplied by reputation.
                </p>
              </div>
            </div>
            <div class="roadmap roadmap-left">
              <div class="roadmap-step">
                <div class="roadmap-head">
                  <span class="roadmap-time">Expected with release 7.0</span>
                  <span class="roadmap-title">LISP Multicast Links for L3</span>
                </div>
                <p>
                  Shares on L3 will use LISP Multicast links allowing the
                  efficient broadcasting of mined shares, and the acceptance of
                  L2 hashes for computation by L3 nodes.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- .col -->
      </div>
      <!-- .row -->
    </div>
    <!-- Block @e -->
  </div>

  <app-scroll-top></app-scroll-top>
</div>

<nexus-footer></nexus-footer>
