<body class="nk-body body-wider bg-light">
  <div class="nk-wrap">
    <nexus-page-header
      [title]="'Hybrid Networks'"
      headerText="The currency of real networking is not greed but generosity."
      [isSmall]="true"
    >
    </nexus-page-header>
  </div>

  <section class="section pdt-1 ov-v">
    <div class="ui-mask-left ui-mask-s8"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-text-wrap">
        <div
          class="row align-items-center gutter-vr-30px justify-content-between"
        >
          <div class="col-lg-5">
            <div class="nk-block-text">
              <h3
                class="title title-s2 title-s2-alt animated"
                data-animate="fadeInUp"
                data-delay=".1"
                style="visibility: visible; animation-delay: 0.1s"
              >
                Hybrid Networks
              </h3>
              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".2"
                style="visibility: visible; animation-delay: 0.2s"
              >
                Hybrid Networks are designed for individuals, businesses and
                organizations who have a need for a dedicated network for their
                DApp or ecosystem. A hybrid network has all the features of
                public chain, while granting access for free transactions,
                contract execution, and token and asset (NFT) creation, at the
                cost of dedicated hardware and hybrid network fees.
              </p>

              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".3"
                style="visibility: visible; animation-delay: 0.3s"
              >
                With the release of Tritium++, Hybrid Mode will be made
                available as an out of the box solution. It will supply security
                services, such as permission access, while granting autonomy,
                and privacy. The owners of the network can also design their own
                fee schedule for transactions, as well as tokenomics.
              </p>
            </div>
          </div>

          <div class="col-lg-7 col-mb-8 m-auto order-first order-lg-last">
            <div
              class="nk-block-img"
              data-animate="fadeInUp"
              data-delay=".1"
              style="visibility: visible; animation-delay: 0.1s"
            >
              <img class="bubble" src="images/hybridnetwork.png" alt="app" />
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>

  <!-- Footer -->
  <nexus-footer></nexus-footer>
</body>
