<header
  class="nk-header page-header is-transparent is-sticky is-shrink"
  id="header"
>
  <div class="header-banner bg-theme-grad">
    <div class="nk-banner">
      <div class="banner banner-page banner-page-lg banner-gap-b4">
        <div class="banner-wrap">
          <div class="container">
            <div
              class="row align-items-center justify-content-center justify-content-lg-start"
            >
              <div class="col-lg-6 col-sm-10 text-center text-lg-start">
                <div class="banner-caption cpn tc-light">
                  <div class="cpn-head">
                    <h1 class="title">Desktop Wallet</h1>
                  </div>
                  <div class="cpn-text">
                    <p>
                      You are able to access your NXS anywhere, anytime, by
                      simply downloading the wallet application and logging in
                      (via username, password, and PIN), meaning you no longer
                      need to backup or manage a wallet.dat file for key
                      storage. Our wallet is also fully customizable. You can
                      choose a skin from the
                      <a
                        target="_blank"
                        href="https://github.com/KenCorma/Nexus-Wallet-Themes"
                        >skin repository</a
                      >
                      or
                      <a
                        target="_blank"
                        href="https://github.com/Nexusoft/NexusInterface/blob/master/docs/Themes.md"
                        >make your own design</a
                      >, and developers can build
                      <a href="./modules">wallet modules</a>.
                    </p>
                  </div>
                </div>
              </div>
              <!-- .col -->
            </div>
            <!-- .row -->
          </div>
        </div>
      </div>
    </div>
    <!-- .nk-banner -->
    <div class="banner-screen banner-screen-right">
      <div class="container">
        <div class="row justify-content-end">
          <div class="col-lg-7 col-xl-6">
            <div class="banner-screen-gfx ps-lg-4 ps-xl-0">
              <img
                class="buy-nxs-image"
                src="images/desktopwallet.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nk-ovm mask-a-md shape-a-sm"></div>
    <div id="particles-bg" class="particles-container particles-bg"></div>
  </div>
  <!-- .header-banner @e -->
</header>
<main class="nk-pages">
  <section class="section protocol pdt-1 ov-v">
    <div class="ui-mask-left ui-mask-s8"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-text-wrap">
        <div
          class="row align-items-center gutter-vr-30px justify-content-between"
        >
          <div class="col-lg-6 col-mb-7 m-auto order-first order-lg-last">
            <div
              class="nk-block-img"
              data-animate="fadeInUp"
              data-delay=".1"
              title="mobilewalletsImage"
              style="visibility: visible; animation-delay: 0.1s"
            >
              <img src="images/mobilewallets.png" alt="app" />
            </div>
          </div>
          <div class="col-lg-5">
            <div class="nk-block-text">
              <div class="nk-block-shade"></div>
              <h3
                class="title title-s2 title-s2-alt animated"
                data-animate="fadeInUp"
                data-delay=".2"
                title="mobilewallets"
                style="visibility: visible; animation-delay: 0.2s"
              >
                Mobile Wallet
              </h3>
              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".3"
                title="mobilewalletsDescription"
                style="visibility: visible; animation-delay: 0.3s"
              >
                The Nexus mobile wallet (beta) is totally independent of a
                centralized node. It is a lite node, though it has the security
                of a full node, while requiring a fraction of the memory. Like
                the desktop wallet, you are able to access the mobile wallet
                with your username, password and PIN. Future releases will have
                the capability to create tokens, NFTs, and tokenized NFTs.
              </p>
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>

  <section class="section section-partners bg-light-alt" id="partners">
    <div class="container">
      <!-- Section Head @s -->
      <div class="section-head text-center wide-auto-xs">
        <h2 class="title title-lg title-dark">Download Wallet</h2>
        <p>
          Wallet is available for all major Operating Systems. The latest
          version of the wallet: 3.1.0
        </p>
      </div>
      <!-- .section-head @e -->
      <!-- Block @s -->
      <div class="nk-block block-partners">
        <div class="card">
          <div class="row align-items-center">
            <div class="col-lg-3 col-sm-6 download-option">
              <em class="icon fab fa-windows"></em>
              <a
                href="https://github.com/Nexusoft/NexusInterface/releases/download/v3.1.0/nexus_wallet-setup-Win-3.1.0.exe"
                class="btn btn-outline btn-dark btn-md"
                >Windows</a
              >
            </div>
            <div class="col-lg-3 col-sm-6 download-option">
              <em class="icon fab fa-apple"></em>
              <a
                href="https://github.com/Nexusoft/NexusInterface/releases/download/v3.1.0/nexus_wallet-MacOSX-3.1.0.dmg"
                class="btn btn-outline btn-dark btn-md"
                >Mac OS</a
              >
            </div>
            <div class="col-lg-3 col-sm-6 download-option">
              <em class="icon fab fa-linux"></em>
              <a
                href="https://github.com/Nexusoft/NexusInterface/releases/download/v3.1.0/nexus_wallet-Linux-3.1.0.AppImage"
                class="btn btn-outline btn-dark btn-md"
                >Linux</a
              >
            </div>
            <div class="col-lg-3 col-sm-6 download-option">
              <ul
                class="btn-grp gutter-20px gutter-vr-20px justify-content-center mt-auto mb-auto"
              >
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=io.nexus.wallet&hl=en&gl=US&pli=1"
                    class="btn-download google-play"
                    ><img src="images/google-play.png" alt=""
                  /></a>
                </li>
                <li>
                  <a
                    href="https://testflight.apple.com/join/dJLBiKzt"
                    class="btn-download app-store"
                    ><img src="images/apps-store.png" alt=""
                  /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Block @e -->
    </div>
  </section>

  <section class="section bg-theme-dark-alt pdt-1 disclaimer">
    <div
      class="section-head section-head-s10 wide-auto-md text-center tc-light"
    >
      <!-- <h6 class="title-xs title-light">THE ROADMAP TO SUCCESS</h6> -->
      <h2 class="title title-light">Disclaimer</h2>
      <p>
        Your wallet is the safest place to store NXS. If NXS is stored on an
        exchange or a custodial wallet, then the risk of coins being lost or
        stolen increases. To ensure you maintain ownership, we recommend that
        NXS is held in the Nexus wallet. We recommend you set up a 'recovery
        phrase’ (either 10, 20 or 100 words), and a password of at least 8
        alpha-numeric characters. The master seed phrase provides extra
        assurance, enabling you to recover your Nexus account in the event that
        it is compromised, or if you forget your password. Remember: your
        wallet, your crypto. If your coins are stored on an exchange, they are
        not yours. Nexus is an open source technology, licensed under an MIT
        agreement
        <a href="https://github.com/Nexusoft/LLL-TAO/blob/master/COPYING.MD"
          >(click here to view license)</a
        >. You are the sole custodian of your coins, meaning there is no one
        that can recover your coins or encryption passwords if they are lost. In
        the case of technical difficulties, the Nexus Community will be happy to
        help with Wallet related queries.
      </p>
    </div>
  </section>

  <!-- Footer -->
  <footer class="nk-footer bg-theme-grad">
    <section class="section no-pdy section-contact bg-transparent">
      <div class="container">
        <!-- Block @s -->
        <div class="nk-block block-contact">
          <div class="row justify-content-center g-0">
            <!-- .col -->
          </div>
          <!-- .row -->
        </div>
        <!-- .block @e -->
      </div>
      <div class="nk-ovm ovm-top ovm-h-50 bg-light"></div>
      <!-- .nk-ovm -->
    </section>
    <!-- // -->
    <section class="section section-footer tc-light bg-transparent">
      <div class="container">
        <!-- Block @s -->
        <div class="nk-block block-footer">
          <div class="row">
            <div class="col-lg-2 col-sm-4 mb-4 mb-sm-0">
              <div class="wgs wgs-menu">
                <h6 class="wgs-title">Company</h6>
                <div class="wgs-body">
                  <ul class="wgs-links">
                    <li><a href="#">About Us</a></li>
                    <li><a href="#">Meet Our Team</a></li>
                    <li><a href="#">Advisors</a></li>
                    <li><a href="#">Contact Us</a></li>
                    <li><a href="#">Insights</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- .col -->
            <div class="col-lg-2 col-sm-4 mb-4 mb-sm-0">
              <div class="wgs wgs-menu">
                <h6 class="wgs-title">Legal</h6>
                <div class="wgs-body">
                  <ul class="wgs-links">
                    <li><a href="#">Terms &amp; Conditions</a></li>
                    <li><a href="#">Privacy Policy</a></li>
                    <li><a href="#">Terms of Sales</a></li>
                    <li><a href="#">Whitepaper</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- .col -->
            <div class="col-lg-2 col-sm-4 mb-4 mb-sm-0">
              <div class="wgs wgs-menu">
                <h6 class="wgs-title">Quick Links</h6>
                <div class="wgs-body">
                  <ul class="wgs-links">
                    <li><a href="#">Ecosystems</a></li>
                    <li><a href="#">Tokens</a></li>
                    <li><a href="#">Roadmaps</a></li>
                    <li><a href="#">Faqs</a></li>
                    <li><a href="#">Login</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- .col -->
            <div class="col-lg-6 mb-4 mb-lg-0 order-lg-first">
              <div class="wgs wgs-text">
                <div class="wgs-body">
                  <a href="./" class="logo-link">
                    <img
                      class="logo-dark"
                      src="./../../images/nexus-logo-blue.png"
                      alt="logo"
                    />
                    <img
                      class="logo-light"
                      src="./../../images/nexus-logo-white.png"
                      alt="logo"
                    />
                  </a>
                  <p>
                    Copyright &copy; 2022 Nexus. <br />
                    All rights reserved.
                  </p>
                </div>
              </div>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->
        </div>
        <!-- .block @e -->
      </div>
    </section>
    <div class="nk-ovm shape-b"></div>
  </footer>
  <!-- // -->
</main>
