<body class="nk-body body-wider bg-light">
  <div class="nk-wrap">
    <nexus-page-header
      [title]="'Namespaces'"
      headerText="Complexity that works is built up out of modules that work perfectly, layered one over the other."
      [isSmall]="true"
    >
    </nexus-page-header>
  </div>

  <section class="section pdt-1 ov-v">
    <div class="ui-mask-left ui-mask-s8"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-text-wrap">
        <div
          class="row align-items-center gutter-vr-30px justify-content-between"
        >
          <div class="col-lg-5 col-mb-8 m-auto order-first">
            <div
              class="nk-block-img"
              data-animate="fadeInUp"
              data-delay=".1"
              title="integrated-api-image"
              style="visibility: visible; animation-delay: 0.1s"
            >
              <img class="modules" src="images/namespaces.png" alt="app" />
            </div>
          </div>

          <div class="col-lg-7">
            <div class="nk-block-text">
              <h3
                class="title title-s2 title-s2-alt animated"
                data-animate="fadeInUp"
                data-delay=".1"
                style="visibility: visible; animation-delay: 0.1s"
              >
                Namespaces
              </h3>

              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".2"
                style="visibility: visible; animation-delay: 0.2s"
              >
                Nexus has a multi-tiered naming system that offers globally
                unique identification, personalization and branding
                opportunities. Three categories exist: local names, namespaces,
                and global names, all of which can be created via the
                <a href="./wallet">Nexus desktop wallet</a>.
              </p>

              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".3"
                style="visibility: visible; animation-delay: 0.3s"
              >
                A user only has to type in the username and account name to send
                funds to another user (eg. send to jules:default). Names can be
                created within a namespace for the purpose of naming accounts
                within an organization. A namespace owner can create and sell
                names just like domain registrars sell domains.
              </p>
              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".4"
                style="visibility: visible; animation-delay: 0.4s"
              >
                names represent a unique, stand-alone name that can be used for
                token tickers and other uses. Global name ownership can be
                transferred just like namespaces, creating the opportunity for
                secondary markets.
              </p>
            </div>

            <div class="namespaces-documentation">
              <a
                class="btn btn-primary btn-lg"
                href="https://docs.nexus.io/developer-guide/namespaces-2"
                ><em class="link-icon fs-5"></em><span>Learn more</span></a
              >
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>

  <!-- Footer -->
  <nexus-footer></nexus-footer>
</body>
