<body class="nk-body body-wider bg-light">
  <div class="nk-wrap">
    <nexus-page-header [title]="'Metrics'" [isSmall]="true">
    </nexus-page-header>
  </div>

  <section class="section pdt-0 ov-v">
    <div class="ui-mask-left ui-mask-s8"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-text-wrap">
        <div
          class="row align-items-center gutter-vr-30px justify-content-between"
        >
          <div class="col-lg-12">
            <div class="nk-block-text">
              <h2
                class="title title-s2 title-s2-alt animated"
                data-animate="fadeInUp"
                data-delay="0.1"
                style="visibility: visible; animation-delay: 0.1s"
              >
                Website visitors
              </h2>
              <div #globe></div>
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>

  <!-- Footer -->
  <nexus-footer></nexus-footer>
</body>
