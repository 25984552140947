<header
  class="nk-header page-header is-transparent is-sticky is-shrink"
  id="header"
>
  <!-- Banner @s -->
  <div class="header-banner bg-theme-grad">
    <div class="nk-banner">
      <div class="banner banner-page" [class.small]="isSmall">
        <div class="banner-wrap" [class.alternative-mask]="alternativeMask">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-6 col-lg-9">
                <div class="banner-caption cpn tc-light text-center">
                  <div class="cpn-head">
                    <div
                      class="section-head section-head-sm text-center wide-auto-sm"
                    >
                      <h2
                        class="title title-s4 animated"
                        data-animate="fadeInUp"
                        data-delay=".1"
                        title="{{ titleBackground || title }}"
                        style="visibility: visible; animation-delay: 0.1s"
                      >
                        {{ title }}
                      </h2>
                      <p
                        class="animated"
                        data-animate="fadeInUp"
                        data-delay=".2"
                        style="visibility: visible; animation-delay: 0.2s"
                      >
                        {{ headerText }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- .nk-banner -->
    <!-- <div class="nk-ovm mask-g-bg shape-v"></div> -->
    <div class="nk-ovm mask-d shape-x" *ngIf="alternativeMask"></div>
    <div class="nk-ovm mask-g-bg shape-z7" *ngIf="!alternativeMask"></div>
    <div id="particles-bg" class="particles-container particles-bg"></div>
  </div>

  <!-- .header-banner @e -->
</header>
