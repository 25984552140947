<body class="nk-body body-wider bg-light">
  <div class="nk-wrap">
    <nexus-page-header
      [title]="'Use cases'"
      headerText="A use case is a complete course of events in the system, seen from a user's perspective."
      [isSmall]="true"
    >
    </nexus-page-header>
  </div>

  <section class="section protocol pdt-1 ov-v">
    <div class="ui-mask-left ui-mask-s8"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-text-wrap">
        <div
          class="row align-items-center gutter-vr-30px justify-content-between"
        >
          <div class="col-lg-5 col-mb-8 m-auto order-first order-lg-last">
            <div
              class="nk-block-img"
              data-animate="fadeInUp"
              data-delay=".1"
              style="visibility: visible; animation-delay: 0.1s"
            >
              <img
                class="entertainment"
                src="images/creativenft2.png"
                alt="app"
              />
            </div>
          </div>
          <div class="col-lg-7">
            <div class="nk-block-text">
              <h3
                class="title title-s2 title-s2-alt animated"
                data-animate="fadeInUp"
                data-delay=".2"
                style="visibility: visible; animation-delay: 0.2s"
              >
                Creative & Entertainment
              </h3>
              <h6
                class="title title-xs title-s1 tc-primary animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".3"
                style="visibility: visible; animation-delay: 0.3s"
              >
                Digital Rights Management:
              </h6>
              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".4"
                style="visibility: visible; animation-delay: 0.4s"
              >
                All types of digital creations can be registered as unique
                <a href="./assets">assets (NFTs)</a> on Nexus. They can then be
                <a href="./tokenize">tokenized</a> allowing for shared
                ownership, and the automatic distribution of royalties to token
                holders. Artists across the creative scenes are learning how
                they can monetize their creations while cutting out the
                middleman. Digital assets include: Music, Audio Books, Art,
                Photography, E-books, Film.
              </p>
              <h6
                class="title title-xs title-s1 tc-primary animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".5"
                style="visibility: visible; animation-delay: 0.5s"
              >
                Virtual & Physical Collectibles:
              </h6>
              <p>
                Assets (NFTs) can be used to build entire metaverses that can
                integrate into games, art galleries, and events etc. Virtual
                assets include: In-Game Items, Skins, Weapons, Digital Art etc.
                Alternatively, assets can be linked to physical objects such as
                Clothes, Jewelry, Antiques or Physical Art to prove authenticity
                and scarcibility.
              </p>
              <h6
                class="title title-xs title-s1 tc-primary animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".6"
                style="visibility: visible; animation-delay: 0.6s"
              >
                Tickets:
              </h6>
              <p>
                Assets (NFTs) can be used to issue tickets in order to reduce
                fraud, and with the release of
                <a target="_blank" href="https://docs.nexus.io/assets-nfts-1"
                  >Protected Assets</a
                >, tickets can be made to be revocable to prevent resale on
                secondary markets.
              </p>
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>

  <section class="section ov-v pdb-2">
    <div class="ui-mask-right ui-mask-s11"></div>
    <div class="container">
      <div class="nk-block">
        <div class="row align-items-center gutter-vr-30px">
          <div class="col-lg-6">
            <div class="nk-block-img">
              <img class="assets" src="images/assets-gov.png" alt="gfx" />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="nk-block-text">
              <h2
                class="title title-s2 title-s2-alt animated"
                data-animate="fadeInUp"
                data-delay="0.1"
                style="visibility: visible; animation-delay: 0.1s"
              >
                Assets & Governance
              </h2>
              <h6
                class="title title-xs title-s1 tc-primary animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".2"
                style="visibility: visible; animation-delay: 0.2s"
              >
                Environmental & Energy:
              </h6>
              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay="0.3"
                style="visibility: visible; animation-delay: 0.3s"
              >
                Nexus <a href="./assets">assets (NFTs)</a> provide the
                technology to trade and track environmental and energy assets.
                With the release of the
                <a
                  target="_blank"
                  href="https://medium.com/@NexusOfficial/nexus-introduces-advanced-apis-that-empower-developers-to-build-smarter-applications-f5d5d35dadc3"
                  >P2P Market API</a
                >, developers will be able to build exchange platforms for their
                assets, eliminating the need for intermediaries, such as
                brokers. Assets include, but are not limited to Carbon and Solar
                credits.
              </p>
              <h6
                class="title title-xs title-s1 tc-primary animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".4"
                style="visibility: visible; animation-delay: 0.4s"
              >
                Securities & DAO:
              </h6>

              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay="0.5"
                style="visibility: visible; animation-delay: 0.5s"
              >
                Tokenized assets can be used for securities and DAO tokens,
                where the asset represents the registration of the organization
                and the tokens represent shared ownership. Payment of dividends
                or surplus revenue can then be automatically distributed to
                token holders.
              </p>

              <h6
                class="title title-xs title-s1 tc-primary animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".6"
                style="visibility: visible; animation-delay: 0.6s"
              >
                Voting & Governance:
              </h6>
              <p>
                Tokens or assets (NFTs) can be used to record cryptographic and
                transparent votes, and with the release of the DAO API, DApps
                can add features for the decentralized management of funds and
                resources.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section protocol pdt-1 ov-v">
    <div class="ui-mask-left ui-mask-s8"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-text-wrap">
        <div
          class="row align-items-center gutter-vr-30px justify-content-between"
        >
          <div class="col-lg-5 col-mb-8 m-auto order-first order-lg-last">
            <div class="nk-block-img">
              <img
                class="property-gov"
                src="images/property-gov.png"
                alt="app"
              />
            </div>
          </div>
          <div class="col-lg-7">
            <div
              class="nk-block-text animated"
              data-animate="fadeInUp"
              data-delay="0.1"
              style="visibility: visible; animation-delay: 0.1s"
            >
              <h2
                class="title title-s2 title-s2-alt animated"
                data-animate="fadeInUp"
                data-delay="0.2"
                style="visibility: visible; animation-delay: 0.2s"
              >
                Titles & Records
              </h2>
              <h6
                class="title title-xs title-s1 tc-primary animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".3"
                style="visibility: visible; animation-delay: 0.3s"
              >
                Public Titles:
              </h6>
              <p>
                Assets (NFTs) can be used as a record for legal titles that
                represent private property. Being transparent, anyone can easily
                verify the authenticity of a public title on Nexus, without
                having to access a central database. Contracts can also be used
                for the decentralized exchange of public titles, eliminating
                counterparty risk. Public titles include, but are not limited
                to: Automotive, Real Estate, Internet Domains and Trademarks.
              </p>
              <h6
                class="title title-xs title-s1 tc-primary animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".4"
                style="visibility: visible; animation-delay: 0.4s"
              >
                Non-transferable Titles:
              </h6>
              <p>
                With the release of
                <a target="_blank" href="https://docs.nexus.io/assets-nfts-1"
                  >Protected Assets</a
                >, NFTs will have the additional functionality of being
                non-transferable and revocable by the issuer, therefore are
                suited to certified systems such as Identity Cards, Memberships,
                Educational and Professional Certificates, Licenses, and KYC
                (know your own customer).
              </p>
              <h6
                class="title title-xs title-s1 tc-primary animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".5"
                style="visibility: visible; animation-delay: 0.5s"
              >
                CopyRights & Unforgeable Records:
              </h6>
              <p>
                Any important digital document can be hashed and authenticated
                as an asset generating a timestamped immutable fingerprint.
                Documents include, but are not limited to: Legal Agreements,
                Waivers, Insurance Records, References (landlord and
                employment), Copyrighted Material, Patents, Medical Records etc.
              </p>
              <h6
                class="title title-xs title-s1 tc-primary animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".6"
                style="visibility: visible; animation-delay: 0.6s"
              >
                Supply Chains:
              </h6>
              <p>
                Assets (NFTs) can be used to provide transparency into the
                supply chains of different products such as Agricultural
                Produce, Commodities, Manufactures, and Electrical Components
                etc. Product certificates include: Organic, Fair Trade,
                Sustainable, and Quality Assurance, etc. The Asset API can also
                be used to develop DApps to streamline production processes and
                prove chain of custody.
              </p>
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>

  <!-- Footer -->
  <nexus-footer></nexus-footer>
</body>
