<body class="nk-body body-wider bg-light">
  <div class="nk-wrap">
    <nexus-page-header
      [title]="'Branding'"
      headerText=""
      [isSmall]="true"
      [alternativeMask]="true"
    >
    </nexus-page-header>
  </div>

  <section class="section branding ov-h has-ovm">
    <div class="ui-mask-left ui-mask-s8"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-text-wrap bg-theme tc-light">
        <!-- <div class="background-shape bs-right"></div> -->
        <div
          class="row align-items-center gutter-vr-30px justify-content-between"
        >
          <div class="col-lg-12">
            <div class="nk-block-text">
              <h3
                class="title title-s2 title-s2-alt animated doc"
                data-animate="fadeInUp"
                data-delay=".1"
                style="visibility: visible; animation-delay: 0.1s"
              >
                Branding & Styling Guide
              </h3>
              <h2
                class="title fw-6 animated fadeInUp doc"
                data-animate="fadeInUp"
                data-delay=".2"
                style="visibility: visible; animation-delay: 0.2s"
              >
                RGB for Screens
              </h2>

              <div
                class="row align-items-center gutter-vr-30px justify-content-between box"
              >
                <div class="col-md-6 m-auto">
                  <img
                    src="images/branding/NexusLogoBlue1000.png"
                    loading="lazy"
                    alt="NexusLogoBlue1000"
                  />
                  <div class="vertical-middle">
                    <a
                      href="images/branding/NexusLogoBlue1000.png"
                      download="NexusLogoBlue1000x225"
                    >
                      <img
                        style="width: 50px"
                        src="images/branding/BlueDownloadIcon.png"
                        loading="lazy"
                        alt="BlueDownloadIcon"
                      />
                    </a>
                    <div style="margin-left: 1em">
                      <h5 id="bsg">Nexus Blue</h5>
                      <h5 id="bsg">1000 x 225</h5>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 m-auto">
                  <img
                    src="images/branding/NexusLogoWhite1000.png"
                    loading="lazy"
                    alt="NexusLogoWhite1000"
                  />
                  <div class="vertical-middle">
                    <a
                      href="images/branding/NexusLogoWhite1000.png"
                      download="NexusLogoWhite1000x225"
                    >
                      <img
                        style="width: 50px"
                        src="images/branding/BlueDownloadIcon.png"
                        loading="lazy"
                        alt="BlueDownloadIcon"
                      />
                    </a>
                    <div style="margin-left: 1em">
                      <h5 id="bsg">White</h5>
                      <h5 id="bsg">1000 x 225</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="row align-items-center gutter-vr-30px justify-content-between box"
              >
                <div class="col-md-6 m-auto">
                  <img
                    src="images/branding/NexusLogoBlue1250.png"
                    loading="lazy"
                    alt="NexusLogoBlue1250"
                  />
                  <div class="vertical-middle">
                    <a
                      href="images/branding/NexusLogoBlue1250.png"
                      download="NexusLogoBlue1250x225"
                    >
                      <img
                        style="width: 50px"
                        src="images/branding/BlueDownloadIcon.png"
                        loading="lazy"
                        alt="BlueDownloadIcon"
                      />
                    </a>
                    <div style="margin-left: 1em">
                      <h5 id="bsg">Nexus Blue</h5>
                      <h5 id="bsg">1250 x 225</h5>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 m-auto">
                  <img
                    src="images/branding/NexusLogoWhite1250.png"
                    loading="lazy"
                    alt="NexusLogoWhite1250"
                  />
                  <div class="vertical-middle">
                    <a
                      href="images/branding/NexusLogoWhite1250.png"
                      download="NexusLogoWhite1250x225"
                    >
                      <img
                        style="width: 50px"
                        src="images/branding/BlueDownloadIcon.png"
                        loading="lazy"
                        alt="BlueDownloadIcon"
                      />
                    </a>
                    <div style="margin-left: 1em">
                      <h5 id="bsg">White</h5>
                      <h5 id="bsg">1250 x 225</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="row align-items-center gutter-vr-30px justify-content-between box"
              >
                <div class="col-md-6 m-auto">
                  <img
                    src="images/branding/GlobeBlue1000.png"
                    loading="lazy"
                    alt="GlobeBlue1000"
                  />
                  <div class="vertical-middle">
                    <a
                      href="images/branding/GlobeBlue1000.png"
                      download="GlobeBlue1000x1000"
                    >
                      <img
                        style="width: 50px"
                        src="images/branding/BlueDownloadIcon.png"
                        loading="lazy"
                        alt="BlueDownloadIcon"
                      />
                    </a>
                    <div style="margin-left: 1em">
                      <h5 id="bsg">Nexus Blue</h5>
                      <h5 id="bsg">1000 x 1000</h5>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 m-auto">
                  <img
                    src="images/branding/GlobeWhite1000.png"
                    loading="lazy"
                    alt="GlobeWhite1000"
                  />
                  <div class="vertical-middle">
                    <a
                      href="images/branding/GlobeWhite1000.png"
                      download="GlobeWhite1000x1000"
                    >
                      <img
                        style="width: 50px"
                        src="images/branding/BlueDownloadIcon.png"
                        loading="lazy"
                        alt="BlueDownloadIcon"
                      />
                    </a>
                    <div style="margin-left: 1em">
                      <h5 id="bsg">White</h5>
                      <h5 id="bsg">1000 x 1000</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="row align-items-center gutter-vr-30px justify-content-between box"
              >
                <div class="col-md-6 m-auto">
                  <img
                    src="images/branding/GlobeBlue1200.png"
                    loading="lazy"
                    alt="GlobeBlue1200"
                  />
                  <div class="vertical-middle">
                    <a
                      href="images/branding/GlobeBlue1200.png"
                      download="GlobeBlue1200x1000"
                    >
                      <img
                        style="width: 50px"
                        src="images/branding/BlueDownloadIcon.png"
                        loading="lazy"
                        alt="BlueDownloadIcon"
                      />
                    </a>
                    <div style="margin-left: 1em">
                      <h5 id="bsg">Nexus Blue</h5>
                      <h5 id="bsg">1200 x 1000</h5>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 m-auto">
                  <img
                    src="images/branding/GlobeWhite1200.png"
                    loading="lazy"
                    alt="GlobeWhite1200"
                  />
                  <div class="vertical-middle">
                    <a
                      href="images/branding/GlobeWhite1200.png"
                      download="GlobeWhite1200x1000"
                    >
                      <img
                        style="width: 50px"
                        src="images/branding/BlueDownloadIcon.png"
                        loading="lazy"
                        alt="BlueDownloadIcon"
                      />
                    </a>
                    <div style="margin-left: 1em">
                      <h5 id="bsg">White</h5>
                      <h5 id="bsg">1200 x 1000</h5>
                    </div>
                  </div>
                </div>
              </div>

              <h2
                class="title fw-6 animated fadeInUp doc"
                data-animate="fadeInUp"
                data-delay=".1"
                style="visibility: visible; animation-delay: 0.1s"
              >
                CMYK for Printing
              </h2>

              <div
                class="row align-items-center gutter-vr-30px justify-content-between box"
              >
                <div class="col-md-6 m-auto">
                  <img
                    src="images/branding/BlueCMYK4000.jpg"
                    loading="lazy"
                    alt="BlueCMYK4000"
                  />
                  <div class="vertical-middle">
                    <a
                      href="images/branding/BlueCMYK4000.jpg"
                      download="BlueCMYK4000"
                    >
                      <img
                        style="width: 50px"
                        src="images/branding/BlueDownloadIcon.png"
                        loading="lazy"
                        alt="BlueDownloadIcon"
                      />
                    </a>
                    <div style="margin-left: 1em">
                      <h5 id="bsg">Nexus Blue</h5>
                      <h5 id="bsg">4000 x 900</h5>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 m-auto">
                  <img
                    src="images/branding/BlueCMYK5000.jpg"
                    loading="lazy"
                    alt="BlueCMYK5000"
                  />
                  <div class="vertical-middle">
                    <a
                      href="images/branding/BlueCMYK5000.jpg"
                      download="BlueCMYK5000"
                    >
                      <img
                        style="width: 50px"
                        src="images/branding/BlueDownloadIcon.png"
                        loading="lazy"
                        alt="BlueDownloadIcon"
                      />
                    </a>
                    <div style="margin-left: 1em">
                      <h5 id="bsg">Nexus Blue</h5>
                      <h5 id="bsg">5000 x 900</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="row align-items-center gutter-vr-30px justify-content-between box"
              >
                <div class="col-md-6 m-auto">
                  <img
                    src="images/branding/GlobeCMYK4000.jpg"
                    loading="lazy"
                    alt="GlobeCMYK4000"
                  />
                  <div class="vertical-middle">
                    <a
                      href="images/branding/GlobeCMYK4000.jpg"
                      download="GlobeCMYK4000"
                    >
                      <img
                        style="width: 50px"
                        src="images/branding/BlueDownloadIcon.png"
                        loading="lazy"
                        alt="BlueDownloadIcon"
                      />
                    </a>
                    <div style="margin-left: 1em">
                      <h5 id="bsg">Nexus Blue</h5>
                      <h5 id="bsg">4000 x 4000</h5>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-6 m-auto"
                  style="vertical-align: text-bottom"
                >
                  <img
                    src="images/branding/GlobeCMYK5200.jpg"
                    loading="lazy"
                    alt="GlobeCMYK5200"
                  />
                  <div class="vertical-middle">
                    <a
                      href="images/branding/GlobeCMYK5200.jpg"
                      download="GlobeCMYK5200"
                    >
                      <img
                        style="width: 50px"
                        src="images/branding/BlueDownloadIcon.png"
                        loading="lazy"
                        alt="BlueDownloadIcon"
                      />
                    </a>
                    <div style="margin-left: 1em">
                      <h5 id="bsg">Nexus Blue</h5>
                      <h5 id="bsg">5200 x 4000</h5>
                    </div>
                  </div>
                </div>
              </div>

              <h2
                class="title fw-6 animated fadeInUp doc"
                data-animate="fadeInUp"
                data-delay=".2"
                style="visibility: visible; animation-delay: 0.2s"
              >
                Primary & Supporting Colors
              </h2>

              <div
                class="row align-items-center gutter-vr-30px justify-content-between box"
              >
                <table>
                  <tbody>
                    <tr>
                      <td>Nexus Blue</td>
                      <td>
                        <img
                          style="width: 60px"
                          src="images/branding/NexusBlue.png"
                          loading="lazy"
                          alt="NexusBlue"
                        />
                      </td>
                      <td>HEX: 0ca4fb RGB: 12,164,251 CMYK: 68,26,0,0</td>
                    </tr>
                    <tr>
                      <td>White</td>
                      <td>
                        <img
                          style="width: 60px"
                          src="images/branding/WhiteSwatch.png"
                          loading="lazy"
                          alt="WhiteSwatch"
                        />
                      </td>
                      <td>HEX: ffffff RGB: 255,255,255 CMYK: 0,0,0,0</td>
                    </tr>
                    <tr>
                      <td>Sky Blue</td>
                      <td>
                        <img
                          style="width: 60px"
                          src="images/branding/SkyBlue.png"
                          loading="lazy"
                          alt="SkyBlue"
                        />
                      </td>
                      <td>HEX: 6dcefe RGB: 109,206,254 CMYK: 48,2,0,0</td>
                    </tr>
                    <tr>
                      <td>Ocean Blue</td>
                      <td>
                        <img
                          style="width: 60px"
                          src="images/branding/OceanBlue.png"
                          loading="lazy"
                          alt="OceanBlue"
                        />
                      </td>
                      <td>HEX: 025e93 RGB: 2,94,147 CMYK: 95,64,19,3</td>
                    </tr>
                    <tr>
                      <td>Midnight Blue</td>
                      <td>
                        <img
                          style="width: 60px"
                          src="images/branding/MidnightBlue.png"
                          loading="lazy"
                          alt="MidnightBlue"
                        />
                      </td>
                      <td>HEX: 012235 RGB: 1,34,53 CMYK: 94,75,52,61</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>

  <!-- Footer -->
  <nexus-footer></nexus-footer>
</body>
