<body class="nk-body body-wider bg-light">
  <div class="nk-wrap">
    <nexus-page-header [title]="'Terms Of Use'" [isSmall]="true">
    </nexus-page-header>
  </div>
  <section class="section privacy pb-0 ov-h">
    <div class="container">
      <div class="nk-block">
        <div class="section-head text-center">
          <h3
            class="title title-s2 title-s2-alt animated"
            data-animate="fadeInUp"
            data-delay=".1"
            style="visibility: visible; animation-delay: 0.1s"
          >
            Terms Of Use
          </h3>
        </div>
        <div class="row gutter-vr-15px justify-content-center">
          <div class="col-lg-10">
            <div class="nk-block-text">
              <p>
                Acceptance of the Terms of Use These terms of use are entered
                into by and between You and the Nexus Development US, LLC
                (“Company”, “we” or “us”). The following terms and conditions,
                together with any additional document that are expressly
                incorporate by reference (collectively, these “Terms of Use”),
                govern your access to and use of Nexus.io, including any
                content, functionality and services offered on or through
                Nexus.io (together, the “Website”).
              </p>
              <p>
                Please read the Terms of Use carefully before you start to use
                the Website. By using you accept and agree to be bound and abide
                by these Terms of Use and Privacy Policy. If you do not to agree
                to these Terms of Use or the Privacy Policy, you must not access
                or use the Website.
              </p>
              <h3 class="title title-md tc-primary">Age Restrictions:</h3>
              <p>
                This Website is offered and available to users who are 18 years
                of age or older for personal use only and not for commercial
                use. The Website is not intended for children under 18 years of
                age. By using this Website, you represent and warrant that you
                (i) are 18 years of age or older, (ii) are not barred to use the
                Website under any applicable law, and (iii) are using the
                Website only for your own personal use. If you do not meet these
                requirements, you must not access or use the Website.
              </p>
              <p>
                If you have a computer that is accessible by anyone under the
                age of 18, it is your responsibility to ensure that such people
                do not have access to this Website. Any violation of this
                requirement shall result in a denial of access to this Website
                in the future.
              </p>
              <h3 class="title title-md tc-primary">
                Changes to Terms & Policy:
              </h3>
              <p>
                Terms and policies may be revised, updated, or created for the
                betterment of the community from time to time at our sole
                discretion. All changes are effective immediately when posted on
                the website unless a different future date is noted in the
                specific change posted.
              </p>
              <p>
                Your continued use of the Website following the posting of
                revised Terms of Use means that you accept and agree to the
                changes. You are expected to check this page frequently so you
                are aware of any changes, as they are binding on you.
              </p>
              <h3 class="title title-md tc-primary">Website Changes:</h3>
              <p>
                Nexus may update the content on this Website from time to time.
                We do our best to keep the information current and up to date
                but do not guarantee all part to be up to date.
              </p>
              <h3 class="title title-md tc-primary">
                Information About You and Your Visits to the Website
              </h3>
              <p>
                All information we collect on this Website is subject to our
                Privacy Policy. By using the Website, you consent to all actions
                taken by us with respect to your information in compliance with
                the Privacy Policy.
              </p>
              <h3 class="title title-md tc-primary">
                Links To Other Web Sites:
              </h3>
              <p>
                Our Service may contain links to third-party web sites or
                services that are not owned or controlled by Nexus. Nexus has no
                control over, and assumes no responsibility for, the content,
                privacy policies, or practices of any third party web sites or
                services. You further acknowledge and agree that Nexus shall not
                be responsible or liable, directly or indirectly, for any damage
                or loss caused or alleged to be caused by or in connection with
                use of or reliance on any such content, goods or services
                available on or through any such web sites or services. We
                strongly advise you to read the terms and conditions and privacy
                policies of any third-party web sites or services that you
                visit.
              </p>

              <h3 class="title title-md tc-primary">
                Disclaimer of Warranties:
              </h3>
              <p>
                You understand that we cannot and do not guarantee or warrant
                that files available for downloading from the internet or the
                Website will be free of viruses or other destructive code. You
                are responsible for implementing sufficient procedures and
                checkpoints to satisfy your particular requirements for
                anti-virus protection and accuracy of data input and output, and
                for maintaining a means external to our site for any
                reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY
                LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
                VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY
                INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER
                PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY
                SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR
                DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE
                LINKED TO IT.
              </p>

              <p>
                YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS
                OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE,
                ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
                WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS,
                WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
                NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY
                MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
                COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR
                AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING,
                NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY
                REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT OR ANY
                SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE,
                RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE
                CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE
                ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE
                WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE
                WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
              </p>
              <p>
                THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT
                LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT
                AND FITNESS FOR PARTICULAR PURPOSE.
              </p>
              <p>
                SOME JURISDICTIONS DO NOT ALLOW EXCLUSION OF WARRIANTIES OR
                LIMITATIONS ON THE DURATION OF IMPLIED WARRANTIES, SO THE ABOVE
                DISCLAIMER MAY NOT APPLY TO YOU IN THEIR ENTIRETY, BUT WILL
                APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
              </p>
              <h3 class="title title-md tc-primary">
                Limitation on Liability:
              </h3>
              <p>
                IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS,
                SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE
                LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING
                OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE
                WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE
                OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH
                THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT,
                INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE
                DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
                SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS,
                LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF
                GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
                NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF
                FORESEEABLE. THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH
                CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW WHICH MAY
                INCLUDE FRAUD.
              </p>
              <h3 class="title title-md tc-primary">Indemnification:</h3>

              <p>
                You agree to defend, indemnify and hold harmless the Company,
                its affiliates, licensors and service providers, and its and
                their respective officers, directors, employees, contractors,
                agents, licensors, suppliers, successors and assigns from and
                against any claims, liabilities, damages, judgments, awards,
                losses, costs, expenses or fees (including reasonable attorneys’
                fees) arising out of or relating to your violation of these
                Terms of Use or your use of the Website, including, but not
                limited to, any use of the Website’s content, services and
                products other than as expressly authorized in these Terms of
                Use or your use of any information obtained from the Website.
              </p>
              <h3 class="title title-md tc-primary">
                Governing Law and Jurisdiction:
              </h3>

              <p>
                All matters relating to the Website and these Terms of Use and
                any dispute or claim arising therefrom or related thereto (in
                each case, including non-contractual disputes or claims), shall
                be governed by and construed in accordance with the laws of the
                State of Arizona in the United States without giving effect to
                any choice or conflict of law provision or rule (whether of
                United States or any other jurisdiction).
              </p>

              <p>
                Any legal suit, action or proceeding arising out of, or related
                to, these Terms of Use or the Website shall be instituted
                exclusively in the United States in Scottsdale Arizona although
                we retain the right to bring any suit, action or proceeding
                against you for breach of these Terms of Use in your country of
                residence or any other relevant country. You waive any and all
                objections to the exercise of jurisdiction over you by such
                courts and to venue in such courts.
              </p>

              <h3 class="title title-md tc-primary">
                Waiver and Severability:
              </h3>

              <p>
                No failure to enforce, or failure to make a claim by the Company
                regarding the violation or the breach of any term or condition
                set forth in these Terms of Use shall be deemed to be a waiver
                or a continuing waiver of such term or condition or a waiver of
                any other term or condition, and any failure of the Company to
                assert a right or provision under these Terms of Use shall not
                constitute a waiver of such right or provision.
              </p>
              <p>
                If any provision of these Terms of Use is held by a court or
                other tribunal of competent jurisdiction to be invalid, illegal
                or unenforceable for any reason, such provision shall be
                eliminated or limited to the minimum extent such that the
                remaining provisions of the Terms of Use will continue in full
                force and effect.
              </p>
              <h3 class="title title-md tc-primary">Feedback:</h3>

              <p>
                This Website is operated by the Nexus Development US, LLC. All
                other feedback, comments, requests for technical support and
                other communications relating to the Website should be directed
                to: info@Nexus.io.
              </p>

              <p><i>Last modified: March 20, 2023</i></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Footer -->
  <nexus-footer></nexus-footer>
</body>
