<body class="nk-body body-wider bg-light">
  <div class="nk-wrap">
    <nexus-page-header
      [title]="'Videos'"
      [headerText]="'Perfection isn’t the end goal. Connection is.'"
      [isSmall]="true"
    >
    </nexus-page-header>

    <main class="nk-pages">
      <section class="section bg-light blog-header">
        <div class="ui-mask-left ui-mask-s11"></div>
        <div id="videos" class="container">
          <div class="nk-block nk-block-blog">
            <div class="row">
              <div class="col-lg-8">
                <div class="row">
                  <div *ngFor="let video of pagedItems" class="col-sm-6">
                    <div class="blog">
                      <div class="blog-photo">
                        <a href="{{ video.url }}" class="video-popup">
                          <img
                            src="images/video/{{ video.image }}"
                            alt="video-thumb"
                        /></a>
                      </div>
                      <div class="blog-text">
                        <ul class="blog-meta">
                          <li *ngIf="!video.tags.length">
                            <span>-</span>
                          </li>
                          <li *ngFor="let tag of video.tags">
                            <a href="#">{{ tag.name }}</a>
                          </li>
                        </ul>
                        <h4 class="title title-sm nk-block-video">
                          <a href="{{ video.url }}" class="video-popup">{{
                            video.title
                          }}</a>
                        </h4>
                        <!-- <p>
                          {{ article.description }}
                        </p> -->
                      </div>
                    </div>
                    <!-- .blog -->
                  </div>
                  <!-- .col -->
                </div>
                <!-- .row -->
                <nav class="w-100 d-flex" *ngIf="pager.totalPages > 1">
                  <!-- pager -->
                  <ul
                    *ngIf="pager.pages && pager.pages.length"
                    class="pagination"
                  >
                    <li [ngClass]="{ disabled: pager.currentPage === 1 }">
                      <a href="#" (click)="setPage(1, $event)">First</a>
                    </li>
                    <li [ngClass]="{ disabled: pager.currentPage === 1 }">
                      <a
                        href="#"
                        (click)="setPage(pager.currentPage - 1, $event)"
                        >Previous</a
                      >
                    </li>
                    <li *ngFor="let page of pager.pages">
                      <a
                        [ngClass]="{ active: pager.currentPage === page }"
                        href="#"
                        (click)="setPage(page, $event)"
                        >{{ page }}</a
                      >
                    </li>
                    <li
                      [ngClass]="{
                        disabled: pager.currentPage === pager.totalPages
                      }"
                    >
                      <a
                        href="#"
                        (click)="setPage(pager.currentPage + 1, $event)"
                        >Next</a
                      >
                    </li>
                    <li
                      [ngClass]="{
                        disabled: pager.currentPage === pager.totalPages
                      }"
                    >
                      <a href="#" (click)="setPage(pager.totalPages, $event)"
                        >Last</a
                      >
                    </li>
                  </ul>
                </nav>
              </div>
              <!-- .col -->
              <div class="col-lg-4 order-lg-first">
                <div class="sidebar me-lg-4 mt-5 mt-lg-0">
                  <div class="wgs wgs-search">
                    <form action="#" class="field-inline">
                      <input
                        type="text"
                        placeholder="Search Keyword"
                        class="input-bordered"
                        [formControl]="searchTerm"
                      />
                      <button
                        class="btn btn-primary btn-link search"
                        (click)="search()"
                      >
                        <em class="ti ti-search"></em>
                      </button>
                      <button
                        *ngIf="searchTerm.value"
                        class="btn btn-primary btn-with-icon btn-light reset"
                        (click)="reset()"
                      >
                        <em class="ti ti-back-left"></em>
                      </button>
                    </form>
                  </div>
                  <div class="wgs wgs-category">
                    <h6 class="wgs-title">Category</h6>
                    <div class="wgs-body">
                      <ul class="wgs-links wgs-links-category">
                        <li>
                          <a
                            href="#"
                            [class.active-category]="
                              activeCategory === 'Nexus Developers'
                            "
                            (click)="filterCategory('Nexus Developers', $event)"
                            >Nexus Developers</a
                          >
                        </li>
                        <li>
                          <a
                            href="#"
                            [class.active-category]="
                              activeCategory === 'Architect Of Nexus'
                            "
                            (click)="
                              filterCategory('Architect Of Nexus', $event)
                            "
                            >Architect Of Nexus</a
                          >
                        </li>
                        <li>
                          <a
                            href="#"
                            [class.active-category]="activeCategory === 'All'"
                            (click)="filterCategory('All', $event)"
                            >All</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="wgs wgs-tags">
                    <h6 class="wgs-title">Tags</h6>
                    <div class="wgs-body">
                      <ul class="wgs-links wgs-links-tags">
                        <li><a href="#">nexus</a></li>
                        <li><a href="#">bitcoin</a></li>
                        <li><a href="#">released</a></li>
                        <li><a href="#">tokens</a></li>
                        <li><a href="#">roadmap</a></li>
                        <li><a href="#">crypto</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- .row -->
          </div>
          <!-- .nk-block -->
        </div>
        <!-- .container -->
      </section>
      <!-- .section -->
    </main>
  </div>

  <nexus-footer></nexus-footer>
</body>
