<body class="nk-body body-wider bg-light">
  <div class="nk-wrap">
    <nexus-page-header
      [title]="'FREQUENTLY ASKED QUESTIONS'"
      [titleBackground]="'FAQS'"
      headerText="Let your conscience be your guide."
      [isSmall]="true"
    >
    </nexus-page-header>
  </div>

  <section class="section bg-white" id="faqs">
    <div class="container">
      <div class="section-head text-center wide-auto-sm">
        <h2 class="title title-s4" title="FAQS">Nexus - FAQ</h2>
        <p>
          Our frequently asked questions. If you have any other questions,
          please get in touch using the contact form.
        </p>
      </div>
      <!-- .section-head @e -->
      <!-- Block @s -->
      <div class="nk-block overflow-hidden">
        <div class="row justify-content-center">
          <div class="col-xl-10 col-lg-12">
            <ul class="nav tab-nav tab-nav-line mgb-r">
              <li>
                <a class="active" data-bs-toggle="tab" href="#general-questions"
                  >General</a
                >
              </li>
              <li>
                <a data-bs-toggle="tab" href="#staking-19">Staking</a>
              </li>
              <li>
                <a data-bs-toggle="tab" href="#technology-19">Technology</a>
              </li>
              <li><a data-bs-toggle="tab" href="#wallets-19">Wallets</a></li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane fade show active" id="general-questions">
                <div class="accordion" id="faq-67">
                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-67-1"
                    >
                      I am a developer, where can I ask a question?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-67-1"
                      class="collapse"
                      data-bs-parent="#faq-67"
                    >
                      <div class="accordion-content">
                        <p>
                          You can ask questions through our
                          <a href="https://t.me/NexusDevelopers"
                            >Nexus Developers</a
                          >
                          Telegram group, or on our website's
                          <a href="./inquiry">Inquiry page</a>.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-67-2"
                    >
                      I represent a business or organization, where can I ask a
                      question?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-67-2"
                      class="collapse"
                      data-bs-parent="#faq-67"
                    >
                      <div class="accordion-content">
                        <p>
                          You can ask a question through our website's
                          <a target="_blank" href="./inquiry">Inquiry page</a>,
                          and select the relevant topic in the drop down menu.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-67-3"
                    >
                      Where can I request to see more information?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-67-3"
                      class="collapse"
                      data-bs-parent="#faq-67"
                    >
                      <div class="accordion-content">
                        <p>
                          If there are topics, FAQs, or glossary terms that you
                          would like to see added to our Resource Hub, please
                          visit the <a href="./inquiry">Inquiry</a> page and
                          submit a request for the team under the "Feedback"
                          topic. Our content is continuously growing, so please
                          check back often for new and exciting information.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-67-4"
                    >
                      Was Nexus an ICO?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-67-4"
                      class="collapse"
                      data-bs-parent="#faq-67"
                    >
                      <div class="accordion-content">
                        <p>
                          No, Nexus is one of the few blockchains that was
                          created from scratch, and mined into existence (like
                          Bitcoin). You can learn more about NXS on our our
                          website's
                          <a target="_blank" href="./economics">Economics</a>
                          page.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-67-5"
                    >
                      How is Nexus funded?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-67-5"
                      class="collapse"
                      data-bs-parent="#faq-67"
                    >
                      <div class="accordion-content">
                        <p>
                          The Nexus Embassies are funded by a portion of each
                          block that is mined. The NXS automatically transfers
                          into embassy keys.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-67-6"
                    >
                      What are the principles of Nexus?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-67-6"
                      class="collapse"
                      data-bs-parent="#faq-67"
                    >
                      <div class="accordion-content">
                        <div class="panel" style="max-height: 100%">
                          <p>
                            The Nexus community is the foundation for the
                            strength, diversity and resilience of Nexus.
                            Together, we provide ideas and resources that are
                            crucial to the health of the network. Whether you
                            trade, mine or stake NXS, develop applications, or
                            exchange ideas in one of our community channels, you
                            are a part of the community. We are a diverse
                            network of individuals who advocate the following
                            principles:
                          </p>
                          <ul class="bulletList">
                            <li>
                              <h4 class="title title-xs title-s1 tc-primary">
                                Responsibility &amp; Independence:
                              </h4>
                              <p>
                                Decentralized Systems have the ability to
                                provide choices to become more independent from
                                existing systems, which are often centralized
                                and reliant on outdated processes.
                              </p>
                            </li>
                            <li>
                              <h4 class="title title-xs title-s1 tc-primary">
                                Decentralization &amp; Meritocracy:
                              </h4>
                              <p>
                                Decentralization of power and resources is
                                fundamental to building the foundations of a
                                meritocracy, where people are rewarded in
                                proportion to their merit.
                              </p>
                            </li>
                            <li>
                              <h4 class="title title-xs title-s1 tc-primary">
                                Information &amp; Exchange:
                              </h4>
                              <p>
                                The technology of Nexus is developed for people
                                to have free access to information and exchange,
                                creating the potential for greater novelty.
                              </p>
                            </li>
                            <li>
                              <h4 class="title title-xs title-s1 tc-primary">
                                Honesty &amp; Transparency:
                              </h4>
                              <p>
                                Honesty nurtures trust between people, allowing
                                us to be more effective as a group, whilst
                                transparency cultivates integrity.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="staking-19">
                <div class="accordion" id="faq-68">
                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-68-1"
                    >
                      How do I stake Nexus?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-68-1"
                      class="collapse"
                      data-bs-parent="#faq-68"
                    >
                      <div class="accordion-content">
                        <p>
                          Staking is an energy efficient form of mining that
                          provides security to the Nexus blockchain. NXS can
                          only be staked inside the official
                          <a target="_blank" href="./wallet"
                            >Nexus Desktop Wallet</a
                          >
                          with staking enabled, and with continuous Internet
                          connection (24 hours a day, 7 days a week). <br />
                          In return, you are rewarded NXS at a stake rate of
                          0.5% to 3.0% per year on your staked holdings. To
                          determine your % stake rate, Nexus has made a
                          time-weighted reputation mechanism called 'trust' that
                          records the consistent time that a node has
                          contributed to processing transactions.
                          <br />Currently, it takes one year of consistent
                          staking to build up enough Trust to earn a
                          <b>3.00% rate</b>
                          of return. <br />To learn more about staking, please
                          visit the
                          <a
                            href="https://docs.nexus.io/user-guide/staking"
                            target="_blank"
                            >Staking</a
                          >
                          page on our Wiki.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-68-2"
                    >
                      Is there a minimum amount of NXS required to stake?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-68-2"
                      class="collapse"
                      data-bs-parent="#faq-68"
                    >
                      <div class="accordion-content">
                        <p>
                          It is possible to stake with any amount of NXS and
                          earn stake rewards at a rate of 0.5%. However, to grow
                          your stake rate and reach the maximum of 3.0%, your
                          node must find a Proof of Stake block at least every
                          72 hours. <br />As of April 2023 it takes around
                          30,000 NXS to do this.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-68-3"
                    >
                      What is a Genesis transaction?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-68-3"
                      class="collapse"
                      data-bs-parent="#faq-68"
                    >
                      <div class="accordion-content">
                        <p>
                          Genesis is the process by which a wallet creates a
                          trust key to use for staking. This is the first step
                          for staking a new wallet. When it creates a new trust
                          key, the Genesis transaction will transfer your wallet
                          balance to that key for staking. This has no impact on
                          your wallet balance. <br />After the trust key is
                          created, the wallet will no longer stake Genesis, and
                          will instead stake Trust transactions. <br />Balance
                          must have a <b>minimum</b> coin age of 3 days (72
                          hours) before the wallet will begin attempting to
                          create a Genesis transaction. <br />The Genesis
                          transaction takes longer to generate than subsequent
                          Trust transactions. For lower balances, it may stake
                          for multiple weeks before you receive this.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-68-4"
                    >
                      What are stake rate, trust weight, block weight, and stake
                      weight?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-68-4"
                      class="collapse"
                      data-bs-parent="#faq-68"
                    >
                      <div class="accordion-content">
                        <p>
                          These items affect the size and frequency of staking
                          rewards after you receive your initial Genesis
                          transaction. You can view the definitions of these
                          terms in the
                          <a target="_blank" href="./glossary">Glossary</a>.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-68-5"
                    >
                      What affects block production in staking?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-68-5"
                      class="collapse"
                      data-bs-parent="#faq-68"
                    >
                      <div class="accordion-content">
                        <p>
                          For new wallets staking Genesis, total balance and
                          trust weight calculated from coin age. For wallets
                          after Genesis, total balance, block weight, and trust
                          weight calculated from trust score. You can view the
                          definitions of these terms in the
                          <a href="./glossary">Glossary</a>.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-68-6"
                    >
                      What happens when my Block Weight reaches 100%?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-68-6"
                      class="collapse"
                      data-bs-parent="#faq-68"
                    >
                      <div class="accordion-content">
                        <p>
                          Trust weight and stake rate will begin to decay.
                          Without a transaction they will eventually decay back
                          to 0.5%. Generating a new Trust transaction resets
                          block weight, and trust weight/ stake rate continue
                          increasing from their current values. Trust decays at
                          a 3:1 rate to which it was gained. For a new key, it
                          takes 1 day for 3 days of trust to decay. An
                          established key holds onto trust much longer.
                        </p>

                        <p>
                          Establishing a new trust key is not immediate, by
                          design. The network does not give away trust for free.
                          You may see staking values decay back to the start
                          multiple times before it manages to generate
                          transactions frequently enough to keep going. If it
                          decays back to the start, the wallet will display
                          minimal trust weight of 1.11% with stake rate 0.5% and
                          block weight 100% until it generates a transaction.
                          This is normal. It is not stuck.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-68-7"
                    >
                      What happens if I remove some stake?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-68-7"
                      class="collapse"
                      data-bs-parent="#faq-68"
                    >
                      <div class="accordion-content">
                        <p>
                          When you remove stake, there is an associated cost. If
                          you remove a portion of your stake, it also reduces
                          your <a href="./glossary">Stake Rate</a>. This is to
                          reward those who consistently stake long term and do
                          not frequently reduce their committed Stake amount. If
                          you require frequent access to a portion of your NXS,
                          consider keeping it in another account (such as your
                          Default Account) and not committing it to stake.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-68-8"
                    >
                      I have not received a Trust transaction in a few days, is
                      that normal?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-68-8"
                      class="collapse"
                      data-bs-parent="#faq-68"
                    >
                      <div class="accordion-content">
                        <p>
                          There is no set time period in which it finds a new
                          block. It is not at all unusual to go a period of time
                          without a Trust transaction. They are more frequent if
                          you have a higher balance and can vary somewhat as
                          trust weight and block weight increase. As long as you
                          find at least one Proof of Stake block which generates
                          a Trust transaction within the 72 hour requirement,
                          your trust and stake rate will increase. <br />If it
                          takes longer than 72 hours, trust and stake rate decay
                          at a rate of 3:1. In other words, you lose 3 days of
                          increase for every 1 day it exceeds 72 hours between
                          blocks.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="technology-19">
                <div class="accordion" id="faq-69">
                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-69-1"
                    >
                      How does Nexus intend to solve the scaling challenges
                      facing blockchain?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-69-1"
                      class="collapse"
                      data-bs-parent="#faq-69"
                    >
                      <div class="accordion-content">
                        <p>
                          The fundamental issue of scaling comes down to what is
                          termed the 'Blockchain Trilemma'. An opinion that only
                          two of the three qualities, Security, Decentralization
                          and Scalability, are achievable concurrently. Nexus is
                          implementing the 3DC to solve this trilemma. Learn
                          more about the 3DC
                          <a target="_blank" href="./blockchain#3dc"> here</a>.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-69-2"
                    >
                      Does Nexus have smart contracts?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-69-2"
                      class="collapse"
                      data-bs-parent="#faq-69"
                    >
                      <div class="accordion-content">
                        <p>Yes.</p>
                        <p>
                          To learn about our smart contracts, please visit our
                          website's
                          <a target="_blank" href="./contracts">Contracts</a>
                          page.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-69-3"
                    >
                      Does Nexus have Tokens and NFTs?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-69-3"
                      class="collapse"
                      data-bs-parent="#faq-69"
                    >
                      <div class="accordion-content">
                        <p>Yes.</p>
                        <p>
                          To learn about NFTs and Tokens, please visit the
                          <a target="_blank" href="../assets">NFTs</a> and
                          <a href="./tokenize">Tokens</a> pages.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-69-4"
                    >
                      Is Nexus Quantum Resistant?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-69-4"
                      class="collapse"
                      data-bs-parent="#faq-69"
                    >
                      <div class="accordion-content">
                        <p>Yes.</p>
                        <p>
                          To learn about how Nexus is Quantum Resistant, please
                          visit our website's
                          <a
                            target="_blank"
                            href="./blockchain#quantumResistance"
                            >Quantum Resistance</a
                          >
                          page.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="wallets-19">
                <div class="accordion" id="faq-71">
                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-71-1"
                    >
                      I need help with my Nexus Wallet, who should I contact?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-71-1"
                      class="collapse"
                      data-bs-parent="#faq-71"
                    >
                      <div class="accordion-content">
                        <p>
                          If you have a question about your wallet, please join
                          the
                          <a href="https://t.me/NexusSupport">Nexus Support</a>
                          Telegram channel, and post a message asking for help.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-71-2"
                    >
                      Can I store NXS on a third party wallet?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-71-2"
                      class="collapse"
                      data-bs-parent="#faq-71"
                    >
                      <div class="accordion-content">
                        <p>
                          No. You can only store NXS on the official NXS desktop
                          or mobile wallet (in beta).
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-71-3"
                    >
                      Can I store NXS on a Nano Ledger?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-71-3"
                      class="collapse"
                      data-bs-parent="#faq-71"
                    >
                      <div class="accordion-content">
                        <p>
                          No, because Nexus’ architecture replaces UTXO, it can
                          not be stored on any hardware wallets.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item accordion-item-s3">
                    <h5
                      class="accordion-title collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-71-4"
                    >
                      Where can I download the NXS wallets?
                      <span class="accordion-icon accordion-icon-s2"></span>
                    </h5>
                    <div
                      id="faq-71-4"
                      class="collapse"
                      data-bs-parent="#faq-71"
                    >
                      <div class="accordion-content">
                        <p>
                          You can find the wallets on our website's
                          <a target="_blank" href="./wallet">wallets</a> page.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>

  <!-- Footer -->
  <nexus-footer></nexus-footer>
</body>
