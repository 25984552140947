<body class="nk-body body-wider bg-light">
  <div class="nk-wrap">
    <nexus-page-header
      [title]="'Tokenization'"
      headerText="Love shall be our token; love be yours and love be mine."
      [isSmall]="true"
    >
    </nexus-page-header>
  </div>

  <section class="section protocol pdt-1 ov-v">
    <div class="ui-mask-left ui-mask-s8"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-text-wrap">
        <div
          class="row align-items-center gutter-vr-30px justify-content-between"
        >
          <div class="col-lg-7 col-mb-8 m-auto order-first order-lg-last">
            <div
              class="nk-block-img"
              data-animate="fadeInUp"
              data-delay=".1"
              title="TokenizationImage"
              style="visibility: visible; animation-delay: 0.1s"
            >
              <img src="images/tokenize.png" alt="app" />
            </div>
          </div>
          <div class="col-lg-5">
            <div class="nk-block-text">
              <h3
                class="title title-s2 title-s2-alt animated"
                data-animate="fadeInUp"
                data-delay=".2"
                title="TokenizationTitle"
                style="visibility: visible; animation-delay: 0.2s"
              >
                Tokenization
              </h3>
              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".3"
                title="TokenizationPart1"
                style="visibility: visible; animation-delay: 0.3s"
              >
                Tokens and <a href="./assets">Assets (NFTs)</a> can easily be
                created via the Nexus wallet or
                <a href="./integratedapi">Nexus API</a>. NFTs can also be
                tokenized, allowing for shared ownership and the automatic
                distribution of royalties or dividends. The fee for token
                creation depends on the total supply. View our fee schedule
                <a target="_blank" href="./contracts#fees">here</a>.
              </p>
              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".4"
                title="TokenizationPart2"
                style="visibility: visible; animation-delay: 0.4s"
              >
                To create a token on Ethereum, or many other platforms,
                developers must write a smart contract (code written in a
                specific programming language) to define the utility of the
                token and its distribution. This requires specialized
                programming skills, and the task can often be quite complex.
              </p>
            </div>

            <div class="tokens-documentation">
              <a
                class="btn btn-primary btn-lg"
                href="https://docs.nexus.io/tokens"
                ><em class="link-icon fs-5"></em><span>Learn more</span></a
              >
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .row -->
      <!-- .block @e -->
    </div>
  </section>

  <!-- Footer -->
  <nexus-footer></nexus-footer>
</body>
