<body class="nk-body body-wider bg-light">
  <div class="nk-wrap">
    <nexus-page-header
      [title]="'White papers'"
      headerText="The mind is like a sheet of white paper in this, that the impressions it receives the oftenest, and retains the longest, are black ones."
      [isSmall]="true"
    >
    </nexus-page-header>
  </div>

  <div class="section bg-light">
    <div class="container container-xxl">
      <div class="nk-block">
        <div class="row gutter-vr-50px">
          <div class="col-sm-6 col-lg-4">
            <div
              class="doc animated fadeInUp"
              data-animate="fadeInUp"
              data-delay="0.3"
              style="visibility: visible; animation-delay: 0.3s"
            >
              <div class="doc-photo doc-shape doc-shape-a">
                <img src="images/whitepaper.png" alt="" />
              </div>
              <div class="doc-text">
                <h5 class="doc-title title-sm">
                  Nexus Protocol <small>(December 21, 2022)</small>
                </h5>
                <a
                  class="doc-download"
                  href="./assets/files/nexus_protocol/Nexus_Protocol_1.0.0.pdf"
                  target="_blank"
                  ><em class="ti ti-import"></em
                ></a>
                <div class="doc-lang">ENGLISH</div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4">
            <div
              class="doc animated fadeInUp"
              data-animate="fadeInUp"
              data-delay="0.4"
              style="visibility: visible; animation-delay: 0.4s"
            >
              <div class="doc-photo doc-shape doc-shape-b">
                <img src="images/whitepaper.png" alt="" />
              </div>
              <div class="doc-text">
                <h5 class="doc-title title-sm">
                  Tritium Trust <small>(October 12, 2018)</small>
                </h5>
                <a
                  class="doc-download"
                  href="./assets/files/tritium/NexusStakingWithTritiumTrust.pdf"
                  target="_blank"
                  ><em class="ti ti-import"></em
                ></a>
                <div class="doc-lang">ENGLISH</div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4">
            <div
              class="doc animated fadeInUp"
              data-animate="fadeInUp"
              data-delay="0.5"
              style="visibility: visible; animation-delay: 0.5s"
            >
              <div class="doc-photo doc-shape doc-shape-c">
                <img src="images/whitepaper.png" alt="" />
              </div>
              <div class="doc-text">
                <h5 class="doc-title title-sm">
                  Tritium <small>(May 29, 2018)</small>
                </h5>
                <a
                  class="doc-download"
                  href="./assets/files/tritium/Nexus-Tritium-White-Paper.pdf"
                  target="_blank"
                  ><em class="ti ti-import"></em
                ></a>
                <div class="doc-lang">ENGLISH</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
