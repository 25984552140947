<body class="nk-body body-wider bg-light">
  <div class="nk-wrap">
    <nexus-page-header
      [title]="'Assets'"
      headerText="Complexity that works is built up out of modules that work perfectly, layered one over the other."
      [isSmall]="true"
    >
    </nexus-page-header>
  </div>

  <section class="section pdt-1 ov-v">
    <div class="ui-mask-left ui-mask-s8"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-text-wrap">
        <div
          class="row align-items-center gutter-vr-30px justify-content-between"
        >
          <div class="col-lg-6">
            <div class="nk-block-text">
              <h3
                class="title title-s2 title-s2-alt animated"
                data-animate="fadeInUp"
                data-delay=".1"
                style="visibility: visible; animation-delay: 0.1s"
              >
                Assets (NFTs)
              </h3>

              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".2"
                style="visibility: visible; animation-delay: 0.2s"
              >
                NFTs (non-fungible tokens) on Nexus are called ‘assets’, and are
                easily created and/or tokenized through the
                <a href="./wallet">Nexus desktop wallet</a> or the
                <a href="./integratedapi">Integrated API</a>. To create an NFT
                on Nexus, the fee charged is a flat 1 NXS per asset. Unlike
                regular tokens, NFTs are unique assets on the blockchain and can
                be used to represent many different types of digital and
                physical assets such as Digital Art, Physical Collectibles, and
                Music. Like NXS and tokens listed on Nexus, asset exchange is
                verified by a decentralized consensus, i.e. a third party is not
                required. Therefore, assets are great for use in
                <a href="./dapps">DApps</a> that provide decentralized and
                automated services. See <a href="./usecases">Use Cases</a> for
                Ideas.
              </p>
            </div>

            <div class="assets-documentation">
              <a
                class="btn btn-primary btn-lg"
                href="https://docs.nexus.io/user-guide/desktop-wallet"
                ><em class="link-icon fs-5"></em
                ><span>Create Asset Guide</span></a
              >
            </div>
          </div>

          <div class="col-lg-6 col-mb-8 m-auto order-first order-lg-last">
            <div
              class="nk-block-img"
              data-animate="fadeInUp"
              data-delay=".3"
              title="integrated-api-image"
              style="visibility: visible; animation-delay: 0.3s"
            >
              <img class="modules" src="images/nfts.png" alt="app" />
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>

  <!-- Footer -->
  <nexus-footer></nexus-footer>
</body>
