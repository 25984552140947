<body class="nk-body body-wider bg-light">
  <div class="nk-wrap">
    <nexus-page-header
      [title]="'Stake & Mine'"
      headerText="All creation is a mine, and every man a miner."
      [isSmall]="true"
    >
    </nexus-page-header>
  </div>

  <section class="section pdt-1 ov-v">
    <div class="ui-mask-left ui-mask-s8"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-text-wrap">
        <div
          class="row align-items-center gutter-vr-30px justify-content-between"
        >
          <div class="col-lg-6 col-mb-8 m-auto order-first">
            <div
              class="nk-block-img"
              data-animate="fadeInUp"
              data-delay=".1"
              title="integrated-api-image"
              style="visibility: visible; animation-delay: 0.1s"
            >
              <img class="modules" src="images/staking.png" alt="app" />
            </div>
          </div>

          <div class="col-lg-6">
            <div class="nk-block-text">
              <h3
                class="title title-s2 title-s2-alt animated"
                data-animate="fadeInUp"
                data-delay=".2"
                style="visibility: visible; animation-delay: 0.2s"
              >
                Stake & Mine
              </h3>
              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".3"
                style="visibility: visible; animation-delay: 0.3s"
              >
                Nexus has three consensus channels: one Proof of Stake (PoS),
                and two Proof of Work (PoW).
              </p>
              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".4"
                style="visibility: visible; animation-delay: 0.4s"
              >
                Staking is an energy efficient form of mining that forms the
                consensus that processes transactions on Nexus. Staking can only
                be performed with a Nexus wallet with continuous internet
                connection. You are rewarded NXS at a rate of 0.5% to 3.0% per
                year on your staked holdings. The rate is determined by a
                time-weighted score called ‘trust’ that represents the total
                consecutive seconds your wallet has been staking.
              </p>
            </div>

            <div class="nk-block-text">
              <div
                class="animated staking-documentation"
                data-animate="fadeInUp"
                data-delay=".1"
                style="visibility: visible; animation-delay: 0.1s"
              >
                <a
                  class="btn btn-primary btn-lg"
                  href="https://docs.nexus.io/user-guide/staking"
                  ><em class="link-icon fs-5"></em><span>Staking Guide</span></a
                >
              </div>
              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".2"
                style="visibility: visible; animation-delay: 0.2s"
              >
                The Prime Channel is a PoW consensus that searches for 308-digit
                dense prime clusters, providing a degree of FPGA/ASIC
                resistance. The second PoW channel uses an algorithm called
                Hashcash, where miners search for SK hashes that are four times
                the size of Bitcoin’s SHA-256 hashes.
              </p>
              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".3"
                style="visibility: visible; animation-delay: 0.3s"
              >
                Nexus offers a dedicated Mining Telegram Group to provide
                support for miners on the network. Additional information for
                the Nexus Miner can be found
                <a target="_blank" href="https://github.com/Nexusoft/NexusMiner"
                  >here</a
                >.
              </p>

              <div class="buttons-inline">
                <div
                  class="animated staking-documentation"
                  data-animate="fadeInUp"
                  data-delay=".1"
                  style="visibility: visible; animation-delay: 0.1s"
                >
                  <a
                    class="btn btn-primary btn-lg"
                    href="https://github.com/Nexusoft/NexusMiner"
                    ><em class="link-icon fs-5"></em
                    ><span>Mining Guide</span></a
                  >
                </div>
                <div
                  class="animated staking-documentation"
                  data-animate="fadeInUp"
                  data-delay=".1"
                  style="visibility: visible; animation-delay: 0.1s"
                >
                  <a
                    class="btn btn-primary btn-lg"
                    href="https://github.com/Nexusoft/NexusMiner/releases"
                    ><em class="link-icon fs-5"></em><span>Nexus Miner</span></a
                  >
                </div>
                <div
                  class="animated staking-documentation"
                  data-animate="fadeInUp"
                  data-delay=".1"
                  style="visibility: visible; animation-delay: 0.1s"
                >
                  <a
                    class="btn btn-primary btn-lg"
                    href="https://primepool.nexus.io/"
                    ><em class="link-icon fs-5"></em><span>Primepool</span></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>

  <!-- Footer -->
  <nexus-footer></nexus-footer>
</body>
