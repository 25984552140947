<!--Scroll to top-->
<div class="scroll-to-top" [ngClass]="{ 'show-scrollTop': windowScrolled }">
  <a
    href="#"
    class="menu-link nav-link menu-toggle button-up"
    title="test"
    (click)="scrollToTop(); $event.preventDefault()"
  >
    <!-- <i class="fa fa-chevron-up"></i> -->
    <img src="../../images/up.png" alt="up" />
  </a>
</div>
