<body class="nk-body body-wider bg-light">
  <div class="nk-wrap">
    <nexus-page-header
      [title]="'Modules'"
      headerText="Complexity that works is built up out of modules that work perfectly, layered one over the other."
      [isSmall]="true"
    >
    </nexus-page-header>
  </div>

  <section class="section pdt-1 ov-v">
    <div class="ui-mask-left ui-mask-s8"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-text-wrap">
        <div
          class="row align-items-center gutter-vr-30px justify-content-between"
        >
          <div class="col-lg-7 col-mb-8 m-auto order-first">
            <div
              class="nk-block-img"
              data-animate="fadeInUp"
              data-delay=".1"
              title="integrated-api-image"
              style="visibility: visible; animation-delay: 0.1s"
            >
              <img class="modules" src="images/modules.png" alt="app" />
            </div>
          </div>

          <div class="col-lg-5">
            <div class="nk-block-text">
              <h3
                class="title title-s2 title-s2-alt animated"
                data-animate="fadeInUp"
                data-delay=".2"
                style="visibility: visible; animation-delay: 0.2s"
              >
                Modules
              </h3>
              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".3"
                style="visibility: visible; animation-delay: 0.3s"
              >
                Wallet modules (also known as extensions or add-ons) allow users
                to customize and add functionality to the
                <a href="./wallet">Nexus desktop wallet</a>. Our wallet is a
                web-based application powered by Electron, therefore developers
                can build modules using web languages, i.e. HTML, CSS, and
                Javascript.
              </p>
            </div>

            <div class="module-documentation">
              <a
                class="btn btn-primary btn-lg"
                href="https://github.com/Nexusoft/NexusInterface/tree/master/docs/Modules"
                ><em class="link-icon fs-5"></em><span>Modules Guide</span></a
              >
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>

  <!-- Footer -->
  <nexus-footer></nexus-footer>
</body>
