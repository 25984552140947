<body class="nk-body body-wider bg-light">
  <div class="nk-wrap">
    <nexus-page-header
      [title]="'Integrated API'"
      headerText="An API that isn't comprehensible isn't usuable."
      [isSmall]="true"
    >
    </nexus-page-header>
  </div>

  <section class="section pdt-1 ov-v">
    <div class="ui-mask-left ui-mask-s8"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-text-wrap">
        <div
          class="row align-items-center gutter-vr-30px justify-content-between"
        >
          <div class="col-lg-5">
            <div class="nk-block-text">
              <h3
                class="title title-s2 title-s2-alt animated"
                data-animate="fadeInUp"
                data-delay=".1"
                title="integratedapititle"
                style="visibility: visible; animation-delay: 0.1s"
              >
                Integrated API
              </h3>
              <p
                class="animated"
                data-animate="fadeInUp"
                data-delay=".2"
                title="integratedapi"
                style="visibility: visible; animation-delay: 0.2s"
              >
                Nexus DApps are powered by a JSON-API (Application Programming
                Interface), allowing you to integrate blockchain functionality
                with only web development skills. Nexus provides a wide range of
                well tested commands that reduce the time, risk and costs
                involved in DApp development. APIs include, but are not limited
                to: ‘User’, for the integration of
                <a href="./blockchain#signatureChains">Signature Chains</a> for
                account creation, ‘Finance’, for NXS related actions or token
                creation, and ‘Asset’ for asset creation (<a href="./assets"
                  >NFTs</a
                >). The API also supports a SQL like query language, with
                advanced search and filtering of registers.
              </p>
            </div>

            <div class="api-documentation">
              <a
                class="btn btn-primary btn-lg"
                href="https://docs.nexus.io/developer-guide"
                ><em class="link-icon fs-5"></em
                ><span>API Documentation</span></a
              >
            </div>
          </div>

          <div class="col-lg-7 col-mb-8 m-auto order-first order-lg-last">
            <div
              class="nk-block-img"
              data-animate="fadeInUp"
              data-delay=".2"
              title="integrated-api-image"
              style="visibility: visible; animation-delay: 0.2s"
            >
              <img class="bubble" src="images/integrateapi.png" alt="app" />
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>

  <!-- Footer -->
  <nexus-footer></nexus-footer>
</body>
