<body class="nk-body body-wider bg-light">
  <div class="nk-wrap">
    <nexus-page-header
      [title]="'Nexus Organization'"
      titleBackground="Team"
      headerText=" We're smart, we're hard working, we're easy to talk
    to, and we love a challenge"
      [isSmall]="true"
    >
    </nexus-page-header>

    <main class="nk-pages">
      <section class="section section-team bg-light">
        <div class="container">
          <!-- Block @s -->
          <div class="nk-block nk-block-team-featured team-featured">
            <div class="row align-items-center">
              <div class="col-lg-5 mb-4 mb-lg-0">
                <div class="team-featured-photo tc-light">
                  <img src="./../../images/team/colincantrell.jpg" alt="team" />
                  <h5 class="team-featured-info">
                    Colin Cantrell<span>Chief Software Architect at Nexus</span>
                  </h5>
                </div>
              </div>
              <div class="col-lg-7">
                <div class="team-featured-cont">
                  <h6 class="title title-xs title-light">behind the idea</h6>
                  <h2 class="title title-lg title-dark">The Founder</h2>
                  <p>
                    Colin Cantrell is a software architect, engineer, musician
                    and composer. Having been raised in the aerospace industry,
                    he took up programming at the age of 11, and was helping to
                    build rockets by the age of 16. Through these combined
                    interests, in 2014 Nexus was born, bringing together
                    technologies to promote a greater distribution of resources
                    and communication. His ultimate vision is to increase the
                    accessibility of these technologies in order to help people
                    to build a greater connection to one another.
                  </p>
                </div>
              </div>
            </div>
            <!-- .row -->
          </div>
          <!-- Block @e -->
        </div>
      </section>
      <!-- // -->

      <!-- Developers -->
      <section class="section section-team bg-light-alt">
        <div class="container">
          <!-- Section Head @s -->
          <div class="section-head text-center wide-auto">
            <h2
              class="title title-s4 animated fadeInUp"
              data-animate="fadeInUp"
              data-delay=".1"
              title="Developers"
              style="visibility: visible; animation-delay: 0.1s"
            >
              Developers
            </h2>
            <!-- <h2 class="title title-lg title-dark">Communications Arm</h2> -->
          </div>
          <!-- .section-head @e -->
          <!-- Block @s -->
          <div class="nk-block nk-block-team-list team-list">
            <div class="row justify-content-center">
              <div class="col-md-3 col-6" *ngFor="let dev of developers">
                <div class="team">
                  <div class="team-photo team-photo-sm">
                    <img
                      src="./../../images/team/{{ dev.image }}.png"
                      alt="team"
                    />
                    <a
                      href="#team-{{ dev.popup }}"
                      class="team-show content-popup"
                    ></a>
                    <ul class="team-social">
                      <li>
                        <a href="{{ dev.telegram }}"
                          ><em class="fab fa-telegram"></em
                        ></a>
                      </li>
                      <li>
                        <a *ngIf="dev.linkedIn" href="{{ dev.linkedIn }}"
                          ><em class="fab fa-linkedin-in"></em
                        ></a>
                      </li>
                    </ul>
                  </div>
                  <h5 class="team-name title title-sm">{{ dev.name }}</h5>
                  <span class="team-position">{{ dev.position }}</span>
                </div>
                <!-- Start .team-profile  -->
                <div id="team-{{ dev.popup }}" class="team-popup mfp-hide">
                  <a title="Close" class="mfp-close">×</a>
                  <div class="row align-items-start">
                    <div class="col-md-6">
                      <div class="team-photo">
                        <img
                          src="./../../images/team/{{ dev.image }}.png"
                          alt="team"
                        />
                      </div>
                    </div>
                    <!-- .col  -->
                    <div class="col-md-6">
                      <div class="team-popup-info ps-md-3">
                        <h3 class="team-name title title-lg pt-4">
                          {{ dev.name }}
                        </h3>
                        <p class="team-position">{{ dev.position }}</p>
                        <ul class="team-social mb-4">
                          <li>
                            <a href="{{ dev.telegram }}"
                              ><em class="fab fa-telegram"></em
                            ></a>
                          </li>
                          <li>
                            <a *ngIf="dev.linkedIn" href="{{ dev.linkedIn }}"
                              ><em class="fab fa-linkedin-in"></em
                            ></a>
                          </li>
                        </ul>
                        <p>
                          {{ dev.description1 }}
                        </p>
                        <p *ngIf="dev.description2">
                          {{ dev.description2 }}
                        </p>
                      </div>
                    </div>
                    <!-- .col  -->
                  </div>
                  <!-- .row  -->
                </div>
                <!-- .team-profile  -->
              </div>
              <!-- .col -->
            </div>
            <!-- .row -->
          </div>
          <!-- Block @r -->
        </div>
      </section>
      <!-- // -->

      <!-- Ambassadors -->
      <!-- Communications Arm -->
      <section class="section section-team bg-light">
        <div class="container">
          <!-- Section Head @s -->
          <div class="section-head section-head-sm text-center wide-auto-sm">
            <h2
              class="title title-s4 animated fadeInUp"
              data-animate="fadeInUp"
              data-delay=".1"
              title="Ambassadors"
              style="visibility: visible; animation-delay: 0.1s"
            >
              Ambassadors
            </h2>
            <h2 class="title title-lg title-dark">Communications Arm</h2>
          </div>
          <!-- .section-head @e -->
          <!-- Block @s -->
          <div class="nk-block nk-block-team-list team-list">
            <div class="row justify-content-center">
              <div
                class="col-md-3 col-6"
                *ngFor="let member of communicationMembers"
              >
                <div class="team">
                  <div class="team-photo team-photo-sm">
                    <img
                      src="./../../images/team/{{ member.image }}.png"
                      alt="team"
                    />
                    <a
                      href="#team-{{ member.popup }}"
                      class="team-show content-popup"
                    ></a>
                    <ul class="team-social">
                      <li>
                        <a *ngIf="member.telegram" href="{{ member.telegram }}"
                          ><em class="fab fa-telegram"></em
                        ></a>
                      </li>
                      <li>
                        <a *ngIf="member.linkedIn" href="{{ member.linkedIn }}"
                          ><em class="fab fa-linkedin-in"></em
                        ></a>
                      </li>
                    </ul>
                  </div>
                  <h5 class="team-name title title-sm">{{ member.name }}</h5>
                  <span class="team-position">{{ member.position }}</span>
                </div>
                <!-- Start .team-profile  -->
                <div id="team-{{ member.popup }}" class="team-popup mfp-hide">
                  <a title="Close" class="mfp-close">×</a>
                  <div class="row align-items-start">
                    <div class="col-md-6">
                      <div class="team-photo">
                        <img
                          src="./../../images/team/{{ member.image }}.png"
                          alt="team"
                        />
                      </div>
                    </div>
                    <!-- .col  -->
                    <div class="col-md-6">
                      <div class="team-popup-info ps-md-3">
                        <h3 class="team-name title title-lg pt-4">
                          {{ member.name }}
                        </h3>
                        <p class="team-position">{{ member.position }}</p>
                        <ul class="team-social mb-4">
                          <li>
                            <a
                              *ngIf="member.telegram"
                              href="{{ member.telegram }}"
                              ><em class="fab fa-telegram"></em
                            ></a>
                          </li>
                          <li>
                            <a
                              *ngIf="member.linkedIn"
                              href="{{ member.linkedIn }}"
                              ><em class="fab fa-linkedin-in"></em
                            ></a>
                          </li>
                        </ul>
                        <p>
                          {{ member.description1 }}
                        </p>
                        <p *ngIf="member.description2">
                          {{ member.description2 }}
                        </p>
                      </div>
                    </div>
                    <!-- .col  -->
                  </div>
                  <!-- .row  -->
                </div>
                <!-- .team-profile  -->
              </div>
              <!-- .col -->
            </div>
            <!-- .row -->
          </div>
          <!-- Block @r -->
        </div>
      </section>

      <!-- Impressions Arm -->
      <section class="section section-team bg-light">
        <div class="container">
          <!-- Section Head @s -->
          <div class="section-head text-center wide-auto">
            <h2 class="title title-lg title-dark">Impressions Arm</h2>
          </div>
          <!-- .section-head @e -->
          <!-- Block @s -->
          <div class="nk-block nk-block-team-list team-list">
            <div class="row justify-content-center">
              <div
                class="col-md-3 col-6"
                *ngFor="let member of impressionMembers"
              >
                <div class="team">
                  <div class="team-photo team-photo-sm">
                    <img
                      src="./../../images/team/{{ member.image }}.png"
                      alt="team"
                    />
                    <a
                      href="#team-{{ member.popup }}"
                      class="team-show content-popup"
                    ></a>
                    <ul class="team-social">
                      <li>
                        <a *ngIf="member.telegram" href="{{ member.telegram }}"
                          ><em class="fab fa-telegram"></em
                        ></a>
                      </li>
                      <li>
                        <a *ngIf="member.linkedIn" href="{{ member.linkedIn }}"
                          ><em class="fab fa-linkedin-in"></em
                        ></a>
                      </li>
                    </ul>
                  </div>
                  <h5 class="team-name title title-sm">{{ member.name }}</h5>
                  <span class="team-position">{{ member.position }}</span>
                </div>
                <!-- Start .team-profile  -->
                <div id="team-{{ member.popup }}" class="team-popup mfp-hide">
                  <a title="Close" class="mfp-close">×</a>
                  <div class="row align-items-start">
                    <div class="col-md-6">
                      <div class="team-photo">
                        <img
                          src="./../../images/team/{{ member.image }}.png"
                          alt="team"
                        />
                      </div>
                    </div>
                    <!-- .col  -->
                    <div class="col-md-6">
                      <div class="team-popup-info ps-md-3">
                        <h3 class="team-name title title-lg pt-4">
                          {{ member.name }}
                        </h3>
                        <p class="team-position">{{ member.position }}</p>
                        <ul class="team-social mb-4">
                          <li>
                            <a
                              *ngIf="member.telegram"
                              href="{{ member.telegram }}"
                              ><em class="fab fa-telegram"></em
                            ></a>
                          </li>
                          <li>
                            <a
                              *ngIf="member.linkedIn"
                              href="{{ member.linkedIn }}"
                              ><em class="fab fa-linkedin-in"></em
                            ></a>
                          </li>
                        </ul>
                        <p>
                          {{ member.description1 }}
                        </p>
                        <p *ngIf="member.description2">
                          {{ member.description2 }}
                        </p>
                      </div>
                    </div>
                    <!-- .col  -->
                  </div>
                  <!-- .row  -->
                </div>
                <!-- .team-profile  -->
              </div>
            </div>
            <!-- .row -->
          </div>
          <!-- Block @r -->
        </div>
      </section>

      <!-- Interactions Arm -->
      <section class="section section-team bg-light">
        <div class="container">
          <!-- Section Head @s -->
          <div class="section-head text-center wide-auto">
            <h2 class="title title-lg title-dark">Interactions Arm</h2>
          </div>
          <!-- .section-head @e -->
          <!-- Block @s -->
          <div class="nk-block nk-block-team-list team-list">
            <div class="row justify-content-center">
              <div
                class="col-md-3 col-6"
                *ngFor="let member of interactionMembers"
              >
                <div class="team">
                  <div class="team-photo team-photo-sm">
                    <img
                      src="./../../images/team/{{ member.image }}.png"
                      alt="team"
                    />
                    <a
                      href="#team-{{ member.popup }}"
                      class="team-show content-popup"
                    ></a>
                    <ul class="team-social">
                      <li>
                        <a *ngIf="member.telegram" href="{{ member.telegram }}"
                          ><em class="fab fa-telegram"></em
                        ></a>
                      </li>
                      <li>
                        <a *ngIf="member.linkedIn" href="{{ member.linkedIn }}"
                          ><em class="fab fa-linkedin-in"></em
                        ></a>
                      </li>
                    </ul>
                  </div>
                  <h5 class="team-name title title-sm">{{ member.name }}</h5>
                  <span class="team-position">{{ member.position }}</span>
                </div>
                <!-- Start .team-profile  -->
                <div id="team-{{ member.popup }}" class="team-popup mfp-hide">
                  <a title="Close" class="mfp-close">×</a>
                  <div class="row align-items-start">
                    <div class="col-md-6">
                      <div class="team-photo">
                        <img
                          src="./../../images/team/{{ member.image }}.png"
                          alt="team"
                        />
                      </div>
                    </div>
                    <!-- .col  -->
                    <div class="col-md-6">
                      <div class="team-popup-info ps-md-3">
                        <h3 class="team-name title title-lg pt-4">
                          {{ member.name }}
                        </h3>
                        <p class="team-position">{{ member.position }}</p>
                        <ul class="team-social mb-4">
                          <li>
                            <a
                              *ngIf="member.telegram"
                              href="{{ member.telegram }}"
                              ><em class="fab fa-telegram"></em
                            ></a>
                          </li>
                          <li>
                            <a
                              *ngIf="member.linkedIn"
                              href="{{ member.linkedIn }}"
                              ><em class="fab fa-linkedin-in"></em
                            ></a>
                          </li>
                        </ul>
                        <p>
                          {{ member.description1 }}
                        </p>
                        <p *ngIf="member.description2">
                          {{ member.description2 }}
                        </p>
                      </div>
                    </div>
                    <!-- .col  -->
                  </div>
                  <!-- .row  -->
                </div>
                <!-- .team-profile  -->
              </div>
            </div>
            <!-- .row -->
          </div>
          <!-- Block @r -->
        </div>
      </section>

      <!-- Relationships Arm -->
      <section class="section section-team bg-light">
        <div class="container">
          <!-- Section Head @s -->
          <div class="section-head text-center wide-auto">
            <h2 class="title title-lg title-dark">Relationships Arm</h2>
          </div>
          <!-- .section-head @e -->
          <!-- Block @s -->
          <div class="nk-block nk-block-team-list team-list">
            <div class="row justify-content-center">
              <div
                class="col-md-3 col-6"
                *ngFor="let member of relationshipMembers"
              >
                <div class="team">
                  <div class="team-photo team-photo-sm">
                    <img
                      src="./../../images/team/{{ member.image }}.png"
                      alt="team"
                    />
                    <a
                      href="#team-{{ member.popup }}"
                      class="team-show content-popup"
                    ></a>
                    <ul class="team-social">
                      <li>
                        <a *ngIf="member.telegram" href="{{ member.telegram }}"
                          ><em class="fab fa-telegram"></em
                        ></a>
                      </li>
                      <li>
                        <a *ngIf="member.linkedIn" href="{{ member.linkedIn }}"
                          ><em class="fab fa-linkedin-in"></em
                        ></a>
                      </li>
                    </ul>
                  </div>
                  <h5 class="team-name title title-sm">{{ member.name }}</h5>
                  <span class="team-position">{{ member.position }}</span>
                </div>
                <!-- Start .team-profile  -->
                <div id="team-{{ member.popup }}" class="team-popup mfp-hide">
                  <a title="Close" class="mfp-close">×</a>
                  <div class="row align-items-start">
                    <div class="col-md-6">
                      <div class="team-photo">
                        <img
                          src="./../../images/team/{{ member.image }}.png"
                          alt="team"
                        />
                      </div>
                    </div>
                    <!-- .col  -->
                    <div class="col-md-6">
                      <div class="team-popup-info ps-md-3">
                        <h3 class="team-name title title-lg pt-4">
                          {{ member.name }}
                        </h3>
                        <p class="team-position">{{ member.position }}</p>
                        <ul class="team-social mb-4">
                          <li>
                            <a
                              *ngIf="member.telegram"
                              href="{{ member.telegram }}"
                              ><em class="fab fa-telegram"></em
                            ></a>
                          </li>
                          <li>
                            <a
                              *ngIf="member.linkedIn"
                              href="{{ member.linkedIn }}"
                              ><em class="fab fa-linkedin-in"></em
                            ></a>
                          </li>
                        </ul>
                        <p>
                          {{ member.description1 }}
                        </p>
                        <p *ngIf="member.description2">
                          {{ member.description2 }}
                        </p>
                      </div>
                    </div>
                    <!-- .col  -->
                  </div>
                  <!-- .row  -->
                </div>
                <!-- .team-profile  -->
              </div>
            </div>
            <!-- .row -->
          </div>
          <!-- Block @r -->
        </div>
      </section>

      <!-- Media Arm -->
      <section class="section section-team bg-light">
        <div class="container">
          <!-- Section Head @s -->
          <div class="section-head text-center wide-auto">
            <h2 class="title title-lg title-dark">Media Arm</h2>
          </div>
          <!-- .section-head @e -->
          <!-- Block @s -->
          <div class="nk-block nk-block-team-list team-list">
            <div class="row justify-content-center">
              <div class="col-md-3 col-6" *ngFor="let member of mediaMembers">
                <div class="team">
                  <div class="team-photo team-photo-sm">
                    <img
                      src="./../../images/team/{{ member.image }}.png"
                      alt="team"
                    />
                    <a
                      href="#team-{{ member.popup }}"
                      class="team-show content-popup"
                    ></a>
                    <ul class="team-social">
                      <li>
                        <a *ngIf="member.telegram" href="{{ member.telegram }}"
                          ><em class="fab fa-telegram"></em
                        ></a>
                      </li>
                      <li>
                        <a *ngIf="member.linkedIn" href="{{ member.linkedIn }}"
                          ><em class="fab fa-linkedin-in"></em
                        ></a>
                      </li>
                    </ul>
                  </div>
                  <h5 class="team-name title title-sm">{{ member.name }}</h5>
                  <span class="team-position">{{ member.position }}</span>
                </div>
                <!-- Start .team-profile  -->
                <div id="team-{{ member.popup }}" class="team-popup mfp-hide">
                  <a title="Close" class="mfp-close">×</a>
                  <div class="row align-items-start">
                    <div class="col-md-6">
                      <div class="team-photo">
                        <img
                          src="./../../images/team/{{ member.image }}.png"
                          alt="team"
                        />
                      </div>
                    </div>
                    <!-- .col  -->
                    <div class="col-md-6">
                      <div class="team-popup-info ps-md-3">
                        <h3 class="team-name title title-lg pt-4">
                          {{ member.name }}
                        </h3>
                        <p class="team-position">{{ member.position }}</p>
                        <ul class="team-social mb-4">
                          <li>
                            <a
                              *ngIf="member.telegram"
                              href="{{ member.telegram }}"
                              ><em class="fab fa-telegram"></em
                            ></a>
                          </li>
                          <li>
                            <a
                              *ngIf="member.linkedIn"
                              href="{{ member.linkedIn }}"
                              ><em class="fab fa-linkedin-in"></em
                            ></a>
                          </li>
                        </ul>
                        <p>
                          {{ member.description1 }}
                        </p>
                        <p *ngIf="member.description2">
                          {{ member.description2 }}
                        </p>
                      </div>
                    </div>
                    <!-- .col  -->
                  </div>
                  <!-- .row  -->
                </div>
                <!-- .team-profile  -->
              </div>
            </div>
            <!-- .row -->
          </div>
          <!-- Block @r -->
        </div>
      </section>

      <!-- Jurors -->
      <section class="section section-team bg-light">
        <div class="container">
          <!-- Section Head @s -->
          <div class="section-head text-center wide-auto">
            <h2
              class="title title-s4 animated fadeInUp"
              data-animate="fadeInUp"
              data-delay=".1"
              title="jurors"
              style="visibility: visible; animation-delay: 0.1s"
            >
              Jurors
            </h2>
          </div>
          <!-- Block @s -->

          <div class="nk-block">
            <div class="row align-items-top">
              <div class="col-lg-12">
                <div class="team-featured-cont jurors">
                  <h6 class="title title-xs title-dark">
                    Judical Branch / Volunteer Jurors
                  </h6>
                  <!-- <h2 class="title title-lg title-dark">The Founder</h2> -->
                  <p>
                    Bridge the community and the DAO to ensure transparency with
                    unbiased reporting and reviews with a neutral point of view
                    for issues that require:
                  </p>

                  <p>
                    <span>● Resolving Disputes</span> <br />
                    <span>● Conducting Audits</span> <br />
                    <span
                      >● Voting when required as a tie-breaker between
                      branches</span
                    >
                  </p>

                  <div>
                    <a
                      href="../../assets/files/tritium/Judicial-Charter-Documentation.pdf"
                      target="_blank"
                      >Judicial Charter Documentation</a
                    ><br />
                    <a
                      href="../../assets/files/tritium/Judicial-Incident-Report-Process-Documentation.pdf"
                      target="_blank"
                      >Incident Report Process Documentation
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="nk-block nk-block-team-list team-list">
            <div class="row justify-content-center">
              <div class="col-md-3 col-6" *ngFor="let member of jurorMembers">
                <div class="team">
                  <div class="team-photo team-photo-sm">
                    <img
                      src="./../../images/team/{{ member.image }}.png"
                      alt="team"
                    />
                    <a
                      href="#team-{{ member.popup }}"
                      class="team-show content-popup"
                    ></a>
                    <ul class="team-social">
                      <li>
                        <a *ngIf="member.telegram" href="{{ member.telegram }}"
                          ><em class="fab fa-telegram"></em
                        ></a>
                      </li>
                      <li>
                        <a *ngIf="member.linkedIn" href="{{ member.linkedIn }}"
                          ><em class="fab fa-linkedin-in"></em
                        ></a>
                      </li>
                    </ul>
                  </div>
                  <h5 class="team-name title title-sm">{{ member.name }}</h5>
                  <span class="team-position">{{ member.position }}</span>
                </div>
                <!-- Start .team-profile  -->
                <div id="team-{{ member.popup }}" class="team-popup mfp-hide">
                  <a title="Close" class="mfp-close">×</a>
                  <div class="row align-items-start">
                    <div class="col-md-6">
                      <div class="team-photo">
                        <img
                          src="./../../images/team/{{ member.image }}.png"
                          alt="team"
                        />
                      </div>
                    </div>
                    <!-- .col  -->
                    <div class="col-md-6">
                      <div class="team-popup-info ps-md-3">
                        <h3 class="team-name title title-lg pt-4">
                          {{ member.name }}
                        </h3>
                        <p class="team-position">{{ member.position }}</p>
                        <ul class="team-social mb-4">
                          <li>
                            <a
                              *ngIf="member.telegram"
                              href="{{ member.telegram }}"
                              ><em class="fab fa-telegram"></em
                            ></a>
                          </li>
                          <li>
                            <a
                              *ngIf="member.linkedIn"
                              href="{{ member.linkedIn }}"
                              ><em class="fab fa-linkedin-in"></em
                            ></a>
                          </li>
                        </ul>
                        <p>
                          {{ member.description1 }}
                        </p>
                        <p *ngIf="member.description2">
                          {{ member.description2 }}
                        </p>
                      </div>
                    </div>
                    <!-- .col  -->
                  </div>
                  <!-- .row  -->
                </div>
                <!-- .team-profile  -->
              </div>
            </div>
            <!-- .row -->
          </div>
          <!-- Block @r -->
        </div>
      </section>
      <!-- // -->
    </main>

    <!-- Footer -->
    <nexus-footer></nexus-footer>
  </div>
</body>
