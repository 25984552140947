<body class="nk-body body-wider bg-light">
  <div class="nk-wrap">
    <nexus-page-header
      [title]="'Transact'"
      headerText="Approaching people looking for something in return isn't a relationship, it's a transaction."
      [isSmall]="true"
    >
    </nexus-page-header>
  </div>

  <section class="section pdt-1 ov-v">
    <div class="ui-mask-left ui-mask-s8"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-text-wrap">
        <div
          class="row align-items-center gutter-vr-30px justify-content-between"
        >
          <div class="col-lg-6">
            <div class="nk-block-text">
              <h2
                class="title title-s2 title-s2-alt animated"
                data-animate="fadeInUp"
                data-delay="0.1"
                style="visibility: visible; animation-delay: 0.1s"
              >
                Transact
              </h2>
              <h6
                class="title title-xs title-s6 tc-primary animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".2"
                style="visibility: visible; animation-delay: 0.2s"
              >
                Fast:
              </h6>
              <div
                class="animated"
                data-animate="fadeInUp"
                data-delay=".2"
                style="visibility: visible; animation-delay: 0.2s"
              >
                <ul class="list list-check">
                  <li>Transactions on Nexus confirm within 50 seconds.</li>
                </ul>
              </div>

              <h6
                class="title title-xs title-s6 tc-primary animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".3"
                style="visibility: visible; animation-delay: 0.3s"
              >
                Free:
              </h6>
              <div
                class="animated"
                data-animate="fadeInUp"
                data-delay=".3"
                style="visibility: visible; animation-delay: 0.3s"
              >
                <ul class="list list-check">
                  <li>
                    It is free to send and receive NXS, tokens and assets
                    (NFTs). However, there is a 0.01 NXS fee applied to every
                    contract generated within 10 seconds of the last
                    transaction, in order to prevent transaction spam attacks.
                  </li>
                </ul>
              </div>

              <h6
                class="title title-xs title-s6 tc-primary animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".4"
                style="visibility: visible; animation-delay: 0.4s"
              >
                Secure:
              </h6>
              <div
                class="animated"
                data-animate="fadeInUp"
                data-delay=".4"
                style="visibility: visible; animation-delay: 0.4s"
              >
                <ul class="list list-check">
                  <li>
                    Your PIN is requested every time a transaction is created,
                    and unlike other cryptocurrencies, it is not possible to
                    send to an invalid or nonexistent address.
                  </li>
                </ul>
              </div>

              <h6
                class="title title-xs title-s6 tc-primary animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".5"
                style="visibility: visible; animation-delay: 0.5s"
              >
                Easy:
              </h6>
              <div
                class="animated"
                data-animate="fadeInUp"
                data-delay=".5"
                style="visibility: visible; animation-delay: 0.5s"
              >
                <ul class="list list-check">
                  <li>
                    You can send NXS to
                    <a href="./namespaces">usernames or names</a>, replacing the
                    need to copy and paste large Base58 addresses.
                  </li>
                </ul>
              </div>

              <h6
                class="title title-xs title-s6 tc-primary animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".6"
                style="visibility: visible; animation-delay: 0.6s"
              >
                Decentralized:
              </h6>

              <div
                class="animated"
                data-animate="fadeInUp"
                data-delay=".6"
                style="visibility: visible; animation-delay: 0.6s"
              >
                <ul class="list list-check">
                  <li>
                    Nexus is programmable digital money, meaning
                    <a href="./contracts">contracts</a> can transact
                    <a href="./tokenization">tokens</a> and
                    <a href="./assets">assets (NFTs)</a> without the requirement
                    for third parties such as brokers.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-mb-8 m-auto order-first order-lg-last">
            <div
              class="nk-block-img"
              data-animate="fadeInUp"
              data-delay=".1"
              title="integrated-api-image"
              style="visibility: visible; animation-delay: 0.1s"
            >
              <img class="bubble" src="images/transactions.png" alt="app" />
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>

  <!-- Footer -->
  <nexus-footer></nexus-footer>
</body>
