<main class="nk-pages">
  <section class="section bg-transparent over-up over-up-xl-s2 pdt-0">
    <div class="ui-mask-right ui-mask-s8"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-features-s3">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-10">
            <div
              class="feature feature-center card card-lg-y animated"
              data-animate="fadeInUp"
              data-delay="1.4"
            >
              <div
                class="feature-icon feature-icon-bg-grd feature-icon-bg-grd-s1"
              >
                <em class="icon ikon ikon-bulb icon-grd icon-lg-s2"></em>
              </div>
              <div class="feature-text">
                <h4 class="title title-md title-dark mb-4">
                  Most Innovative Technology
                </h4>
                <p>
                  Nexus is a <b>community-driven</b> project with the common
                  vision of a world inspired by <b>innovative</b> and
                  responsible values, expansive technology, and the fundamental
                  quality of connection being ubiquitous, free, and available to
                  everyone.
                </p>
              </div>
            </div>
          </div>
          <!-- .col -->
          <div class="col-lg-4 col-md-10">
            <div
              class="feature feature-center card card-lg-y animated"
              data-animate="fadeInUp"
              data-delay="1.5"
            >
              <div
                class="feature-icon feature-icon-bg-grd feature-icon-bg-grd-s2"
              >
                <em class="icon ikon ikon-lock icon-grd icon-lg-s2"></em>
              </div>
              <div class="feature-text">
                <h4 class="title title-md title-dark mb-4">
                  Quantum Resistance
                </h4>
                <p>
                  With the rise in the power of classical computers and the
                  emergence of quantum computers, public keys are becoming
                  vulnerable. Nexus has prepared by adding cryptography that
                  supports increased levels of <b>quantum resistance</b>.
                </p>
              </div>
            </div>
          </div>
          <!-- .col -->
          <div class="col-lg-4 col-md-10">
            <div
              class="feature feature-center card card-lg-y animated"
              data-animate="fadeInUp"
              data-delay="1.6"
            >
              <div
                class="feature-icon feature-icon-bg-grd feature-icon-bg-grd-s3"
              >
                <em class="icon ikon ikon-cash icon-grd icon-lg-s2"></em>
              </div>
              <div class="feature-text">
                <h4 class="title title-md title-dark mb-4">
                  Free Simple Transactions
                </h4>
                <p>
                  Transactions on Nexus are <b>free</b>, confirmed within 50
                  seconds. It is not possible to send NXS to an invalid or
                  nonexistent address. You can send NXS to usernames or account
                  names, replacing the need to copy and paste large Base58
                  addresses.
                </p>
              </div>
            </div>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>
  <!-- // -->
  <section class="section pdt-0 ov-v">
    <div class="ui-mask-left ui-mask-s9"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-text-wrap">
        <div
          class="row align-items-center gutter-vr-30px justify-content-between"
        >
          <div class="col-lg-7 col-mb-8 m-auto order-first order-lg-last">
            <div
              class="nk-block-img animated"
              data-animate="fadeInUp"
              data-delay=".3"
            >
              <!-- <img src="./../../images/gfx/gfx-z-g.png" alt="app" /> -->
              <img src="images/decentralize.png" alt="app" />
            </div>
          </div>
          <div class="col-lg-5">
            <div
              class="nk-block-text animated"
              data-animate="fadeInUp"
              data-delay=".2"
            >
              <div class="nk-block-shade"></div>
              <h3 class="title title-dark title-regular">
                Decentralize everything
              </h3>
              <p>
                Imagine a scalable, quantum-resistant blockchain securing an
                uncensorable and free internet through a hack-resistant
                Operating System, virtualizing access to your desktop and
                digital content from any computer in the world.
              </p>
              <p>
                Nexus is bringing this possibility to life with an end-to-end
                decentralized platform designed to empower every human being
                with technology to reclaim their digital identity.
              </p>

              <div class="nk-block-note nk-block-note-s2">
                <em class="icon ti ti-light-bulb dark"></em>
                <p>
                  Nexus was mined into existence similar to Bitcoin and there
                  was no premine or ICO.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>
  <!-- // -->

  <!-- // -->
  <section class="section ov-v pdb-2 bg-theme-accent-alt">
    <div class="ui-mask-left ui-mask-s11"></div>
    <div class="container">
      <div class="nk-block">
        <div class="row align-items-center gutter-vr-30px">
          <div class="col-lg-6">
            <div
              class="nk-block-img animated"
              data-animate="fadeInUp"
              data-delay=".1"
            >
              <img src="images/sigchain.png" alt="gfx" />
            </div>
          </div>
          <div class="col-lg-6">
            <div
              class="nk-block-text animated"
              data-animate="fadeInUp"
              data-delay=".2"
            >
              <h2 class="title title-dark title-regular">Use Nexus</h2>
              <p>
                Your gateway to Nexus begins with a username, password and PIN.
                Send free and reversible transactions, while staking NXS to
                receive rewards. Uses range from invoicing clients, to issuing
                contracts, creating NFTs or Namespaces (Dot-com equivalent).
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- // -->
  <section class="section ov-v">
    <div class="ui-mask-left ui-mask-s8"></div>
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-text-wrap">
        <div
          class="row align-items-center gutter-vr-30px justify-content-between gutter-vr-30px"
        >
          <div class="col-lg-7 col-mb-9 m-auto order-first order-lg-last">
            <div
              class="nk-block-img animated"
              data-animate="fadeInUp"
              data-delay=".3"
            >
              <!-- <img src="./../../images/gfx/gfx-z-g.png" alt="app" /> -->
              <img src="images/developonnxs.png" alt="app" />
            </div>
          </div>
          <div class="col-lg-5">
            <div
              class="nk-block-text animated"
              data-animate="fadeInUp"
              data-delay=".2"
            >
              <div class="nk-block-shade"></div>
              <h3 class="title title-dark title-regular">Develop On Nexus</h3>
              <p>
                Nexus’ integrated API makes developing decentralized
                applications (DApps) easy with “no-code” web tools like Bubble.
                Create tokens and assets (NFTs), design contracts, or use our
                templates. All our features are available on the public network
                or a private (hybrid) chain.
              </p>
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .block @e -->
    </div>
  </section>
  <!-- // -->

  <!-- // -->
  <section class="section bg-theme-grad-alt tc-light metrics">
    <div class="container">
      <div class="section-head section-head-s2 text-center wide-auto-md">
        <h2 class="title title-thin">Nexus Today</h2>
        <p class="lead">
          <em class="icon ti ti-light-bulb"></em>
          The first NXS block was mined on September 23rd of 2014 at 16:20:00
          GMT – 7. <br />
        </p>
      </div>

      <!-- .section-head @e -->
      <div class="nk-block" *ngIf="metricsLoaded; else stillLoading">
        <div class="token-box-top">
          <div class="row gutter-vr-30px">
            <div class="col-lg-4 col-md-6">
              <div class="token-sale-box">
                <span class="token-sale-info">
                  <em class="fas fa-user-shield"></em>
                  {{ sigchains }}</span
                >
                <span class="token-sale-title">User Accounts On Nexus</span>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="token-sale-box">
                <span class="token-sale-info">
                  <em class="fas fa-coins"></em>
                  {{ coinsInCirculation }}</span
                >
                <span class="token-sale-title">NXS in circulation</span>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="token-sale-box">
                <span class="token-sale-info">
                  <em class="fas fa-coins"></em>
                  78,000,000</span
                >
                <span class="token-sale-title">NXS Max Supply</span>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="token-sale-box">
                <span class="token-sale-info">
                  <em class="fas fa-lock"></em>
                  {{ stakedCoins }}</span
                >
                <span class="token-sale-title">Total NXS Staked</span>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="token-sale-box">
                <span class="token-sale-info"> {{ stakingPercentage }}</span>
                <span class="token-sale-title">Staked Percentage</span>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="token-sale-box">
                <span class="token-sale-info">
                  <em class="fas fa-person"></em>
                  {{ stakingAccounts }}</span
                >
                <span class="token-sale-title">Staking Accounts</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bg-image" data-overlay="theme" data-opacity="65">
      <img src="../../images/bg/bg-b.jpg" alt="background" />
    </div> -->
  </section>

  <!-- .section -->
  <div
    class="section bg-theme tc-light section-t-l pb-0 ov-h"
    id="articles"
    *ngIf="showArticles"
  >
    <div class="container container-xxl">
      <div class="nk-block">
        <div class="section-head text-center">
          <div class="row justify-content-center">
            <div class="col-xxl-6 col-xl-8 col-lg-9">
              <h2 class="animated" data-animate="fadeInUp" data-delay=".1">
                Latest <span class="text-grad">Articles</span>
              </h2>
              <p class="lead animated" data-animate="fadeInUp" data-delay=".2">
                An overview of our latest published articles.
              </p>
            </div>
          </div>
        </div>
        <!-- .section-head -->
        <div class="row justify-content-center">
          <div class="col-xxl-8 col-xl-9">
            <div
              class="has-carousel carousel-navs-middle carousel-navs-fill carousel-dots-long-line carousel-dots-sharp carousel-center-focused animated"
              data-animate="fadeInUp"
              data-delay=".3"
              data-margin="28"
              data-items="1"
              data-navs="true"
              data-dots="true"
              data-loop="true"
            >
              <!-- .schedule-item -->
              <div class="schedule-item box-bordered-grad">
                <div class="box-bordered-inner flex-grow-0">
                  <div class="schedule-meta">
                    <div class="schedule-step">
                      <h5>
                        <em class="ti ti-notepad"></em>
                      </h5>
                    </div>
                    <div class="schedule-meta-text">
                      <ul class="schedule-meta-list">
                        <li class="text-grad">
                          {{ articles[0]?.publishDate }}
                        </li>
                      </ul>
                      <h5 class="schedule-meta-title">
                        {{ articles[0]?.category }}
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="box-bordered-inner schedule-content">
                  <a href="{{ articles[0]?.url }}" target="_blank">
                    <img
                      src="images/blog/{{ articles[0]?.image }}"
                      alt="blog-thumb"
                  /></a>
                  <div class="schedule-text">
                    <h5 class="line-truncate-2">
                      {{ articles[0]?.title }}
                    </h5>
                    <p class="line-truncate-4">
                      {{ articles[0]?.description }}
                    </p>
                    <a
                      href="{{ articles[0]?.url }}"
                      target="_blank"
                      class="btn btn-sm btn-grad btn-auto btn-noround btn-nocap mt-0"
                      >Read article</a
                    >
                  </div>
                </div>
              </div>
              <!-- .schedule-item -->
              <div class="schedule-item box-bordered-grad">
                <div class="box-bordered-inner flex-grow-0">
                  <div class="schedule-meta">
                    <div class="schedule-step">
                      <h5><em class="ti ti-notepad"></em></h5>
                    </div>
                    <div class="schedule-meta-text">
                      <ul class="schedule-meta-list">
                        <li class="text-grad">
                          {{ articles[1]?.publishDate }}
                        </li>
                      </ul>
                      <h5 class="schedule-meta-title">
                        {{ articles[1]?.category }}
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="box-bordered-inner schedule-content">
                  <a href="{{ articles[1]?.url }}" target="_blank">
                    <img
                      src="images/blog/{{ articles[1]?.image }}"
                      alt="blog-thumb"
                  /></a>
                  <div class="schedule-text">
                    <h5 class="line-truncate-2">
                      {{ articles[1]?.title }}
                    </h5>
                    <p class="line-truncate-4">
                      {{ articles[1]?.description }}
                    </p>
                    <a
                      href="{{ articles[1]?.url }}"
                      target="_blank"
                      class="btn btn-sm btn-grad btn-auto btn-noround btn-nocap mt-0"
                      >Read article</a
                    >
                  </div>
                </div>
              </div>
              <!-- .schedule-item -->
              <div class="schedule-item box-bordered-grad">
                <div class="box-bordered-inner flex-grow-0">
                  <div class="schedule-meta">
                    <div class="schedule-step">
                      <h5><em class="ti ti-notepad"></em></h5>
                    </div>
                    <div class="schedule-meta-text">
                      <ul class="schedule-meta-list">
                        <li class="text-grad">
                          {{ articles[2]?.publishDate }}
                        </li>
                      </ul>
                      <h5 class="schedule-meta-title">
                        {{ articles[2]?.category }}
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="box-bordered-inner schedule-content">
                  <a href="{{ articles[2]?.url }}" target="_blank">
                    <img
                      src="images/blog/{{ articles[2]?.image }}"
                      alt="blog-thumb"
                  /></a>
                  <div class="schedule-text">
                    <h5 class="line-truncate-2">
                      {{ articles[2]?.title }}
                    </h5>
                    <p class="line-truncate-4">
                      {{ articles[2]?.description }}
                    </p>
                    <a
                      href="{{ articles[2]?.url }}"
                      target="_blank"
                      class="btn btn-sm btn-grad btn-auto btn-noround btn-nocap mt-0"
                      >Read article</a
                    >
                  </div>
                </div>
              </div>
              <!-- .schedule-item -->
            </div>

            <div class="articles-button">
              <a href="./blog" class="btn btn-primary btn-md">All articles</a>
            </div>
            <!-- .has-carousel -->
          </div>

          <!-- .col -->
        </div>

        <!-- .row -->
      </div>
      <!-- .nk-block -->
    </div>
    <!-- .container -->
  </div>
  <!-- .section -->

  <!-- .section -->
  <div
    class="section bg-theme-alt tc-light section-t-l pb-0 ov-h"
    id="videos"
    *ngIf="showVideos"
  >
    <div class="container container-xxl">
      <div class="nk-block">
        <div class="section-head text-center">
          <div class="row justify-content-center">
            <div class="col-xxl-6 col-xl-8 col-lg-9">
              <h2 class="animated" data-animate="fadeInUp" data-delay=".1">
                Latest <span class="text-grad">Videos</span>
              </h2>
              <p class="lead animated" data-animate="fadeInUp" data-delay=".2">
                An overview of our latest recorded videos.
              </p>
            </div>
          </div>
        </div>
        <!-- .section-head -->
        <div class="row justify-content-center">
          <div class="col-xxl-8 col-xl-9">
            <div
              class="has-carousel carousel-navs-middle carousel-navs-fill carousel-dots-long-line carousel-dots-sharp carousel-center-focused animated"
              data-animate="fadeInUp"
              data-delay=".3"
              data-margin="28"
              data-items="1"
              data-navs="true"
              data-dots="true"
              data-loop="true"
            >
              <!-- .schedule-item -->
              <div class="schedule-item box-bordered-grad">
                <div class="box-bordered-inner flex-grow-0">
                  <div class="schedule-meta">
                    <div class="schedule-step">
                      <h5>
                        <em class="ti ti-video-camera"></em>
                      </h5>
                    </div>
                    <div class="schedule-meta-text">
                      <ul class="schedule-meta-list">
                        <li class="text-grad">
                          {{ videos[2]?.category }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="box-bordered-inner schedule-content videos">
                  <a href="{{ videos[2]?.url }}" target="_blank">
                    <img
                      src="images/video/{{ videos[2]?.image }}"
                      alt="blog-thumb"
                  /></a>
                  <div class="schedule-text">
                    <h5 class="line-truncate-2">
                      {{ videos[2]?.title }}
                    </h5>
                    <a
                      href="{{ videos[2]?.url }}"
                      target="_blank"
                      class="btn btn-sm btn-grad btn-auto btn-noround btn-nocap mt-0"
                      >Watch video</a
                    >
                  </div>
                </div>
              </div>
              <!-- .schedule-item -->
              <div class="schedule-item box-bordered-grad">
                <div class="box-bordered-inner flex-grow-0">
                  <div class="schedule-meta">
                    <div class="schedule-step">
                      <h5><em class="ti ti-video-camera"></em></h5>
                    </div>
                    <div class="schedule-meta-text">
                      <ul class="schedule-meta-list">
                        <li class="text-grad">
                          {{ videos[1]?.category }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="box-bordered-inner schedule-content videos">
                  <a href="{{ videos[1]?.url }}" target="_blank">
                    <img
                      src="images/video/{{ videos[1]?.image }}"
                      alt="blog-thumb"
                  /></a>
                  <div class="schedule-text">
                    <h5 class="line-truncate-2">
                      {{ videos[1]?.title }}
                    </h5>
                    <a
                      href="{{ videos[1]?.url }}"
                      target="_blank"
                      class="btn btn-sm btn-grad btn-auto btn-noround btn-nocap mt-0"
                      >Watch video</a
                    >
                  </div>
                </div>
              </div>
              <!-- .schedule-item -->
              <div class="schedule-item box-bordered-grad">
                <div class="box-bordered-inner flex-grow-0">
                  <div class="schedule-meta">
                    <div class="schedule-step">
                      <h5><em class="ti ti-video-camera"></em></h5>
                    </div>
                    <div class="schedule-meta-text">
                      <ul class="schedule-meta-list">
                        <li class="text-grad">
                          {{ videos[0]?.category }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="box-bordered-inner schedule-content videos">
                  <a href="{{ videos[0]?.url }}" target="_blank">
                    <img
                      src="images/video/{{ videos[0]?.image }}"
                      alt="blog-thumb"
                  /></a>
                  <div class="schedule-text">
                    <h5 class="line-truncate-2">
                      {{ videos[0]?.title }}
                    </h5>
                    <a
                      href="{{ videos[0]?.url }}"
                      target="_blank"
                      class="btn btn-sm btn-grad btn-auto btn-noround btn-nocap mt-0"
                      >Watch video</a
                    >
                  </div>
                </div>
              </div>
              <!-- .schedule-item -->
            </div>

            <div class="videos-button">
              <a href="./videos" class="btn btn-primary btn-md">All videos</a>
            </div>
            <!-- .has-carousel -->
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
      </div>
      <!-- .nk-block -->
    </div>
    <!-- .container -->
  </div>
  <!-- .section -->

  <section class="section bg-theme-grad-alt tc-light globe-section">
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-text-wrap">
        <h2 class="title-globe">
          User visits <span class="text-grad">all around the world</span>
        </h2>
        <div
          class="row align-items-center gutter-vr-30px justify-content-between gutter-vr-30px"
        >
          <div class="col-lg-12 m-auto">
            <div #globe></div>
            <div #bar class="bar"></div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section tc-light section-video d-none d-md-block">
    <div id="videoDiv" class="container">
      <video
        class="video-container"
        id="HPVid"
        loop
        muted
        autoplay
        oncanplay="this.play()"
        onloadedmetadata="this.muted = true"
        (click)="navigateToWallets()"
      ></video>
    </div>
  </section>
</main>

<ng-template #stillLoading>
  <div class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>
